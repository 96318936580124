import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { cospoAnimations } from '@app/@animations';
import { CacheCospoService } from '@app/@store/services/cache.service';



@Component({
  selector: 'app-fan',
  templateUrl: './fan.component.html',
  styleUrls: ['./fan.component.scss'],
  animations: cospoAnimations
})
export class FanComponent implements OnInit {
  playerId;
  rankingDetailId;
  rankingId;
  rankings;
  i = 0;

  constructor(
    private route: ActivatedRoute,
    private api: CacheCospoService
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.playerId = params.get('playerId');
      this.rankingDetailId = params.get('ranking_detail_id');
      this.rankingId = params.get('rankingId');
    });
    this.api.getFanRankingByPlayer(this.rankingId, this.playerId, this.rankingDetailId)
      .subscribe(json => {
        this.rankings = json.content.slice(0, 20);
      })

  }
  trackByFn(index, item) { return item.id }
}
