import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Session } from '@app/@store/models/session';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscribable, Subscription } from 'rxjs';

@Component({
  selector: 'app-play-guide',
  templateUrl: './play-guide.component.html',
  styleUrls: ['./play-guide.component.scss']
})
export class PlayGuideComponent implements OnInit, OnDestroy {
  session: Session;
  pdfPlayer: string = "./assets/manual_cosplayer.pdf";
  pdfManual: string = "./assets/manual_user.pdf";
  private subscription: Subscription;
  constructor(private router: Router, private dialogRef: MatDialogRef<PlayGuideComponent>) { }
  pdfSrc;
  ngOnInit(): void {
    this.pdfSrc = this.session.isLayer === 1 ? this.pdfPlayer : this.pdfManual;
    this.subscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.dialogRef.close()
      }
    })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
