import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLanguage } from '../../../@store/actions/language.action';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  lang: string;
  constructor(readonly store: Store<any>) {
    store.select(getLanguage).subscribe(lang => this.lang = lang)
  }

  ngOnInit() {
  }

}
