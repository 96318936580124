<div class="user_profile">
  <div class="cardItem">
    <div class="trimming">
      <img class="image" [src]="user?.imageUrl||'./assets/images/no_image.jpg'" alt="{{user?.name}}"
        onerror="this.src='./assets/images/no_image.jpg'">
    </div>
  </div>
  <div class="text">
    <h3>{{ user?.name }}</h3>
    <p>{{'level'|translate}}: {{ user?.level }}</p>
    <p>{{ user?.message }}</p>
  </div>
</div>
