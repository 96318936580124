import * as moviesAction from '../actions/movie.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export interface MoviesReducer {
    movies: Array<any>,
    loading: boolean
}
export const initialState: MoviesReducer = {
    movies: [],
    loading: false
}
export function moviesReducer(state = initialState, action: moviesAction.MoviesActions) {
    switch (action.type) {
        case moviesAction.FETCH_MOVIES:
            return {
                ...state,
                movies: state.movies,
                loading: state.movies.length===0?true:false
            }
        case moviesAction.FETCH_MOVIES_SUCCESS:
            return {
                ...state,
                movies: action.data,
                loading: false
            }

        default:
            return state;
    }
}
export const movie = createFeatureSelector<MoviesReducer>('movies');
export const getMovies = createSelector(movie, (state: MoviesReducer) => state);
