import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../@store/services/session.service';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let userID = this.sessionService.session.login;
    let lang = localStorage.getItem('locale');
    let header = {
      'Accept-Language': lang||'ja',
    }
    if (userID) {
      header = { ...header, ...{ 'userID': userID } }
    }
    request = request.clone({ setHeaders: header });
    return next.handle(request);
  }
}
