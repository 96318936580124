<article *ngIf="lang==='ja'">
  <h2 class="cospo-title">
    法人の方へ
  </h2>
  KAWAII JAPAN では法人の方々とも取り組みをさせていただいております。
  <br>
  ご希望の法人様は
  <a routerlink="/contact" class="app-color font-bold-600">
    お問い合わせフォーム
  </a>
  よりご連絡ください
  <br>
  <br>
  <br>
  <img src="/assets/corp/player.png" width="100%" alt="プレイヤー">
  <!-- <mat-grid-list [cols]="3" >
  <mat-grid-tile *ngFor="let item of rankings;">
    <img [src]="item.image" alt="">
  </mat-grid-tile>
  </mat-grid-list> -->
  <div class="container">
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/odhition.png" alt="オーディション開催">
      <br>
      <div class="yellow">オーディション開催</div>
      <br>
      <div class="white">
        事務所様向けのオーディションや、
        <br>
        製作者様向けの企画など幅広く
        <br>
        開催できます。
      </div>
    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/create.png" alt="企業タイアップ">
      <br>
      <div class="yellow">
        企業タイアップ、
        <br>
        イベント制作、キャスティング
      </div>
      <br>
      <div class="white">
        アプリ内の中で商品やサービスとの
        <br>
        タイアップができます。
      </div>

    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/sanka.png" alt="アプリ参加！">
      <br>
      <div class="yellow"> アプリ参加！</div>
      <br>
      <div class="white">
        COSPOに参加いただける方を
        <br>
        幅広く募集しています。
        <br>
        <a routerLink="/register" style="color:#ffffff;">
          アプリ投稿参加申請ページ
        </a>
        より
        <br>
        申請してください。
      </div>

    </div>
  </div>

</article>
<article *ngIf="lang==='en'">
  <h2 class="cospo-title">
    CORPORATION
  </h2>
  So, we are working with the corporate people as well.
  <br>
  The company you would like to work for
  <a routerlink="/contact" class="app-color font-bold-600">
    Contact form
  </a>
  More please contact us
  <br>
  <br>
  <br>
  <img src="/assets/corp/player.png" width="100%" alt="プレイヤー">
  <!-- <mat-grid-list [cols]="3" >
    <mat-grid-tile *ngFor="let item of rankings;">
      <img [src]="item.image" alt="">
    </mat-grid-tile>
    </mat-grid-list> -->
  <div class="container">
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/odhition.png" alt="オーディション開催">
      <br>
      <div class="yellow"> Audition</div>
      <br>
      <div class="white">
        Audition for the office
        <br>
        A wide range of projects for creators
        <br>
        It can be held.
      </div>
    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/create.png" alt="企業タイアップ">
      <br>
      <div class="yellow">
        Corporate tie-up
        <br>
        Event production, casting
      </div>
      <br>
      <div class="white">
        With goods and services within the app
        <br>
        You can tie up.
      </div>

    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/sanka.png" alt="アプリ参加！">
      <br>
      <div class="yellow"> App participation</div>
      <br>
      <div class="white">
        You can join COSPO
        <br>
        We are looking for a wide range of candidates.
        <br>
        <a routerLink="register" style="color:#ffffff;">
          Application page
        </a>
        More
        <br>
        Please apply
      </div>

    </div>
  </div>

</article>
<article *ngIf="lang==='fr'">
  <h2 class="cospo-title">
    Pour les entreprises
  </h2>
  KAWAII JAPAN travaille également avec des gens d'affaires.
  <br>
  Veuillez nous contacter via le
  <a routerlink="/contact" class="app-color font-bold-600">
    Cformulaire de demande
  </a>
  <br>
  <br>
  <br>
  <img src="/assets/corp/player.png" width="100%" alt="プレイヤー">

  <div class="container">
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/odhition.png" alt="オーディション開催">
      <br>
      <div class="yellow"> Audition</div>
      <br>
      <div class="white">
        Audition pour le bureau
        <br>
        Un large éventail de projets pour les créateurs
        <br>
        Il peut être organisé.
      </div>
    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/create.png" alt="企業タイアップ">
      <br>
      <div class="yellow">
        Liaison d'entreprise
        <br>
        Production événementielle, casting
      </div>
      <br>
      <div class="white">
        Avec des biens et services dans l'application
        <br>
        Vous pouvez attacher.
      </div>

    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/sanka.png" alt="アプリ参加！">
      <br>
      <div class="yellow"> Participation à l'application</div>
      <br>
      <div class="white">
        Vous pouvez rejoindre COSPO
        <br>
        Nous recherchons un large éventail de candidats.
        <br>
        <a routerLink="/register" style="color:#ffffff;">
          Page d'application
        </a>
        Plus
        <br>
        Veuillez postuler
      </div>

    </div>
  </div>

</article>
<article *ngIf="lang==='kor'">
  <h2 class="cospo-title">
    법인쪽으로
  </h2>>
  KAWAII JAPAN에서는 법인들도 노력을 해 드리고 있습니다.
  <br>
  원하는 법인은
  <a routerlink="/contact" class="app-color font-bold-600">
    문의 폼
  </a>
  보다 연락해주십시오
  <br>
  <br>
  <br>
  <img src="/assets/corp/player.png" width="100%" alt="プレイヤー">

  <div class="container">
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/odhition.png" alt="オーディション開催">
      <br>
      <div class="yellow"> 오디션 개최</div>
      <br>
      <div class="white">
        사무실 고객을위한 오디션과<br>
        제작자 님을위한 기획 등 폭넓게<br>
        개최 할 수 있습니다.
      </div>
    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/create.png" alt="企業タイアップ">
      <br>
      <div class="yellow">
        기업 정체,
        <br>
        이벤트 제작, 캐스팅
      </div>
      <br>
      <div class="white">
        인앱에서 상품이나 서비스의<br>
        정체 있습니다.
      </div>

    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/sanka.png" alt="アプリ参加！">
      <br>
      <div class="yellow">응용 프로그램 참여!</div>
      <br>
      <div class="white">
        COSPO에 참가하실 분을
        <br>
        폭넓게 모집하고 있습니다.
        <br>
        <a routerLink="/register" style="color:#ffffff;">
          앱 게시 참가 신청 페이지
        </a>
        에서
        <br>
        신청하십시오.
      </div>

    </div>
  </div>

</article>
<article *ngIf="lang==='zh'">
  <h2 class="cospo-title">
    对于企业
  </h2>
  KAWAII JAPAN也在与企业人士合作
  <br>
  请使用询
  <a routerlink="/contact" class="app-color font-bold-600">
    与我们
  </a>
  联系
  <br>
  <br>
  <br>
  <img src="/assets/corp/player.png" width="100%" alt="プレイヤー">
  <div class="container">
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/odhition.png" alt="オーディション開催">
      <br>
      <div class="yellow">试镜</div>
      <br>
      <div class="white">
        我们可以举办
        <br>
        各种
        <br>
        活动，例如办公室的试镜和制作人的计划。
      </div>
    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/create.png" alt="企業タイアップ">
      <br>
      <div class="yellow">
        企业合作，
        <br>
        活动制作，铸造
      </div>
      <br>
      <div class="white">
        您可以在应用程序中搭配产品和服务。
      </div>

    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/sanka.png" alt="アプリ参加！">
      <br>
      <div class="yellow"> 加入应用程序！</div>
      <br>
      <div class="white">
        我们正在寻找可以参加COSPO的
        <br>
        广泛人群。请从
        <br>
        <a routerLink="/register" style="color:#ffffff;">
          申请提交申请页面申请
        </a>
      </div>
    </div>
  </div>
</article>
<article *ngIf="lang==='zh-TW'">
  <h2 class="cospo-title">
    對於企業
  </h2>
  KAWAII JAPAN也在與企業人士合作。
  <br>
  請使用
  <a routerlink="/contact" class="app-color font-bold-600">
    與我們
  </a>
  聯繫
  <br>
  <br>
  <br>
  <img src="/assets/corp/player.png" width="100%" alt="プレイヤー">
  <div class="container">
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/odhition.png" alt="オーディション開催">
      <br>
      <div class="yellow">試鏡</div>
      <br>
      <div class="white">
        我们可以举办
        <br>
        各种
        <br>
        活动，例如办公室的试镜和制作人的计划。
      </div>
    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/create.png" alt="企業タイアップ">
      <br>
      <div class="yellow">
        企业合作，
        <br>
        活动制作，铸造
      </div>
      <br>
      <div class="white">
        您可以在应用程序中搭配产品和服务。
      </div>

    </div>
    <div class="item">
      <br>
      <br>
      <img src="/assets/corp/sanka.png" alt="アプリ参加！">
      <br>
      <div class="yellow"> 加入应用程序！</div>
      <br>
      <div class="white">
        我们正在寻找可以参加COSPO的
        <br>
        广泛人群。请从
        <br>
        <a routerLink="/register" style="color:#ffffff;">
          申请提交申请页面申请
        </a>
      </div>
    </div>
  </div>
</article>
