import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from '../@store/services/session.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslationService } from '../@store/services/translation.service';
import { Store } from '@ngrx/store';
import { getLanguage } from '../@store/actions/language.action';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { TranslateService } from '@ngx-translate/core';
import { getProfile } from '@app/@store/reducers/user.reduder';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})

export class NavComponent implements OnInit, OnDestroy {
  query: string;
  queryPlayer: string;
  lang: string = 'ja';
  user: any;
  currentRouter: string;
  private subscriptions: Subscription[] = [];
  constructor(
    public sessionService: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslationService,
    readonly store: Store<any>,
    private translateService: TranslateService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentRouter = this.router.url;
      }
    });
  }
  ngOnInit() {
    if (!this.sessionService.session.login) {
      this.sessionService.authenticated();
    }
    const profileSub = this.store.select(getProfile).subscribe(mes => { this.user = mes });
    const langSub = this.store.select(getLanguage).subscribe(lang => this.lang = lang);
    this.subscriptions = [profileSub, langSub];
  }

  logout() {
    Swal.fire({
      title: this.translateService.instant('notify.title'),
      text: this.translateService.instant('logout_confirm'),
      confirmButtonText: this.translateService.instant('notify.confirm'),
      cancelButtonText: this.translateService.instant('notify.cancel'),
      showCancelButton: true,
      preConfirm: () => {
        this.sessionService.logout();
      }
    })
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("sidenav-close").style.display = "block";
    document.getElementById("sidenav-close").style.opacity = ".6";
    let body = <HTMLElement>document.getElementById('body');
    body.style.overflow = 'hidden';
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("sidenav-close").style.display = "none";
    document.getElementById("sidenav-close").style.opacity = "0";
    let body = <HTMLElement>document.getElementById('body');
    body.style.overflow = 'scroll';
  }

  searchPost() {
    this.router.navigate(['/post/search'], { queryParams: { "query": this.query, "search": true } });
    this.query = '';
  }

  searchPlayer() {
    this.router.navigate(['player/search', this.queryPlayer ?? ''], { relativeTo: this.route });
    this.queryPlayer = '';
  }
  changeLanguage(lang, code) {
    if (lang !== this.lang) {
      this.lang = lang;
      this.translate.setLanguage(lang, code);
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
