import { Component, OnInit, Input } from '@angular/core';
import { Session } from '@app/@store/models/session';
import { MatDialog } from '@angular/material/dialog';
import { PlayGuideComponent } from '@app/@components/play-guide/play-guide.component';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  @Input() session: Session;
  constructor(private matDialog: MatDialog) { }

  ngOnInit() {

  }
  openGuide() {
    const dialog = this.matDialog.open(PlayGuideComponent,{panelClass:'guide-dialog'});
    dialog.componentInstance.session = this.session;
  }
}
