import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateText'
})
export class TruncateTextPipe implements PipeTransform {

    transform(value: string, limit: number = 40, trail: String = '…'): string {
        let result = value || '';
        var b = 0;
        for (var i = 0; i < result.length; i++) {
            b += result.charCodeAt(i) <= 255 ? 1 : 2;
            if (b > limit) {
                return result.substr(0, i) + trail;
            }
        }
        return result;
    }
}