import { Action } from "@ngrx/store";

export const FETCH_EVENTS = '[EVENTS] GET LIST FETCH_EVENT';
export const FETCH_EVENTS_SUCCESS = '[EVENTS] GET LIST FETCH_EVENTS_SUCCESS';

export const FETCH_EVENTS_JOIN = '[EVENTS] GET LIST FETCH_EVENTS_JOIN';
export const FETCH_EVENTS_JOIN_SUCCESS = '[EVENTS] GET LIST FETCH_EVENTS_JOIN_SUCCESS';

export class FetchEvents implements Action {
    readonly type = FETCH_EVENTS;
}
export class FetchEventsSuccess implements Action {
    readonly type = FETCH_EVENTS_SUCCESS;
    constructor(public events: any) { }
}
export class FetchEventJoin implements Action {
    readonly type = FETCH_EVENTS_JOIN;
}
export class FetchEventJoinSuccess implements Action {
    readonly type = FETCH_EVENTS_JOIN_SUCCESS;
    constructor(public eventJoins: Array<any>) { }
}
export type EventActions = FetchEvents | FetchEventsSuccess | FetchEventJoin | FetchEventJoinSuccess;