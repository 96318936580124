import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Store } from '@ngrx/store';
import { getPlayerDetail } from '@app/@store/reducers/player.reducer';
import { FetchPlayerDetail } from '@app/@store/actions';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  user;
  private subscriptions: Subscription[] = [];
  constructor(
    private route: ActivatedRoute,
    private store: Store<any>
  ) { }

  ngOnInit() {
    const detailSub = this.store.select(getPlayerDetail).subscribe(json =>  this.user = json)
    const routerSub = this.route.params.subscribe(params => this.store.dispatch(new FetchPlayerDetail(params['id'])));
    this.subscriptions = [detailSub, routerSub];
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe())
  }
}
