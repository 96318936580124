import { Action } from "@ngrx/store";
import { FetchMyPostMoreSuccess } from './playerPost.action';


export const FETCH_PLAYER = '[PLAYER] GET LIST PLAYER';
export const FETCH_PLAYER_SUCCESS = '[PLAYER] GET LIST PLAYER SUCCESS';

export const FETCH_PLAYER_MORE = '[PLAYER] GET LIST PLAYER MORE';
export const FETCH_PLAYER_MORE_SUCCESS = '[PLAYER] GET LIST PLAYER MORE SUCCESS';

export const FETCH_PLAYER_HISTORY = '[HISTORY] GET HISTORY PLAYER';
export const FETCH_PLAYER_HISTORY_SUCCESS = '[HISTORY] GET HISTORY PLAYER SUCCESS';

export const FETCH_PLAYER_FAN = "[PLAYER] GET PLAYER FAN";
export const FETCH_PLAYER_FAN_SUCCESS = "[PLAYER] GET PLAYER FAN SUCCESS";

export const FETCH_PLAYER_DETAIL = "[PLAYER] GET PLAYER DETAIL";
export const FETCH_PLAYER_DETAIL_SUCCESS = "[PLAYER] GET PLAYER DETAIL SUCCESS";

export class FetchPlayers implements Action {
  readonly type = FETCH_PLAYER;
  constructor(public page: number, public size: number, public query: string) { }
}
export class FetchPlayersSuccess implements Action {
  readonly type = FETCH_PLAYER_SUCCESS;
  constructor(public players: Array<any>,public hasNext:boolean) { }
}

export class FetchMorePlayers implements Action {
  readonly type = FETCH_PLAYER_MORE;
  constructor(public page: number, public size: number, public query: string) { }
}
export class FetchPlayersMoreSuccess implements Action {
  readonly type = FETCH_PLAYER_MORE_SUCCESS;
  constructor(public players: Array<any>, public hasNext: boolean) { }
}

export class FetchPlayerHistory implements Action {
  readonly type = FETCH_PLAYER_HISTORY;
  constructor(public playerId: any) { }
}
export class FetchPlayerHistorySuccess implements Action {
  readonly type = FETCH_PLAYER_HISTORY_SUCCESS;
  constructor(public data: any) { }
}

export class FetchPlayerFan implements Action {
  readonly type = FETCH_PLAYER_FAN;
  constructor(public playerId: any) { }
}
export class FetchPlayerFanSuccess implements Action {
  readonly type = FETCH_PLAYER_FAN_SUCCESS;
  constructor(public data: any) { }
}

export class FetchPlayerDetail implements Action {
  readonly type = FETCH_PLAYER_DETAIL;
  constructor(public playerId: number) { }
}
export class FetchPlayerDetailSuccess implements Action {
  readonly type = FETCH_PLAYER_DETAIL_SUCCESS;
  constructor(public player: any) { }
}
export type PlayerActions =
  FetchPlayers
  | FetchPlayersSuccess
  | FetchMorePlayers
  | FetchPlayersMoreSuccess
  | FetchMyPostMoreSuccess
  | FetchPlayerHistory
  | FetchPlayerHistorySuccess
  | FetchPlayerFan
  | FetchPlayerFanSuccess
  | FetchPlayerDetail
  | FetchPlayerDetailSuccess;
