import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getProfile } from '@app/@store/reducers/user.reduder';
import { AuthService } from '@app/@store/services/auth.service';

@Component({
  selector: 'app-register-thanks',
  templateUrl: './register-thanks.component.html',
  styleUrls: ['./register-thanks.component.scss']
})
export class RegisterThanksComponent implements OnInit, OnDestroy {
  connectTwitter: boolean = false;
  constructor(
    private store: Store<any>,
    private api: AuthService,
    private activeRouter: ActivatedRoute
  ) { }
  private subscriptions: Subscription[] = [];
  ngOnInit() {
    const storeSub = this.store.select(getProfile)
      .subscribe(json => {
        if (json) {
          this.connectTwitter = (json.socialProviders || []).find(x => x.providerType === 'TWITTER') ? true : false;
        }
      })
    this.subscriptions.push(storeSub);
    const routerSub = this.activeRouter.queryParams.subscribe((params: any) => {
      this.api.connectTwitter(params)
    });
    this.subscriptions.push(routerSub);
  }
  checkTwitter() {
    this.api.redirectTwitterConnect();
  }
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe())
  }
}
