import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from './loading.service';
import { finalize, map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from './session.service';
import { UserInfo } from '../models/user';
@Injectable({ providedIn: 'root' })
export class CacheCospoService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private matSnackBar: MatSnackBar,
    private session: SessionService
  ) { }
  getEvents() {
    return this.http.get(`${environment.apiUrl}/api/event`);
  }
  getPlayerPost(currentPage: number, type: string) {
    return this.http.get<any>(`${environment.apiUrl}/api/playerPost/grade/${type}?page=${currentPage}`)
  }
  getGoldPost(currentPage) {
    return this.http.get<any>(`${environment.apiUrl}/api/playerPost/grade/GOLD?page=${currentPage}`)
  }

  getSliverPost(currentPage) {
    return this.http.get<any>(`${environment.apiUrl}/api/playerPost/grade/SILVER?page=${currentPage}`)
  }

  getVtuberPost(currentPage) {
    return this.http.get<any>(`${environment.apiUrl}/api/playerPost/grade/VTUBER?page=${currentPage}`)
  }

  getNewList() {
    return this.http.get<any>(`${environment.apiUrl}/api/news_list`)
  }
  getRankings() {
    return this.http.get<any>(`${environment.apiUrl}/api/ranking`)
  }

  getMovies() {
    return this.http.get<any>(`${environment.apiUrl}/api/movie`)
  }
  getProfile() {
    return this.http.get<UserInfo>(`${environment.apiUrl}/api/user/me`)
      .pipe(
        map(((json: any) => {
          this.session.session.point = json.point;
          this.session.session.userId = json.id;
          this.session.session.name = json.name;
          this.session.session.image = json.image;
          this.session.session.level = json.level;
          this.session.session.nextLevel = json.cheerCountNextLevel;
          this.session.session.cheerCount = json.cheerCount;
          this.session.session.cheerBar = Math.floor(json.cheerCount / json.maxStamina * 100) / 100 * 100;
          return json;
        })),
      )
  }
  getFavorite(currentPage) {
    return this.http.get<any>(`${environment.apiUrl}/api/user/favorite/player?page=${currentPage}`)
  }
  getHistory(currentPage: number) {
    return this.http.get<any>(`${environment.apiUrl}/api/user/cheer/playerPost?page=${currentPage}`)
  }
  getKawaiiPlayer() {
    return this.http.get<any>(`${environment.apiUrl}/api/user/cheer/player`)
  }
  getPlayer(page: number, size: number, query: string) {
    return this.http.get<any>(`${environment.apiUrl}/api/player?page=${page}&size=${size}&query=${query}`)
  }
  getHistoryPlayer(playerId) {
    return this.http.get(`${environment.apiUrl}/api/player/history?playerId=${playerId}`)
  }
  searchPost(query, currentPage) {
    return this.http.get<any>(`${environment.apiUrl}/api/playerPost/search?query=${query}&page=${currentPage}`)
  }
  getPlayerBlock() {
    return this.http.get<any>(`${environment.apiUrl}/api/block`)
  }
  getMyPost(playerId, currentPage) {
    return this.http.get<any>(`${environment.apiUrl}/api/playerPost?favorite=false&layer_id=${playerId}&page=${currentPage}`)
  }
  getDetailRanking(rankingId) {
    return this.http.get<any>(`${environment.apiUrl}/api/ranking_detail?ranking_id=${rankingId}`)
  }
  getEventJoins() {
    return this.http.get(`${environment.apiUrl}/api/ranking/entry`);
  }
  getListPastRanking() {
    return this.http.get<any>(`${environment.apiUrl}/api/ranking/old`)
  }
  postDetail(postId) {
    return this.http.get<any>(`${environment.apiUrl}/api/playerPost/${postId}`)
  }
  detailNew(newId) {
    return this.http.get<any>(`${environment.apiUrl}/api/news?id=${newId}`)
  }
  getPlayFan(playerId) {
    return this.http.get<any>(`${environment.apiUrl}/api/player/${playerId}/ranking`)
  }
  updatePost(postId, text): void {
    this.http.put<any>(`${environment.apiUrl}/api/playerPost/${postId}?text=${text}`, {})
      .subscribe(() => {
        this.router.navigate(['/detail/' + postId]);
      });
  }
  getPlayerDetail(playerId) {
    return this.http.get<any>(`${environment.apiUrl}/api/player/${playerId}`)
  }
  getRakingDaily(target_date) {
    return this.http.get<any>(environment.apiUrl + '/api/player/me/ranking/daily' + '?targetDate=' + target_date)
  }
  checkSocialProvider() {
    return this.http.get<any>(`${environment.apiUrl}/api/user/socialProvider`)
  }
  toggleFavorite(playerId, isfavorite) {
    return this.http.post<any>(`${environment.apiUrl}/api/favorite_regist?layer_id=${playerId}&isfavorite=${isfavorite}`, {})
  }
  toggleBlockPlayer(playerId, isBlock) {
    return isBlock ? this.http.post<any>(`${environment.apiUrl}/api/block?playerId=${playerId}`, {}) : this.http.delete<any>(`${environment.apiUrl}/api/block?playerId=${playerId}`)
  }
  getBonus() {
    return this.http.post<any>(`${environment.apiUrl}/api/user/bonus`, {})
  }
  resetMail(mail) {
    this.http.post<any>(environment.apiUrl + '/api/user/password/reset' + '?mail=' + encodeURIComponent(mail), {})
      .subscribe(() => {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'error': this.translateService.instant('sent_reset_password')
          }
        };
        this.router.navigate(['/login'], navigationExtras);
      })
  }
  getRankingPost() {
    return this.http.get<any>(environment.apiUrl + '/api/ranking/entry?onlyEntried=true');
  }
  createdPost(postForm: FormGroup, twitterId, img_file): void {
    this.loadingService.loadStart();
    const url = `${environment.apiUrl}/api/playerPost?text=${postForm.value.text}&rankingId=${postForm.value.rankingId}&twitterUserIds=${twitterId}`;
    this.http.post<any>(url, img_file)
      .pipe(finalize(() => this.loadingService.loadEnd()))
      .subscribe(() => {
        this.router.navigate(['/my-posts']);
      }, (err: HttpErrorResponse) => {
        this.matSnackBar.open(err.message, 'Close', { duration: 2000, panelClass: 'snackBarCospo' })
      });

  }
  getFanRankingByPlayer(rankingId, playerId, detailId) {
    this.loadingService.loadStart();
    return this.http.get<any>(`${environment.apiUrl}/api/ranking/player/user?ranking_detail_id=${detailId}&rankingId=${rankingId}&playerId=${playerId}`)
      .pipe(finalize(() => this.loadingService.loadEnd()))
  }
  jointEvent(id) {
    return this.http.post<any>(environment.apiUrl + '/api/ranking/' + id + '/entry', {})
  }
  getAdvertisementLink() {
    return this.http.get<any>(`${environment.apiUrl}/api/adsPlayerPostOutsideHoneFeed`)
  }
  blobToFile(blob: Blob, name): File {
    var b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = name;
    return <File>b;
  }
}
