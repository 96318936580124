import * as userActions from '../actions/user.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserInfo, User } from '../models/user';
export interface UserReducer {
  profile: UserInfo
}
export const initialState: UserReducer = {
  profile: new UserInfo()
}
export function userReducer(state = initialState, action: userActions.ProfileActions) {
  switch (action.type) {
    case userActions.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile
      }
    case userActions.CHANGE_USER_POINT:
      return {
        ...state,
        profile: Object.assign({}, state.profile, { point: action.point })
      }
    case userActions.CHANGE_USER_AVATAR:
      return {
        ...state,
        profile: Object.assign({}, state.profile, { image: action.image, imageUrl: action.image })
      }
    case userActions.CHANGE_USER_NEXT_LEVER:
      return {
        ...state,
        profile: Object.assign({}, state.profile, { cheerCountNextLevel: action.nextLever })
      }
    default:
      return state;
  }
}
export const profile = createFeatureSelector<UserReducer>('profile');
export const getProfile = createSelector(profile, (state: UserReducer) => state.profile);
