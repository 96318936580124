import { NgModule } from '@angular/core';
import { TruncateTextPipe } from './truncate';
import { ImagePipe } from './image.pipe';

const COMPONENT = [TruncateTextPipe,ImagePipe];
@NgModule({
    imports: [],
    exports: COMPONENT,
    declarations: COMPONENT,
})
export class PipeModule { }
