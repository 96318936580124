<div class="post">
	<h1 translate="post_detail.title"></h1>
	<div class="cardItem">
	  <div class="trimming">
	    <img class="image" src="{{ post?.imageUrl }}" alt="{{post?.name}}">
	  </div>
	  <div class="text">
		<h3>{{ post?.name }}</h3>
		<p><span style="color: red">♥</span>：{{ post?.cheer_count | number }}</p>
		<p>{{ post?.post_time }}</p>
		<p>{{ post?.text }}</p>
	  </div>
	</div>
 <div *ngIf="sessionService.session.userId === player_id">
 	<div style="text-align:center;margin:10px auto;">
		<button class="page_link" (click)="postDelete()" translate="delete"></button>
		<a [routerLink]="['/post-edit', post?.id]"><button class="page_link" translate="edit"></button></a>
	</div>
</div>
</div>



