import * as rankingActions from '../actions/ranking.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export interface RankingReducer {
  ranking_pasts: Array<any>;
  rankings: Array<any>;
  detail: Array<any>;
  loading: boolean;
}
export const innitSate: RankingReducer = {
  rankings: [],
  loading: false,
  detail: [],
  ranking_pasts: []
}
export function rankingReducer(state = innitSate, action: rankingActions.RankingActions) {
  switch (action.type) {
    // case rankingActions.FETCH_DETAIL_RANKING:
    //   return {
    //     ...state,
    //     loading: true
    //   }
    // case rankingActions.FETCH_RANKING:
    //   return {
    //     ...state,
    //     loading: state.rankings.length === 0 ? true : false
    //   }
    // case rankingActions.FETCH_RANKING_PAST:
    //   return {
    //     ...state,
    //     loading: state.ranking_pasts.length === 0 ? true : false
    //   }
    case rankingActions.FETCH_RANKING__PAST_SUCCESS:
      return {
        ...state,
        ranking_pasts: action.rankings,
        loading: false
      }
    case rankingActions.FETCH_RANKING_SUCCESS:
      return {
        ...state,
        rankings: action.rankings,
        loading: false
      }
    case rankingActions.FETCH_DETAIL_RANKING_SUCCESS:
      return {
        ...state,
        detail: action.payload,
        loading: false
      }
    default:
      return state;
  }
}
export const rankings = createFeatureSelector<RankingReducer>('rankings');
export const getRankings = createSelector(rankings, (state: RankingReducer) => state);
export const getDetailRanking = createSelector(rankings, (state: RankingReducer) => state);
export const getRankingPast = createSelector(rankings, (state: RankingReducer) => state);
