import { Action } from "@ngrx/store";

export const FETCH_NEWS = "[NEWS] GET LIST FETCH_NEWS";
export const FETCH_NEWS_SUCCESS = "[NEWS] GET LIST FETCH_NEWS_SUCCESS";

export class FetchNews implements Action {
    readonly type = FETCH_NEWS;
    constructor(public currentPage: number) { }
}
export class FetchNewsSuccess implements Action {
    readonly type = FETCH_NEWS_SUCCESS;
    constructor(public data: any, public hasNext: boolean) { }
}

export type NewsActions = FetchNews | FetchNewsSuccess;