import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { LoadingService } from '@app/@store/services/loading.service';
import { finalize } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getLanguage } from '@app/@store/actions';
import { getProfile } from '@app/@store/reducers/user.reduder';
import { CacheCospoService } from '@app/@store/services/cache.service';
@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss'],
})
export class NewsDetailComponent implements OnInit {
  news: any;
  user;
  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private store: Store<any>,
    private apiService: CacheCospoService
  ) { }

  ngOnInit() {
    this.store.select(getLanguage).subscribe(lang => {
      this.route.params.subscribe((params: any) => {
        this.fechDetailNew(params['id']);
      })
    })
    
  }
  fechDetailNew(newId: String) {
    this.loadingService.loadStart();
    this.apiService.detailNew(newId)
      .pipe(finalize(() => this.loadingService.loadEnd()))
      .subscribe(json => {
        this.news = json;
        console.log(this.news);
        
        this.store.select(getProfile).subscribe(user => {      
          if (user) {
            
            this.news = {
              ...this.news,
              urls: this.news.urls.map(url => {
                let urlObj = new URL(url.url);
                urlObj.searchParams.set("u", user.id.toString());
                return {
                  ...url,
                  url: urlObj.href
                }
              })
            }
          }
        });
      })
  }
}
