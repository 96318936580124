import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { LanguageReducer, getLanguage } from '../../../@store/actions';

@Component({
  selector: 'app-shiketsuhou',
  templateUrl: './shiketsuhou.component.html',
  styleUrls: ['./shiketsuhou.component.scss']
})
export class ShiketsuhouComponent implements OnInit,OnDestroy {
  private subscription: Subscription;
  lang: any;
  constructor(private store: Store<LanguageReducer>) {
    this.subscription = this.store.select(getLanguage).subscribe(lang => this.lang = lang);
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
