import * as playerPostAction from '../actions/playerPost.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export interface PlayerPostReducer {
  goldPosts: any,
  sliverPost: any,
  vtuberPost: any,
  historyPost: any,
  favoritePost: any,
  searchPost: any,
  kawaiPlayer: any,
  cosplayersBlock: any,
  mypost: any,
  post: any,
}
export const initialState: PlayerPostReducer = {
  goldPosts: {
    hasNext: false,
    loading: false,
    data: []
  },
  sliverPost: {
    hasNext: false,
    loading: false,
    data: []
  },
  vtuberPost: {
    hasNext: false,
    loading: false,
    data: []
  },
  historyPost: {
    hasNext: false,
    loading: false,
    data: []
  },
  favoritePost: {
    hasNext: false,
    loading: false,
    data: []
  },
  searchPost: {
    hasNext: false,
    loading: false,
    data: []
  },
  kawaiPlayer: {
    hasNext: false,
    loading: false,
    data: []
  },
  cosplayersBlock: {
    data: [],
    loading: false,
  },
  mypost: {
    posts: [],
    loading: false,
    hasNext: false,
    playerId: null
  },
  post: {}
}
export function playerPostReducer(state = initialState, action: playerPostAction.PlayerPostActions) {

  switch (action.type) {
    case playerPostAction.FETCH_ERROR:
      return {
        ...state,
        goldPosts: Object.assign({}, state.goldPosts, { loading: false })
      }
    case playerPostAction.FETCH_GOLD_POST:
      return {
        ...state,
        goldPosts: Object.assign({}, state.goldPosts, { loading: state.goldPosts.data.length === 0 ? true : false })
      }
    case playerPostAction.FETCH_SILVER_POST:
      return {
        ...state,
        sliverPost: Object.assign({}, state.sliverPost, { loading: state.sliverPost.data.length === 0 ? true : false })
      }
    case playerPostAction.FETCH_VTUBER_POST:
      return {
        ...state,
        vtuberPost: Object.assign({}, state.vtuberPost, { loading: state.vtuberPost.data.length === 0 ? true : false })
      }
    case playerPostAction.FETCH_FAVORITE:
      return {
        ...state,
        favoritePost: Object.assign({}, state.favoritePost, { loading: state.favoritePost.data.length === 0 ? true : false })
      }
    case playerPostAction.FETCH_HISTORY_WAKAII:
      return {
        ...state,
        historyPost: Object.assign({}, state.historyPost, { loading: state.historyPost.data.length === 0 ? true : false })
      }
    case playerPostAction.FETCH_BLOCK_COSPLAYER:
      return {
        ...state,
        cosplayersBlock: Object.assign({}, state.cosplayersBlock, { loading: state.cosplayersBlock.data.length === 0 ? true : false })
      }
    case playerPostAction.FETCH_MYPOST:
      return {
        ...state,
        mypost: Object.assign({}, state.mypost, { loading: state.mypost.length === 0 ? true : false, posts: action.playerId == state.mypost.playerId ? state.mypost.posts : [], playerId: action.playerId })
      }
    case playerPostAction.FETCH_POST_DETAIL:
      return {
        ...state,
        post: state.post.id == action.postId ? state.post : {}
      }
    case playerPostAction.FETCH_KAWAII_PLAYER:
      return {
        ...state,
        kawaiPlayer: Object.assign({}, state.kawaiPlayer, { loading: state.kawaiPlayer.data.length === 0 ? true : false })
      }
    case playerPostAction.FETCH_POST_DETAIL_SUCCESS:
      return {
        ...state,
        post: action.payload
      }
    case playerPostAction.FETCH_GOLD_POST_SUCCESS:
      return {
        ...state,
        goldPosts: {
          hasNext: action.hasNext,
          data: action.data,
          loading: false
        }
      }
    case playerPostAction.FETCH_GOLD_POST_MORE_SUCCESS:
      return {
        ...state,
        goldPosts: {
          hasNext: action.hasNext,
          data: [...state.goldPosts.data, ...action.data]
        }
      }
    case playerPostAction.FETCH_SILVER_POST_SUCCESS:
      return {
        ...state,
        sliverPost: {
          hasNext: action.hasNext,
          data: action.data
        }
      }
    case playerPostAction.FETCH_SILVER_POST_MORE_SUCCESS:
      return {
        ...state,
        sliverPost: {
          hasNext: action.hasNext,
          data: [...state.sliverPost.data, ...action.data]
        }
      }
    case playerPostAction.FETCH_VTUBER_POST_SUCCESS:
      return {
        ...state,
        vtuberPost: {
          hasNext: action.hasNext,
          data: action.data
        }
      }
    case playerPostAction.FETCH_VTUBER_POST_MORE_SUCCESS:
      return {
        ...state,
        vtuberPost: {
          hasNext: action.hasNext,
          data: [...state.vtuberPost.data, ...action.data]
        }
      }
    case playerPostAction.FETCH_HISTORY_WAKAII_SUCCESS:
      return {
        ...state,
        historyPost: {
          hasNext: action.hasNext,
          data: action.data,
          loading: false
        }
      }
    case playerPostAction.FETCH_HISTORY_WAKAII_MORE_SUCCESS:
      return {
        ...state,
        historyPost: {
          hasNext: action.hasNext,
          data: [...state.historyPost.data, ...action.data],
          loading: false
        }
      }
    case playerPostAction.FETCH_FAVORITE_SUCCESS:
      return {
        ...state,
        favoritePost: {
          hasNext: action.hasNext,
          data: action.data,
          loading: false
        }
      }
    case playerPostAction.FETCH_FAVORITE_MORE_SUCCESS:
      return {
        ...state,
        favoritePost: {
          hasNext: action.hasNext,
          data: [...state.favoritePost.data, ...action.data],
          loading: false
        }
      }
    case playerPostAction.FETCH_SEARCH_POST_SUCCESS:
      return {
        ...state,
        searchPost: {
          hasNext: action.hasNext,
          data: action.data
        }
      }
    case playerPostAction.FETCH_SEARCH_POST_MORE_SUCCESS:
      return {
        ...state,
        searchPost: {
          hasNext: action.hasNext,
          data: [...state.searchPost.data, ...action.data]
        }
      }
    case playerPostAction.FETCH_KAWAII_PLAYER_SUCCESS:
      return {
        ...state,
        kawaiPlayer: {
          data: action.data,
          loading: false,
          hasNext: action.hasNext
        }
      }
    case playerPostAction.FETCH_BLOCK_COSPLAYER_SUCCESS:
      return {
        ...state,
        cosplayersBlock: {
          data: action.cosplayers,
          loading: false
        }
      }
    case playerPostAction.FETCH_MYPOST_SUCCESS:
      return {
        ...state,
        mypost: {
          hasNext: action.hasNext,
          posts: action.posts,
          loading: false,
          playerId: state.mypost.playerId
        }
      }
    case playerPostAction.FETCH_MYPOST_MORE_SUCCESS:
      return {
        ...state,
        mypost: {
          hasNext: action.hasNext,
          posts: [...state.mypost.posts, ...action.posts],
          loading: false
        }
      }
    default:
      return state;
  }
}
export const playerPost = createFeatureSelector<PlayerPostReducer>('playerPost');
export const getGoldPost = createSelector(playerPost, (state: PlayerPostReducer) => state.goldPosts);
export const getSilverPost = createSelector(playerPost, (state: PlayerPostReducer) => state.sliverPost);
export const getVtuberPost = createSelector(playerPost, (state: PlayerPostReducer) => state.vtuberPost);
export const getHistoryPost = createSelector(playerPost, (state: PlayerPostReducer) => state.historyPost);
export const getFavoritePost = createSelector(playerPost, (state: PlayerPostReducer) => state.favoritePost);
export const getSearchPost = createSelector(playerPost, (state: PlayerPostReducer) => state.searchPost);
export const getKwaiiPlayer = createSelector(playerPost, (state: PlayerPostReducer) => state.kawaiPlayer);
export const getCosplayersBlocks = createSelector(playerPost, (state: PlayerPostReducer) => state.cosplayersBlock);
export const getMyPost = createSelector(playerPost, (state: PlayerPostReducer) => state.mypost);
export const getPostDetail = createSelector(playerPost, (state: PlayerPostReducer) => state.post);
