import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from '../../../@store/services/session.service';
import { FormGroup, FormControl } from '@angular/forms'
import { Store } from '@ngrx/store';
import { getLanguage } from '@app/@store/actions/language.action';
import { getProfile } from '@app/@store/reducers/user.reduder';
import { Subscription } from 'rxjs';

import { AuthService } from '@app/@store/services/auth.service';
import { cospoAnimations } from '@app/@animations';
@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss'],
  animations:cospoAnimations
})
export class WithdrawalComponent implements OnInit, OnDestroy {
  withdrawalForm = new FormGroup({
    email: new FormControl(''),
    epw: new FormControl(''),
  });
  point;
  lang;
  private subscription: Subscription;
  constructor(
    public sessionService: SessionService,
    readonly store: Store<any>,
    private authService: AuthService
  ) {
    store.select(getLanguage).subscribe(lang => this.lang = lang);
  }
  ngOnInit() {
    this.subscription = this.store.select(getProfile).subscribe(json => {
      this.point = json?.point || 0;
    })
  }

  onSubmit() {
    this.authService.unSubscriptionAccount();

  }
  ngOnDestroy() { this.subscription.unsubscribe() }
}
