<article>
  <ng-container *ngIf="lang==='ja'">
    <h2 class="cospo-title">COSPO利用規約</h2>
    <div class="entry entry-content">
      <p>&nbsp;</p>
      <h1>COSPO利用規約</h1>
      <p>第１条（目的）<br>
        本規約は、KAWAIIJAPAN株式会社（以下「当社」といいます。）が提供するサービス「COSPO(コスポ）」をユーザー（第２条に定義します）が利用する際の条件を定めるものです。</p>
      <p>&nbsp;</p>
      <p>第２条（定義）<br>
        本規約における用語の定義は以下のとおりとします。<br>
        （１）｢本サービス｣<br>
        当社が運営する「COSPO(コスポ）」において提供されるすべてのサービスのことをいいます。</p>
      <p>（２）｢ユーザー｣<br>
        本規約を承認した上で、当社所定の手続に従って本サービスの利用を申し込んだ個人または当社の承諾を得た個人以外の者で、当社がユーザー登録を承認した者をいいます。</p>
      <p>（３）「ユーザー情報」<br>
        ユーザーが本サービスを利用する上で必要となる、当初所定のフォーマットに従い当社が登録または提供を求める情報をいいます。</p>
      <p>（４）「ユーザーコンテンツ」</p>
      <p>本サービス内においてユーザーが送信、投稿し、もしくは登録、表示等（以下、これらの行為を総称して「表記」といいます）したすべての情報をいいます。</p>
      <p>（５）「提供コンテンツ」<br>
        ユーザーが本サービスを利用するために当社が提供する写真、画像、動画、文章、音声、アイテムその他の情報（ユーザーコンテンツを含みます）のことをいいます。</p>
      <p>（６）「ポイント」<br>
        本サービスにおいて有償で提供されるサービス又はその他当社が指定したコンテンツと交換できる点数をいいます。</p>
      <p>&nbsp;</p>
      <p>第３条（規約の同意、適用範囲）<br>
        １．ユーザーは、本規約の定めに従って本サービスを利用しなければなりません。</p>
      <p>２．ユーザーは、当社の提供するアプリ「COSPO」をスマートフォンその他の情報端末にダウンロードし本規約への同意手続を行わない限り本サービスを利用できません。</p>
      <p>
        ３．本サービスの提供にあたり、別途定める規約、当社が実施する利用条件の告知、本サービスに関するヘルプおよびユーザーに対して発信する通知等に記載された情報（以下「関連規定」といいます）も本規約の一部を構成するものとし、当該告知、通知等の内容が本規約の条項と異なる定めをする場合、当該告知、通知等の定めが優先して適用されるものとします。
      </p>
      <p>&nbsp;</p>
      <p>第４条（ユーザー情報の登録）<br>
        １．ユーザーとなることを希望する者は、本サービスを利用するにあたり、本規約を承認の上で当社が指定する手続に従って、ユーザー登録手続を行うものとします。</p>
      <p>２．ユーザーが未成年の場合は、法定代理人の同意（本規約への同意を含みます）を得なければなりません。</p>
      <p>
        ３．当社は、ユーザー登録手続が行われたときは、当社所定の認証手続にしたがい、ユーザー情報の登録を行い、アカウントを付与するものとします。ただし、当社は不適当と認めたときはユーザー登録を拒絶することができ、その場合、当該登録手続を行った者に対し、拒絶理由を開示する義務を負わないものとします。<br>
        ４．前項に規定するアカウントの付与をもって、ユーザーと当社との間において、本サービスの利用契約が成立するものとします。なお、当社が不適当と判断する場合には、アカウントの付与の後であってもアカウントを取り消し、本サービスの利用契約を解除する場合があります。
        ただし、当社は承諾を取り消した理由について回答する義務を負いません。</p>
      <p>&nbsp;</p>
      <p>第５条（変更の届出）<br>
        １．ユーザー情報に変更が生じた場合、ユーザーはすみやかに当該登録情報の変更手続を行うものとします。<br>
        ２．ユーザー情報に虚偽、誤りがあった場合またはユーザーが前項の届出を怠ったことにより不利益を被ったとしても､当社は一切の責任を負わないものとします。</p>
      <p>&nbsp;</p>
      <p>第６条（アカウントの削除等）<br>
        １．ユーザーが以下の各号の一に該当する場合、または当社が不適当と判断した場合、当社は、事前に通知することなく、当該ユーザーに対する本サービスの利用の停止もしくは制限、アカウントの削除を含む利用契約の解除等の措置をとることができるものとします。なお、当社は、当該措置の理由を開示する義務を負わないものとします。<br>
        （１）本規約のいずれかの条項に違反した場合</p>
      <p>（２）当社に提供した情報に虚偽があった場合<br>
        （３）ユーザーが未成年者、成年被後見人、被保佐人または被補助人のいずれかである場合で、法定代理人、成年後見人、保佐人もしくは補助人の同意等を得ていなかった場合<br>
        （４）ユーザーが死亡し、または後見、保佐もしくは補助が開始されたとき</p>
      <p>（５）その他、ユーザーとして不適切もしくは登録の継続が困難であると当社が判断した場合</p>
      <p>（６）その他、前各号に類する事由があると当社が判断した場合</p>
      <p>２．前項によりユーザー登録の削除もしくは利用契約の解除を受けた場合において、当該ユーザーが再度ユーザー登録申請をした場合、当社は、当該申請を承諾しない場合があります。<br>
        ３．当社は、本条に基づき当社が行った措置によりユーザーに生じた不利益、損害について、一切の責任を負わないものとします。</p>
      <p>第７条（ユーザーコンテンツの取り扱い）</p>
      <p>１．ユーザーコンテンツを表記したユーザーは、当該ユーザーコンテンツに関しすべての責任を負うものとします。</p>
      <p>２．ユーザーは、次の情報を表記することはできません。</p>
      <p>（1）当社または他のユーザーその他の第三者の著作権等知的財産権、プライバシー、肖像権、パブリシティ権その他の権利もしくは利益を侵害するもの</p>
      <p>（2）他のユーザー、第三者または当社を誹謗中傷し、または名誉もしくは信用を傷つけるもの</p>
      <p>（3）他の利用者、第三者または当社に不利益を与えるもの</p>
      <p>（4）法令、行政措置に違反しもしくはこれらを助長するものまたは犯罪に結びつくもの</p>
      <p>（5）公序良俗に反するもの</p>
      <p>（6）反社会的勢力に対する利益供与その他の協力行為</p>
      <p>（7）援助交際・売春・買春等を行い、またはそれらを勧誘するもの</p>
      <p>（8）児童（満18歳未満の男女をいいます。）を異性交際の相手方となるよう勧誘し、または大人に対し児童との異性交際の相手方となるよう勧誘するもの</p>
      <p>（9）営業、宣伝、広告、勧誘その他の営利を目的とするもの（当社の認めるものを除きます）、宗教活動もしくは宗教団体への勧誘、政治活動への勧誘等、本サービスの趣旨に反するもの</p>
      <p>（10）コンピューターウィルスなど有害なプログラムを使用または含むもの</p>
      <p>(11）過度にわいせつな表現を含むもの、残虐な表現その他他人に不快感を与える恐れのあるもの</p>
      <p>(12）その他当社が不適切と判断するもの</p>
      <p>
        ３．当社は、ユーザーコンテンツが本規約に違反する場合その他、当社が不適当と判断したときは、事前に通知することなく、ユーザーコンテンツの全部または一部を削除することができるものとします。なお、当社は、当該措置の理由を開示する義務を負わないものとします。
      </p>
      <p>４．当社は、ユーザーコンテンツを、無償で、本サービスの提供及び利用促進のために、複製、編集、展示、公衆送信、公表等して利用することができ、ユーザーはこのことをあらかじめ当社に許諾するものとします。</p>
      <p>５．ユーザーは、ユーザーコンテンツについて、当社以外の第三者に対し使用許諾することはできないものとします。</p>
      <p>第８条（ユーザーアカウントの管理）<br>
        １．ユーザーは、本サービスのアカウントを、自己の責任にて管理するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更等してはなりません。<br>
        ２．ユーザーは、本サービスのアカウントを任意のメールアドレスおよびパスワードにて管理することができます。ユーザーは、自己が登録したメールアドレスおよびパスワードが不正に利用されることのないよう自己の責任で厳重に管理しなければなりません。<br>
        ３．メールアドレスおよびパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任はユーザーが負うものとし、当社は一切責任を負いません。<br>
        ４．アカウントを通じた本サービスの利用については、ユーザー本人以外の第三者による利用および行為の場合も含め、すべて当該ユーザーによる利用および行為とみなします。当該ユーザーは、当該利用および行為により生じた一切の債務を負担するものとします。<br>
        ５．ユーザーは、メールアドレスおよびパスワードが不当に取得され、あるいはアカウントが第三者により利用されもしくはそのおそれがある場合は、直ちに当社にその旨連絡するとともに、当社からの指示がある場合には、これに従うものとします。
      </p>
      <p>６．当社は、アカウントの管理不十分または第三者の使用等による損害に関し、一切の責任を負いません。</p>
      <p>７．ユーザーが誤ってアカウントを削除するなどユーザーの行為によりアカウントが削除された場合、ユーザーは、アカウント、コンテンツその他本サービスに関する情報を利用することができなくなることをあらかじめ承諾するものとします。
      </p>
      <p>&nbsp;</p>
      <p>第９条（退会）<br>
        １．ユーザーは、当社が指定する退会手続により退会して本サービスの利用を停止することができます。<br>
        ２．前条第３項ないし第６項は、本条に基づき退会した場合にも適用されるものとします。</p>
      <p>３．ユーザーが退会した場合にも、有償サービスの料金の返還は行いません。</p>
      <p>&nbsp;</p>
      <p>第１０条（本規約の改定・変更）<br>
        １．当社は、必要に応じていつでも本規約及び関連規定を変更または改定することができるものとします。なお、本規約及び関連規定の変更は、本サービス内または当社が運営するウェブサイト上に掲示された時点で効力が生じるものとし、変更後にユーザーが本サービスを利用した場合は変更後の規約に同意したものとみなします。<br>
        ２．当社は、将来において本サービスの事業を継承する会社に、本サービスに関する当社の地位を譲渡する場合があります。この場合の地位の譲渡の手続には、前項の規定を準用するものとします。</p>
      <p>&nbsp;</p>
      <p>第１１条（有償のサービスの販売）<br>
        １．当社は、本サービスの一部をユーザーに有償で提供することがあります。<br>
        ２．ユーザーは、前項に定める有償の本サービス（以下「有償サービス」といいます。）の利用権を、当社が別途定め本サービス上に掲載する対価を支払うことにより取得することができます。<br>
        ３．ユーザーは、取得した有償のサービスを、他のユーザーに移転、譲渡、貸与し又は本サービスその他類似のサービスに表記しないものとします。</p>
      <p>４．当社は、有償サービスについて、当社の裁量でその価格を変更することがあります。</p>
      <p>&nbsp;</p>
      <p>第１２条（ポイント）<br>
        １．ポイントは、本サービス上で「購入」その他当社が指定する方法によりユーザーに付与されるものとします。ポイントの購入単位、支払方法、その他の付与条件は当社が別途定め本サービス上に掲載するものとします。<br>
        ２．ポイントは、有償のサービスその他当社が指定したコンテンツとのみ交換または利用することができ、その他のコンテンツ又はサービス、現金その他経済上の利益と交換または利用することはできません。交換または利用するために必要なポイント数、その他ポイントの利用条件は、当社が別途定め本サービス上に掲載するものとします。<br>
        ３．当社は、理由の如何を問わず、ポイントの再発行及び換金をいたしません。また、ポイントの払戻しは一切いたしません。<br>
        ４．当社が本サービスを終了もしくは中止する場合、または、第６条の規定によるアカウントの削除、ユーザーの退会等によりユーザー資格を喪失した場合、一定の周知期間の経過時点で残存ポイントが消滅するものとします。なお、かかる周知期間中は、新規のポイントの取得はできません。<br>
        ５．ユーザーは、当社が特に定めた場合を除き、保有するポイントを他のユーザーに移転、譲渡、貸与することはできません。<br>
        ６．ユーザーは、ポイントを使用して取得した有償のサービスを、他のユーザーに移転、譲渡、貸与し又は本サービスその他類似のサービスに表記してはなりません。</p>
      <p>&nbsp;</p>
      <p>第１３条（利用料金）<br>
        １．ユーザーは、前２条において規定した有償のサービスを利用する対価、有償のポイントを取得する対価として、別途当社が定め、本サービス上で掲示する利用料金を、当社が指定する支払方法により当社に支払うものとします。ただし、当社が認める場合にはユーザーは利用料金をこれに相当する額のポイントで支払うことができるものとします。<br>
        ２．法令等により払戻しが義務付けられている場合を除き、当社は、理由の如何を問わず、お支払いいただいた利用料金の返金はいたしません。<br>
        ３．未成年者は有償のサービスの購入、ポイントの購入にあたり、事前に法定代理人の同意を得ることが必要となります。</p>
      <p>&nbsp;</p>
      <p>第１４条（報酬）</p>
      <p>
        １．当社は、ユーザーコンテンツの内容及び当該ユーザーコンテンツに対するユーザーの行動その他当社の定める基準による評価に基づき、ユーザーコンテンツを表記したユーザーに対し、報酬を支払うものとします。なお、評価基準及び報酬の支払方法等は、当社の定めによるものとし、当社は適宜これを変更することができるものとします。
      </p>
      <p>２．ユーザーが本規約に違反するなど、報酬の支払を行うことが不適当と認められる場合、当社はユーザーに対し報酬の支払を行いません。また、この場合、すでに支払済みの報酬の返還を請求することができるものとします。</p>
      <p>&nbsp;</p>
      <p>第１５条（権利の帰属）<br>
        １．本サービスおよび提供コンテンツ、ユーザーコンテンツに関する著作権その他一切の権利は、当社または当社にライセンスを付与し、もしくは本サービスにおける提供を許諾した権利者に帰属します。</p>
      <p>２．当社は、本サービス、及び当社の管理するＷＥＢサイト、ＳＮＳサイトにおいて利用する目的で、ユーザーに対し、譲渡及び再許諾することのできない提供コンテンツの非独占的かつ私的な利用を許諾します。</p>
      <p>３．ユーザーは、提供コンテンツを、複製、翻案、公衆送信その他の方法により利用してはなりません。<br>
        ４．ユーザーは、本サービスにおいて自己が利用するため、自己の判断により本サービスにユーザープロフィールを登録することができるものとします。</p>
      <p>５．ユーザーコンテンツ及びユーザープロフィールに他者が権利を有する知的財産権等が含まれる場合には、ユーザーが当該権利者との権利許諾等に関する手続を行うものとします。<br>
        ６．ユーザーが前３項の規定に違反することにより何らかの問題が発生した場合には、ユーザーは、自己の費用と責任においてかかる問題を解決するものとし、当社に何等の迷惑または損害を与えないものとします。</p>
      <p>&nbsp;</p>
      <p>第１６条（禁止事項）<br>
        １．ユーザーは、本サービスの利用にあたって以下の行為を行ってはなりません。<br>
        （1）援助交際・売春・買春等を行い、またはそれらを勧誘する行為<br>
        （2）児童（満18歳未満の男女をいいます。）を異性交際の相手方となるよう勧誘し、または大人に対し児童との異性交際の相手方となるよう勧誘する行為<br>
        （3）当社または他のユーザーその他の第三者の著作権等知的財産権、プライバシー、肖像権、パブリシティ権その他の権利もしくは利益を侵害する行為、または侵害するおそれのある行為<br>
        （4）他のユーザー、第三者または当社を誹謗中傷し、または名誉もしくは信用を傷つける行為<br>
        （5）他の利用者、第三者または当社に不利益を与える行為<br>
        （6）法令、行政措置に違反しもしくはこれらを助長する行為または犯罪に結びつく行為<br>
        （7）公序良俗に反する行為<br>
        （8）反社会的勢力に対する利益供与その他の協力行為<br>
        （9）営業、宣伝、広告、勧誘その他の営利を目的とする行為（当社の認めるものを除きます）、宗教活動もしくは宗教団体への勧誘行為、政治活動への勧誘目的等、本サービスの趣旨に反する目的で本サービスを利用する行為<br>
        （10）他の利用者による本サービスの利用を妨害する行為<br>
        （11）他の利用者または第三者になりすまして、本サービスを利用する行為<br>
        （12）本サービスを通じて取得した個人情報等を第三者に提供する行為<br>
        （13）利用者間において金銭の授受をする行為<br>
        （14）本サービスにより利用しうる情報を改ざん・不正に消去する行為<br>
        （15）本サービスに接続されている他のコンピューター・システムまたはネットワークへの不正アクセスを試みる行為<br>
        （16）コンピューターウィルスなど有害なプログラムを使用し、もしくは提供する行為、またはこれらの行為を推奨する行為<br>
        （17）本サービスまたは本サービスのために使用されているソフトウエアの修正、改変、使用許可の付与、翻訳、販売、ソースコードを解析した上での模倣、解読、逆コンパイル行為<br>
        （18）本サービスの他、当社の運営するサービスを妨害する行為（20）外部サイトへのリンク等、本サービス以外のサイト、サービスに誘導する行為</p>
      <p>(19）過度にわいせつな表現を含む情報、他のユーザーを特定可能な個人情報を含む情報、残虐な表現その他他人に不快感を与える恐れのある情報その他当社が不適切と判断する情報を本サービスに送信・投稿する行為<br>
        （20）外部サイトへのリンク等、本サービス以外のサイト、サービスに誘導する行為</p>
      <p>(21) 前各号に定める行為を助長する行為<br>
        （22）前各号に定める行為もしくはこれと疑われる行為<br>
        （23）その他､当社が不適切と判断した行為</p>
      <p>２．ユーザーは、ユーザーとして有する権利の全部または一部を第三者に譲渡、売買、貸与、担保に供する行為その他一切の処分行為はできないものとします。</p>
      <p>&nbsp;</p>
      <p>第１７条（本サービスの変更・中断・終了）<br>
        １．当社は、ユーザーに事前に通知することなく、本サービスの一部または全部を変更、追加することができます。<br>
        ２．当社は、以下の各号の一に該当する事由が生じた場合には、当社の判断により、本サービスの運用の全部または一部を中断することができるものとします。<br>
        （１）戦争、暴動、騒乱、労働争議、天災、地震、噴火、洪水、津波、火災、停電などの不可抗力により本サービスが通常どおり提供できなくなった場合。<br>
        （２）本サービスの運営において必要なシステム等の一時的または大規模なメンテナンス、電気通信設備の障害、プロバイダー等の障害、システムトラブルなどによる緊急な保守点検を行う場合。<br>
        （３）その他やむを得ない事由が生じた場合など、当社が必要と判断した場合</p>
      <p>３．当社は、前項により本サービスの全部または一部を中断する場合は、当社が適当と判断する方法で事前に告知します。ただし、緊急の場合には、この限りではありません。<br>
        ４．当社は、当社が適当と判断する方法で事前にユーザーにその旨を告知することにより本サービスの提供を終了することができるものとします。</p>
      <p>５．当社は理由の如何を問わず、前各項の規定によりユーザーが何らかの不利益を受けた場合であっても、損害賠償責任を含めた一切の責任を負いません。</p>
      <p>&nbsp;</p>
      <p>第１８条（設備等）<br>
        １．当社の設備もしくは本サービスの運営設備に障害を生じ、またはその設備が滅失したことを当社が知ったときは、当社は速やかにその設備を修理・復旧するよう努めるものとします。かかる場合に、ユーザーまたは第三者に損害が生じた場合であっても、当社は一切責任を負わないものとします。<br>
        ２．ユーザーは、自己の負担と責任により、本サービスの利用に必要な通信機器、ソフトウェアその他の機器および通信回線その他の接続環境等を用意、設置、操作し、本サービスを利用可能な状態にするものとします。</p>
      <p>&nbsp;</p>
      <p>第１９条（個人情報の取扱い）<br>
        １．当社は、法令に定める場合を除き、事前にユーザーである本人の同意を得ることなく、ユーザー情報に含まれている個人情報を第三者へ開示、提供することはありません。ただし、以下の場合は除きます。<br>
        （１）法令に基づく場合など個人情報保護法に定める場合や、裁判所・警察等の公的機関から法律に基づく要請を受けた場合。<br>
        （２）ユーザーの事前の承認、同意を得た場合。<br>
        （３）本サービスを提供する上で必要となる業務委託先に開示する場合。<br>
        （４）当社の合併、分社化、事業譲渡により事業が継承されユーザー情報を引き継ぐ場合。</p>
      <p>２.当社は、ユーザー情報を、以下に掲げる目的により、無償で、複製その他の方法により利用し、第三者に提供することができるものとし、ユーザーはこれを承諾するものとします。<br>
        （１）本サービス実施、提供のため<br>
        a.本サービスの拡充、改善、新規開発のための調査・分析のため<br>
        b.お問い合わせ、サポート対応のため<br>
        c.その他、お客様の同意を得た利用目的のため</p>
      <p>（２）本サービスの利用状況等に係る統計情報の作成、本サービスまたは当社の運営するウェブサイト等における公表のため。</p>
      <p>３．当社は、ユーザー登録情報の保存義務を負うものではありません。ユーザーは、必要なユーザー情報およびユーザープロフィールについて、自らの責任でバックアップをとるものとします。</p>
      <p>
        ４．当社は、ユーザーが本サービスを退会し、または当社が本サービスの運営を終了したときは、当社の判断により、ユーザー登録情報を全て消去できるものとし、その返却等の要望には一切応じません。また、当社はこれによってユーザーに生じる不利益、損害等について、一切の責任を負いません。
      </p>
      <p>
        ５．個人情報を当社にご提供いただくか否かは、ユーザーご自身の任意です。個人情報を登録するか否かは、ユーザーご自身で判断ください。ただし、当社が本サービスを提供するうえでお願いする個人情報をご提供いただけない場合や、ご提供いただいた個人情報に不備があった場合には、個人情報の取得目的どおりのサービスがご提供できない場合があります。
      </p>
      <p>
        ６．本サービスでは、クッキーを使用する場合があります。これは本サービスの改善や利用状況の解析のため、ユーザーによるＷＥＢサイト、アプリ、広告の閲覧状況を確認するもので、統計的なマーケティング情報として利用いたします。クッキー内に個人情報が含まれることはございません。また、個人情報の取得およびユーザー情報との関連付けを行うものではありません。
        なお、本サービスでは、下記の事業者が提供する情報収集モジュールを使用し、当該事業者を通じて情報を自動取得します。</p>
      <p>——————–<br>
        a.サービス名称：Google Analystics<br>
        提供会社名：Google Inc.<br>
        取得する情報の項目：HTTP Cookie、IPアドレスおよび、ブラウザの環境変数、IDFA、UUID、利用OS<br>
        利用目的：本サービスの改善、利用状況の解析<br>
        第三者提供の有無：無<br>
        http://www.google.com/intl/ja/policies/privacy/<br>
        ——————–<br>
        ７．当社は、ユーザーからご提供いただいた個人情報の利用目的の通知、開示、訂正、追加、削除、利用停止または第三者提供停止の請求に対応いたします。個人情報の取り扱いに関するお問い合わせ、苦情などは以下のお問い合わせ先までお願いいたします。<br>
        KAWAII JAPAN株式会社<br>
        〒135-0064　東京都江東区青海 2-7-4 THE SOHO 706</p>
      <p>TEL : 03-5530-7056</p>
      <p>メール：<a href="mailto:info@kawaiijapan.co.jp">info@kawaiijapan.co.jp</a></p>
      <p>第２０条（免責事項）<br>
        １．当社は、本サービスの内容、本サービスにより提供する一切の情報、データ等について、その完全性、正確性、適法性、目的適合性等いかなる保証も行わず、一切の責任を負いません。<br>
        ２．当社は、当社が本サービスにより提供する一切の情報、データ等、またはユーザーが掲載する一切の情報、データ等を確実に保存することを保証するものではなく、かかる情報、データ等が消失したとしても、一切の責任を負いません。<br>
        ３．本サービスの提供、遅滞、変更、中止もしくは終了、本サービスを通じて登録、提供される情報等の流失もしくは消失等その他本サービスに関連して発生したユーザーおよび第三者の損害について、当社は一切の責任を負いません。<br>
        ４．本サービスの利用に関連して、ユーザーが他のユーザーもしくは第三者に対して損害を与えた場合、またはユーザーが他のユーザーもしくは第三者と紛争を生じた場合、当該
        ユーザーは自己の費用と責任で解決するものとし、当社に何等の迷惑または損害を与えないものとします。また、ユーザーが本サービスの利用に関連して当社に損害を生
        じさせた場合は、当該ユーザーは当社に生じた損害を賠償するものとします。<br>
        ５．当社は、本サービスを提供するコンピューターシステムの稼働状況について保証するものではなく、本サービス提供のためのコンピューターシステムの障害等、いかなる原因に基づき生じた損害についても、一切の責任を負いません。<br>
        ６．当社は、本サービスの利用、本サイトからのダウンロード、コンピュータウイルス感染等により、コンピュータ、周辺機器、回線、ソフトウェアなどに生じた損害について、一切の責任を負いません。<br>
        ７．本規約に従い当社がユーザーに対して損害賠償の責任を負う場合であっても、当社に故意又は重過失が存する場合を除き、当社が賠償すべき損害の範囲は、ユーザーに直接かつ現実的に生じた損害に限定されます。</p>
      <p>&nbsp;</p>
      <p>第２１条（管轄裁判所）<br>
        １．本サービスに関連してユーザーと当社との間で問題が生じた場合には、ユーザーと当社で誠意をもって協議するものとします。<br>
        ２．協議しても解決せず訴訟となった場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
      <p>&nbsp;</p>
      <p>第２２条（準拠法）<br>
        本規約に関する準拠法は、日本法とします。</p>
      <p>付則<br>
        本規約は平成29年2月14日から実施します。<br>
        令和1年8月1日 改定</p>
      <p>&nbsp;</p>
    </div>
  </ng-container>
  <ng-container *ngIf="lang==='en'">
    <h2 class="cospo-title">COSPO Terms of Service</h2>
    <div class="entry entry-content">
      <p>&nbsp;</p>
      <h1>COSPO Terms of Service</h1>
      <p>Article 1 (Purpose)<br>
        This agreement is a condition for the user (defined in Article 2) to use the service "COSPO" provided by KAWAII
        JAPAN Co., Ltd. (hereinafter referred to as "Company"). Is to determine</p>
      <p>&nbsp;</p>
      <p>Article 2 (Definition)<br>
        Definitions of terms in this agreement are as follows.<br>
        (1) "Service" This means all services provided by "COSPO" operated by the Company.<br>
      </p>
      <p>(2) "User"<br>
        A person who has approved this agreement and is a person other than the individual who has applied for the use
        of this service according to the procedure prescribed by the Company or the person who has obtained the consent
        of the Company, and who has approved the user registration by the Company. I will.</p>
      <p>(3) "User information"<br>
        This is the information required by the Company to be registered or provided in the initially specified format,
        which is necessary for the user to use this service.</p>
      <p>(4) "User content"</p>
      <p>Refers to all information sent, posted, registered, displayed, etc. within the Service (hereinafter, these acts
        are collectively referred to as "notation").</p>
      <p>(5) "Provided contents"<br>
        means photos, images, videos, sentences, sounds, items and other information (including user contents) provided
        by the Company in order for users to use this service.</p>
      <p>(6) "Points"<br>
        The points that can be exchanged for services provided for a fee or other contents specified by the Company in
        this service.</p>
      <p>&nbsp;</p>
      <p>Article 3 (Agreement of agreement, scope of application)<br>
        1. The user must use this service in accordance with the provisions of this agreement.</p>
      <p>2. The user cannot use this service unless the application "COSPO" provided by the Company is downloaded to a
        smartphone or other information terminal and the procedure for agreeing to this agreement is performed.</p>
      <p>
        3. In providing this service, the information (hereinafter referred to as "related rules") described separately
        in terms of terms, notification of usage conditions implemented by the Company, help regarding this service and
        notification sent to users (hereinafter referred to as "related regulations") If the content of the notice,
        notice, etc. is different from the provisions of this agreement, the provision of the notice, notice, etc. shall
        take precedence.
      </p>
      <p>&nbsp;</p>
      <p>Article 4 (Registration of user information)<br>
        1. Those who wish to become users shall perform user registration procedures in accordance with the procedure
        specified by our company after accepting this agreement when using this service.</p>
      <p>
        2. If you are a minor, you must obtain the consent of a legal representative (including your consent to these
        Terms).
      </p>
      <p>
        3. When the user registration procedure is performed, the Company shall register the user information and grant
        an account according to the authentication procedure prescribed by the Company. However, our company may refuse
        the user registration when we deem it inappropriate, and in that case, we shall not be obliged to disclose the
        reason for refusal to the person who performed the registration procedure.</p>
      <p> 4. By granting the account specified in the preceding paragraph, the use contract of this service shall be
        concluded between the user and our company. In addition, if we determine that it is inappropriate, we may cancel
        the account even after granting the account and cancel the contract to use this service. However, we do not
        undertake any obligation to provide a reason for withdrawing consent.</p>
      <p>&nbsp;</p>
      <p>Article 5 (Notification of change)<br>
        1. When the user information is changed, the user shall promptly change the registered information.<br>
        2. The Company shall not be liable for any inconvenience caused even if the user information is false or
        incorrect, or if the user incurs any disadvantages due to the failure to notify in the preceding paragraph.</p>
      <p>&nbsp;</p>
      <p>Article 6 (Deleting an account, etc.)<br>
        1. If the user falls under any of the following items, or if the Company determines that it is inappropriate,
        the Company includes suspension or restriction of the use of this service for the user and deletion of the
        account without prior notice. It shall be possible to take measures such as cancellation of the usage contract.
        The Company shall not be obliged to disclose the reason for such measures.<br>
        (1) When any of the terms of this agreement is violated</p>
      <p>(2) If the information provided to the Company is false<br>
        (3) If the user is a minor, an adult ward, a person under conservatorship or an assistant, a legal
        representative, adult guardian, When the consent of the guardian or assistant is not obtained <br>
        (4) When the user dies or guardianship, guardianship or assistance is started</p>
      <p>(5) In addition, when we judge that it is inappropriate as a user or it is difficult to continue registration
      </p>
      <p>(6) When the Company judges that there are other reasons similar to the above items</p>
      <p>2. When the user registration is deleted or the usage contract is canceled according to the preceding
        paragraph, if the user again applies for user registration, we may not accept the application.<br>
        3. The Company shall not be liable for any disadvantage or damage caused to the User by the measures taken by
        the Company based on this Article.</p>
      <p>Article 7 (Handling of User Content)</p>
      <p>1. The user who expresses user content assumes all responsibility about the user content concerned.</p>
      <p>2. The user cannot provide the following information</p>
      <p>(1) Those that infringe on intellectual property rights such as copyrights, privacy, portrait rights, publicity
        rights or other rights or interests of the Company or other users or other third parties</p>
      <p>(2) Those that slander other users, third parties or our company, or damage their honor or credit.</p>
      <p>(3) Other users, third parties or those that give us a disadvantage</p>
      <p>(4) Anything that violates or promotes laws or administrative measures, or that leads to a crime or</p>
      <p>(5) Those that violate public order and morals</p>
      <p>(6) Providing profits to antisocial forces and other cooperative activities</p>
      <p>(7) Offering dating, prostitution, prostitution, etc., or soliciting them</p>
      <p>(8) Invite children (men and women under the age of 18) to be partners in heterosexual relationships, or
        solicit adults to be partners in heterosexual relationships with children.</p>
      <p>(9) It violates the purpose of this service such as sales, advertising, advertisement, solicitation or other
        profit-making purpose (excluding those recognized by the Company), solicitation to religious activities or
        religious groups, solicitation to political activities, etc. thing</p>
      <p>(10) Those that use or include harmful programs such as computer viruses</p>
      <p>(11) Those that contain excessively obscene expressions, cruel expressions, or other things that may offend
        others</p>
      <p>(12) Others that the Company judges to be inappropriate</p>
      <p>
        3. The Company shall be able to delete all or part of the User Content without prior notice when the User
        Content violates this Agreement or when the Company judges that it is inappropriate. The Company shall not be
        obliged to disclose the reason for such measures.
      </p>
      <p>4. Our company can use the user contents by copying, editing, displaying, publicly transmitting, publicizing,
        etc. for the purpose of providing and promoting the use of this service free of charge, and the user consents to
        us in advance. I shall.</p>
      <p>5. The user cannot license the user content to a third party other than our company.</p>
      <p>Article 8 (User account management)<br>
        1. The user shall manage the account of this service at his own risk, and may not let third parties use it, lend
        it, transfer it, change the name, etc.<br>
        2. The user can manage the account of this service with any email address and password. The user must manage the
        email address and password registered by him/herself strictly at their own risk so that they will not be used
        illegally.<br>
        3. The user is responsible for damages due to insufficient management of email address and password, error in
        use, use by a third party, etc., and we do not take any responsibility.<br>
        4. Use of this service through an account is considered to be the use and action of the user, including the use
        and action of third parties other than the user himself. The user shall bear all debts incurred by the use and
        conduct.<br>
        5. If the user has obtained an unauthorized email address and password, or if the account is being used by or is
        likely to be used by a third party, the user shall be notified immediately, and if instructed by the Company,
        this Subject to.<br>
      </p>
      <p>6. We do not take any responsibility for damages caused by insufficient management of accounts or the use of
        third parties.</p>
      <p>7. If the user deletes the account by mistake, such as when the user accidentally deletes the account, the user
        consents in advance that the user will not be able to use the account, contents and other information related to
        the Service.
      </p>
      <p>&nbsp;</p>
      <p>Article 9 (withdrawal)<br>
        1. The user can cancel the use of this service by withdrawing through the withdrawal procedure specified by the
        Company.<br>
        2. Paragraphs 3 to 6 of the previous article shall apply even if you withdraw from membership under this
        article.<br>
        3. Even if the user withdraws, the fee for the paid service will not be refunded.
      </p>
      <p>&nbsp;</p>
      <p>Article 10 (Revision/change of this agreement)<br>
        1. Our company may change or revise this agreement and related regulations at any time as needed. It should be
        noted that any changes to this agreement and related regulations shall take effect at the time of posting in
        this service or on the website operated by the Company, and if the user uses this service after the change, the
        changed agreement will be applied. We consider that you have agreed.<br>
        2. In the future, we may transfer our position regarding this service to a company that inherits the business of
        this service in the future. In this case, the provisions of the preceding paragraph shall apply mutatis mutandis
        to the procedure for the transfer of status.
      </p>
      <p>&nbsp;</p>
      <p>Article 11 (Sales of paid services)<br>
        1. We may provide a part of this service to users for a fee.<br>
        2. The user can obtain the usage right of the paid service (hereinafter referred to as "paid service") specified
        in the preceding paragraph by paying the consideration separately posted by the Company and posted on the
        service.<br>
        3. The user shall not transfer, transfer, lend or pay the acquired paid service to other users or describe it on
        this service or other similar services.<br>
        4. We may change the price of paid services at our discretion.
      </p>
      <p>&nbsp;</p>
      <p>Article 12 (Points)<br>
        1. Points shall be given to users by "purchase" or other method specified by us on this service. The point
        purchase unit, payment method, and other granting conditions shall be determined separately by the Company and
        posted on the Service.<br>
        2. Points can only be exchanged or used for paid services or other content designated by the Company, and cannot
        be exchanged or used for other content or services, cash or other economic benefits. The number of points
        required to exchange or use and other conditions for using the points shall be determined separately by the
        Company and posted on the Service.<br>
        3. We will not reissue or redeem points for any reason. In addition, no points will be refunded.<br>
        4. If the Company terminates or cancels this service, or if the user qualification is lost due to account
        deletion, withdrawal from the user, etc. according to the provisions of Article 6, the remaining points shall
        disappear after the elapse of a certain known period. I will. Please note that new points cannot be obtained
        during this period.<br>
        5. The user cannot transfer, transfer, or lend the owned points to other users unless otherwise specified by the
        Company.<br>
        6. The user may not transfer, transfer, lend or pay the paid service obtained by using the points to other users
        or this service or other similar service.
      </p>
      <p>&nbsp;</p>
      <p>Article 13 (use fee)<br>
        1. The user shall determine the usage fee posted on this service separately by the Company as the consideration
        for using the paid service specified in the preceding two articles and the consideration for acquiring the paid
        points by the payment method specified by the Company. I shall pay. However, if our company approves, the user
        shall be able to pay the usage fee with the equivalent amount of points.<br>
        2. We will not refund the usage fee you paid for any reason unless refund is required by law.<br>
        3. Minors must obtain the consent of a legal representative before purchasing paid services or purchasing
        points.
      </p>
      <p>&nbsp;</p>
      <p>Article 14 (Rewards)</p>
      <p>
        1. The Company shall pay the user who has described the User Content based on the content of the User Content,
        the behavior of the User Content, and the evaluation based on other standards set by the Company. The evaluation
        criteria and payment method of remuneration shall be determined by the Company, and the Company may change them
        as appropriate.<br>

        2. If it is deemed inappropriate to pay the reward, such as if the user violates this agreement, we will not pay
        the reward to the user. In addition, in this case, it is possible to request the refund of the already paid
        reward.
      </p>
      <p>&nbsp;</p>
      <p>Article 15 (Attribution of Rights)<br>
        1. Copyrights and all other rights related to this service, provided contents, and user contents belong to the
        company or the right holder who licensed the company or licensed to provide the service.<br>

        2. The Company grants the user non-exclusive and private use of the provided contents that cannot be transferred
        or sublicensed for the purpose of using this service and the WEB site and SNS site managed by the Company.<br>

        3. The user may not use the provided content by copying, adapting, public transmission or any other method.
        4. Since the user uses this service by himself, he/she can register the user profile in this service at his/her
        own discretion.<br>

        5. If the user content and user profile include intellectual property rights that others have rights to, the
        user shall perform procedures related to the license agreement with the right holder.<br>

        6. If any problem occurs due to the user violating the provisions of the preceding three paragraphs, the user
        shall solve the problem at his own expense and responsibility and shall not cause any inconvenience or damage to
        the Company. I will.
      </p>
      <p>&nbsp;</p>
      <p>Article 16 (Prohibitions)<br>
        1. The user must not perform the following actions when using this service.<br>
        (1) Assistance dating, prostitution, prostitution, etc., or soliciting them<br>
        (2) Solicitation of children (men and women under the age of 18) to be partners of heterosexual relations, or to
        adults The act of soliciting to be a partner in a heterosexual relationship with a child<br>
        (3) Violation of intellectual property rights such as copyrights, privacy, portrait rights, publicity rights and
        other rights or interests of the Company or other users and other third parties Acts, or acts that may
        infringe<br>
        (4) Acts that slander other users, third parties or our company, or damage our reputation or credit<br>
        (5) Disadvantage to other users, third parties or our company Actions to give<br>
        (6) Actions that violate or promote laws or administrative measures or that lead to crimes<br>
        (7) Actions that violate public order and morals<br>
        (8) Providing profits to antisocial forces or other cooperative actions<br>
        (9) Sales and advertising , Advertisement, solicitation or other commercial activities (excluding those
        recognized by the Company), solicitation to religious activities or religious groups, solicitation to political
        activities, etc.<br>
        (10) Acts that interfere with the use of this service by other users<br>
        (11) Acts of using this service by impersonating other users or a third party<br>
        (12) Individuals acquired through this service The act of providing information to a third party<br>
        (13) The act of giving and receiving money between users<br>
        (14) The act of falsifying or illegally erasing the information that can be used by this service<br>
        (15) An attempt to illegally access other computer systems or networks connected to this service<br>
        (16) An act of using or providing a harmful program such as a computer virus, or an act of recommending these
        acts<br>
        (17) Modification, alteration, granting of permission to use, translation, sale, imitation, decoding,
        decompilation after analyzing the source code of this service or the software used for this service<br>
        (18) books In addition to services, acts that interfere with services operated by the Company (20) Acts that
        lead to sites or services other than this service, such as links to external sites<br>

        (19) Information that contains excessively obscene expressions, information that contains personal information
        that can identify other users, cruel expressions or other information that may offend others, or any other
        information that the Company deems inappropriate Acts of sending/posting to services<br>
        (20) Acts of guiding to sites or services other than this service, such as links to external sites<br>

        (21) Actions that promote the actions
        specified in the preceding
        items <br>
        (22) Actions specified in the preceding items or actions suspected to be this (23) Other actions that the
        Company deems inappropriate<br>

        2. The user shall not be able to transfer, sell, lend, provide collateral, or otherwise dispose of all or part
        of the rights that the user has as a user.
      </p>
      <p>&nbsp;</p>
      <p>Article 17 (Change, suspension, termination of this service)<br>
        1. We can change or add a part or all of this service without notifying the user in advance.<br>
        2. The Company shall be able to suspend the whole or part of the operation of this Service at the discretion of
        the Company in the event that one of the following items occurs.<br>
        (1) If this service cannot be provided as usual due to force majeure such as war, riot, mayhem, labor dispute,
        natural disaster, earthquake, eruption, flood, tsunami, fire, or power outage.<br>
        (2) When performing emergency maintenance inspections due to temporary or large-scale maintenance of systems
        required for the operation of this service, failures in telecommunications equipment, failures in providers,
        system troubles, etc.<br>
        (3) When the Company deems it necessary, such as when unavoidable reasons arise.<br>

        3. If we suspend all or part of this service in accordance with the preceding paragraph, we will notify you in
        advance by the method that we determine to be appropriate. However, this does not apply in the case of an
        emergency.<br>
        4. The Company shall be able to terminate the provision of this service by notifying the user in advance by a
        method that the Company deems appropriate.<br>

        5. For any reason, our company will not be liable for damages even if the user suffers any disadvantages due to
        the provisions of the preceding paragraphs.<br>
      </p>
      <p>&nbsp;</p>
      <p>Article 18 (equipment, etc.)<br>
        1. When the Company discovers that the facilities of the Company or the operating facilities of the Service have
        failed or have been lost, the Company shall endeavor to promptly repair or restore the facilities. In such a
        case, we shall not be liable for any damages to users or third parties.<br>
        2. The user prepares, installs and operates communication equipment, software and other equipment, communication
        lines and other connection environments necessary for using this service, and makes this service available at
        its own expense and responsibility. will do.<br>
      </p>
      <p>&nbsp;</p>
      <p>Article 19 (Handling of personal information)<br>
        1. Except as required by law, we will not disclose or provide personal information contained in user information
        to a third party without the prior consent of the person who is the user. However, the following cases are
        excluded.<br>
        (1) In cases where it is required by law, such as when it is based on laws and regulations, or when a legal
        request is received from a public institution such as a court or police.<br>
        (2) When the user's prior approval and consent are obtained.<br>
        (3) When disclosing to a subcontractor necessary for providing this service.<br>
        (4) When the business is succeeded by the merger, spin-off or transfer of the business and the user information
        is succeeded.</p>
      <p>2. The Company shall be able to use the user information free of charge for the following purposes by copying
        or other methods and provide it to a third party, and the user consents to this.<br>
        (1) To carry out and provide
        this service a. To expand and improve this service, to research/analyze for new development<br>
        b. To respond to inquiries and support<br>
        c. For other purposes, with the consent of the customer for</p>
      <p>(2) To create statistical information related to the usage status of this service and to publish it on this
        service or the website operated by our company.</p>
      <p>3. We are not obliged to save user registration information. You are responsible for backing up the necessary
        user information and user profile.</p>
      <p>
        4. When the user withdraws from the service or the operation of the service is terminated, the Company shall be
        able to delete all user registration information at its discretion, and will not respond to any request for its
        return etc. .. In addition, we do not take any responsibility for the disadvantages, damages, etc. caused to
        users.
      </p>
      <p>
        5. It is up to the user to provide us with personal information. It is the user's responsibility to decide
        whether to register personal information. However, if we are unable to provide the personal information that we
        request in order to provide this service, or if the personal information provided is incomplete, we will not be
        able to provide the service for the purpose of obtaining the personal information. There are cases.
      </p>
      <p>
        6. This service may use cookies. This is to check the browsing status of WEB sites, apps and advertisements by
        users for the purpose of improving this service and analyzing the usage status, and it is used as statistical
        marketing information. No personal information is included in the cookie. In addition, we do not obtain personal
        information or associate it with user information. In addition, this service uses the information collection
        module provided by the following businesses to automatically obtain information through the businesses.
      </p>
      <p>——————–<br>
        a. Service name: Google Analystics<br>
        Provider name: Google Inc.<br>
        Items of information to be acquired: HTTP cookie, IP address, browser environment variables, IDFA, UUID, OS<br>
        usage purpose: This service Improvement of, and analysis of usage status<br>
        Provided by a third party: None<br>
        http://www.google.com/intl/ja/policies/privacy/<br>
        ——————–<br>
        7. We will respond to requests for notification, disclosure, correction, addition, deletion, suspension of use
        or suspension of third party provision of personal information provided by users. For inquiries and complaints
        regarding the handling of personal information, please contact the following inquiries.<br>
        KAWAII JAPAN Co., Ltd.THE SOHO 706 2-7-4 Aomi, Koto-ku, Tokyo 135-0064</p>
      <p>TEL : 03-5530-7056</p>
      <p>Email:<a href="mailto:info@kawaiijapan.co.jp">info@kawaiijapan.co.jp</a></p>
      <p>Article 20 (Disclaimer)<br>
        1. Our company does not guarantee the content of this service, all information and data provided by this
        service, such as completeness, accuracy, legality and suitability for any purpose, and we assume no
        responsibility.<br>
        2. We do not guarantee that all information, data, etc. provided by the Company by this service, or all
        information, data, etc. posted by users will be saved securely, and that such information, data, etc. will be
        lost. However, we do not take any responsibility.<br>
        3. The Company shall not be responsible for any damages to users and third parties caused by the provision,
        delay, change, cancellation or termination of this service, loss or loss of information etc. provided or
        provided through this service Not responsible.<br>
        4. If the user causes damage to another user or a third party in connection with the use of this service, or if
        the user has a dispute with the other user or a third party, the user will bear his own expense. We will take
        responsibility for the solution and will not cause any inconvenience or damage to our company. In addition, if a
        user causes damage to us in connection with the use of this service, the user shall compensate the damage caused
        to us.<br>
        5. We do not guarantee the operating status of the computer system that provides this service, and we will not
        be liable for any damage caused by any cause, such as a failure of the computer system for providing this
        service.<br>
        6. We do not take any responsibility for damage caused to your computer, peripherals, lines, software, etc. due
        to use of this service, download from this site, computer virus infection, etc.<br>
        7. Even if the Company is liable for damages to the User in accordance with these Terms, the range of damages to
        be paid by the Company will occur directly and realistically to the User unless the Company intentionally or
        grossly negligently exists. Limited to damages.</p>
      <p>&nbsp;</p>
      <p>Article 21 (Jurisdiction Court)<br>
        1. If a problem occurs between you and us in connection with this service, we will discuss with you in good
        faith.<br>
        2. If the case is not resolved even after consultation, the Tokyo District Court will be the exclusive agreement
        jurisdiction court of the first instance.</p>
      <p>&nbsp;</p>
      <p>Article 22 (Governing Law)<br>
        The governing law for this agreement shall be Japanese law.</p>
      <p>Supplementary<br>
        rules This agreement will be implemented from February 14, 2017 .<br>
        Revised August 1, 1st Reiwa </p>
      <p>&nbsp;</p>
    </div>
  </ng-container>
  <ng-container *ngIf="lang==='fr'">
    <h2 class="cospo-title">Conditions d'utilisation de COSPO</h2>
    <div class="entry entry-content">
      <p>&nbsp;</p>
      <h1>Conditions d'utilisation de COSPO</h1>
      <p>Article 1 (Objet)<br>
        Cet accord est une condition pour l'utilisateur (défini à l'article 2) d'utiliser le service "COSPO" fourni par
        KAWAII JAPAN Co., Ltd. (ci-après dénommé "Société"). Est de déterminer.</p>
      <p>&nbsp;</p>
      <p>Article 2 (Définition) Les définitions des termes du présent accord sont les suivantes.<br>
        (1) "Service" Cela signifie tous les services fournis par "COSPO" exploités par la Société.。</p>
      <p>(2) "Utilisateur"<br>
        Une personne qui a approuvé le présent accord et est une personne autre que la personne qui a demandé
        l'utilisation de ce service selon la procédure prescrite par la Société ou la personne qui a obtenu le
        consentement de la Société, et qui a approuvé l'enregistrement de l'utilisateur par la Société. Je vais.</p>
      <p>(3) «Informations utilisateur»<br>
        Il s'agit des informations requises par la Société pour être enregistrées ou fournies dans le format
        initialement spécifié, qui est nécessaire pour que l'utilisateur utilise ce service.</p>
      <p>(4) "Contenu utilisateur"</p>
      <p>Cela fait référence à toutes les informations envoyées, publiées, enregistrées, affichées, etc. (ci-après
        collectivement dénommées "notation") par l'utilisateur au sein de ce service.</p>
      <p>(5) "Contenu fourni"<br>
        désigne les informations (y compris le contenu utilisateur) fournies par la Société aux utilisateurs pour
        utiliser ce service, telles que photos, images, vidéos, phrases, sons, éléments et autres informations.</p>
      <p>(6) "Points"<br>
        Les points qui peuvent être échangés contre des services fournis contre rémunération ou tout autre contenu
        spécifié par la Société dans ce service.</p>
      <p>&nbsp;</p>
      <p>Article 3 (Accord d'accord, champ d'application)<br>
        1. L'utilisateur doit utiliser ce service conformément aux dispositions du présent accord.<br>

        2. L'utilisateur ne peut utiliser ce service que si l'application "COSPO" fournie par la Société est téléchargée
        sur un smartphone ou autre terminal d'information et que la procédure d'acceptation de cet accord est
        effectuée.<br>

        3. En fournissant ce service, les informations (ci-après dénommées "règles connexes") décrites séparément en
        termes de termes, avis de conditions d'utilisation mis en œuvre par notre société, aide concernant ce service et
        notification envoyée aux utilisateurs, etc. Si le contenu de ces notifications, notifications, etc. est
        différent des dispositions du présent accord, les dispositions de ces notifications, notifications, etc.
        prévalent.</p>
      <p>&nbsp;</p>
      <p>Article 4 (Enregistrement des informations utilisateur)<br>
        1. Ceux qui souhaitent devenir utilisateurs doivent effectuer les procédures d'enregistrement des utilisateurs
        conformément à la procédure spécifiée par notre société après avoir accepté cet accord lors de l'utilisation de
        ce service.<br>

        2. Si vous êtes mineur, vous devez obtenir le consentement d'un représentant légal (y compris votre consentement
        aux présentes conditions).<br>

        3. Lorsque la procédure d'enregistrement de l'utilisateur est effectuée, la Société enregistrera les
        informations de l'utilisateur et accordera un compte selon la procédure d'authentification prescrite par la
        Société. Cependant, la Société peut refuser l'enregistrement de l'utilisateur lorsqu'elle le juge inapproprié
        et, dans ce cas, elle n'est pas tenue de divulguer le motif du refus à la personne qui a effectué la procédure
        d'enregistrement.<br>
        4. En accordant le compte spécifié au paragraphe précédent, le contrat d'utilisation de ce service sera conclu
        entre l'utilisateur et notre société. En outre, si nous estimons qu'il est inapproprié, nous pouvons annuler le
        compte même après avoir accordé le compte et résilier le contrat d'utilisation de ce service. Cependant, nous
        n'assumons aucune obligation de fournir un motif de retrait du consentement.
      </p>
      <p>&nbsp;</p>
      <p>Article 5 (Notification de changement)<br>
        1. Lorsque les informations utilisateur sont modifiées, l'utilisateur doit rapidement modifier les informations
        enregistrées.<br>
        2. La Société ne sera pas responsable des inconvénients causés même si les informations utilisateur sont fausses
        ou incorrectes, ou si l'utilisateur encourt des inconvénients en raison de la non-notification dans le
        paragraphe précédent.
      </p>
      <p>&nbsp;</p>
      <p>Article 6 (Suppression d'un compte, etc.)<br>
        1. Si l'utilisateur relève de l'un des éléments suivants, ou si la Société détermine qu'il est inapproprié, la
        Société inclut la suspension ou la restriction de l'utilisation de ce service pour l'utilisateur et la
        suppression du compte sans préavis. Il doit être possible de prendre des mesures telles que la résiliation du
        contrat d'utilisation. La Société n'est pas tenue de divulguer le motif de ces mesures.<br>
        (1) En cas de violation de l'une des conditions de cet accord<br>

        (2) Si les informations fournies à la Société sont fausses<br>
        (3) Si l'utilisateur est un mineur, un adulte, une personne sous tutelle ou un assistant, un représentant légal,
        un tuteur adulte, Lorsque le consentement du tuteur ou de l'assistant n'a pas été obtenu
        (4) Lorsque l'utilisateur est décédé ou que la tutelle, la tutelle ou l'assistance a commencé<br>

        (5) De plus, lorsque nous jugeons qu'il est inapproprié en tant qu'utilisateur ou qu'il est difficile de
        continuer l'enregistrement<br>

        (6) Lorsque la Société juge qu'il existe d'autres raisons similaires aux éléments ci-dessus<br><br>

        2. Lorsque l'enregistrement de l'utilisateur est supprimé ou que le contrat d'utilisation est annulé
        conformément au paragraphe précédent, si l'utilisateur demande à nouveau l'enregistrement de l'utilisateur, nous
        ne pouvons pas accepter la demande.<br>
        3. La Société ne sera pas responsable de tout désavantage ou dommage causé à l'Utilisateur par les mesures
        prises par la Société sur la base du présent article.
      </p>


      <p>Article 7 (Traitement du contenu utilisateur)<br>
        1. L'utilisateur qui exprime du contenu utilisateur assume l'entière responsabilité du contenu utilisateur
        concerné.<br><br>

        2. L'utilisateur ne peut pas fournir les informations suivantes:<br>

        (1) Ceux qui portent atteinte aux droits de propriété intellectuelle tels que les droits d'auteur, la vie
        privée, les droits de portrait, les droits de publicité ou d'autres droits ou intérêts de la Société ou d'autres
        utilisateurs ou d'autres tiers<br>

        (2) Ceux qui calomnient d'autres utilisateurs, des tiers ou notre entreprise, ou qui endommagent leur honneur ou
        leur crédit.<br>

        (3) Autres utilisateurs, tiers ou ceux qui nous défavorisent<br>

        (4) Tout ce qui viole ou promeut des lois ou des mesures administratives, ou qui conduit à un crime ou<br>

        (5) Ceux qui violent l'ordre public et la morale<br>

        (6) Fournir des bénéfices aux forces antisociales et autres activités de coopération<br>

        (7) Offrir des rencontres, de la prostitution, de la prostitution, etc., ou les solliciter<br>

        (8) Invitez des enfants (hommes et femmes de moins de 18 ans) à être partenaires dans des relations
        hétérosexuelles, ou sollicitez des adultes à être partenaires dans des relations hétérosexuelles avec des
        enfants.<br>

        (9) Il viole le but de ce service tel que les ventes, la publicité, la publicité, la sollicitation ou tout autre
        but lucratif (à l'exclusion de ceux reconnus par la Société), la sollicitation à des activités religieuses ou à
        des groupes religieux, la sollicitation à des activités politiques, etc. chose<br>

        (10) Ceux qui utilisent ou incluent des programmes nuisibles tels que les virus informatiques<br>

        (11) Ceux qui contiennent des expressions excessivement obscènes, des expressions cruelles ou d'autres choses
        qui peuvent offenser les autres<br>

        (12) Autres que la Société juge inappropriés<br><br>

        3. La Société pourra supprimer tout ou partie du Contenu Utilisateur sans préavis lorsque le Contenu Utilisateur
        viole le présent Contrat ou lorsque la Société juge qu'il est inapproprié. La Société n'est pas tenue de
        divulguer le motif de ces mesures.<br>

        4. Notre entreprise peut utiliser le contenu de l'utilisateur en copiant, éditant, affichant, transmettant
        publiquement, faisant de la publicité, etc. dans le but de fournir et de promouvoir l'utilisation de ce service
        gratuitement, et l'utilisateur nous consent à l'avance. Je vais.<br>

        5. L'utilisateur ne peut pas concéder sous licence le contenu de l'utilisateur à un tiers autre que notre
        société.<br>

      </p>



      <p>Article 8 (Gestion des comptes d'utilisateurs)<br>
        1. L'utilisateur gère le compte de ce service à ses propres risques et ne peut laisser un tiers l'utiliser,
        prêter, transférer, changer le nom, etc.<br>
        2. L'utilisateur peut gérer le compte de ce service avec n'importe quelle adresse e-mail et mot de passe.
        L'utilisateur doit gérer strictement l'adresse e-mail et le mot de passe qu'il a enregistrés à ses risques et
        périls afin qu'ils ne soient pas utilisés illégalement.<br>
        3. L'utilisateur sera responsable des dommages dus à une gestion insuffisante de l'adresse e-mail et du mot de
        passe, à une erreur d'utilisation, à une utilisation par un tiers, etc., et nous ne serons aucunement
        responsables.<br>
        4. L'utilisation de ce service via un compte est considérée comme l'utilisation et l'action de l'utilisateur, y
        compris l'utilisation et l'action de tiers autres que l'utilisateur lui-même. L'utilisateur supporte toutes les
        dettes encourues par l'utilisation et la conduite.<br>
        5. Dans le cas où l'adresse e-mail et le mot de passe sont obtenus de manière incorrecte, ou si le compte est
        utilisé par ou est susceptible d'être utilisé par un tiers, l'utilisateur doit nous contacter immédiatement et,
        si nous le lui demandons, Sujet à.<br>

        6. Nous ne prenons aucune responsabilité pour les dommages causés par une gestion insuffisante des comptes ou
        l'utilisation de tiers.<br>

        7. Si l'utilisateur supprime le compte par erreur, comme la suppression du compte par erreur, l'utilisateur
        consent à l'avance que l'utilisateur ne pourra pas utiliser le compte, le contenu et d'autres informations liées
        au Service.
      </p>
      <p>&nbsp;</p>
      <p>Article 9 (retrait)<br>
        1. L'utilisateur peut annuler l'utilisation de ce service en se retirant par la procédure de retrait spécifiée
        par la Société.<br>
        2. Les paragraphes 3 à 6 de l'article précédent s'appliquent même si vous vous retirez de l'adhésion en vertu du
        présent article.<br>
        3. Même si l'utilisateur se retire, les frais du service payant ne seront pas remboursés.
      </p>
      <p>&nbsp;</p>
      <p>Article 10 (Révision / modification du présent accord)<br>
        1. Notre entreprise peut modifier ou réviser cet accord et les réglementations connexes à tout moment si
        nécessaire. En outre, toute modification de cet accord et des réglementations connexes prendra effet au moment
        de la publication sur ce site Web ou sur le site Web exploité par notre société, et si l'utilisateur utilise ce
        service après le changement, les conditions après le changement seront Nous considérons que vous avez
        accepté.<br>
        2. À l'avenir, nous pourrons transférer notre position concernant ce service à une société qui héritera de
        l'activité de ce service à l'avenir. Dans ce cas, les dispositions du paragraphe précédent s'appliquent mutatis
        mutandis à la procédure de transfert de statut.
      </p>
      <p>&nbsp;</p>
      <p>Article 11 (Ventes de services payants)<br>
        1. Nous pouvons fournir une partie de ce service aux utilisateurs moyennant des frais.<br>
        2. L'utilisateur peut obtenir le droit d'utilisation du service payant (ci-après dénommé «service payant»)
        spécifié au paragraphe précédent en payant la contrepartie affichée séparément par la Société et publiée sur le
        service.<br>
        3. L'utilisateur ne doit pas transférer, transférer, prêter ou acquérir le service payant à d'autres
        utilisateurs ou le décrire dans ce service ou d'autres services similaires.<br>
        4. Nous pouvons modifier le prix des services payants à notre discrétion.
      </p>
      <p>&nbsp;</p>
      <p>Article 12 (Points)<br>
        1. Les points seront attribués à l'utilisateur par "achat" ou par toute autre méthode spécifiée par nous sur ce
        service. L'unité d'achat de points, le mode de paiement et les autres conditions d'octroi seront déterminés
        séparément par la Société et affichés sur ce service.<br>
        2. Les points ne peuvent être échangés ou utilisés que pour des services payants ou tout autre contenu désigné
        par la Société, et ne peuvent être échangés ou utilisés pour d'autres contenus ou services, de l'argent ou
        d'autres avantages économiques. Le nombre de points requis pour échanger ou utiliser, et les conditions
        d'utilisation d'autres points seront déterminés séparément par la Société et affichés sur le Service.<br>
        3. Nous n'émettrons ni n'échangerons de points pour aucune raison. De plus, aucun point ne sera remboursé.<br>
        4. Si la Société met fin ou annule ce service, ou si la qualification de l'utilisateur est perdue en raison de
        la suppression du compte, du retrait de l'utilisateur, etc. conformément aux dispositions de l'article 6, les
        points restants disparaîtront après l'écoulement d'une certaine période connue. Je vais. Veuillez noter que de
        nouveaux points ne peuvent être obtenus pendant cette période.<br>
        5. L'utilisateur ne peut pas transférer, transférer ou prêter les points détenus à d'autres utilisateurs, sauf
        indication contraire de la Société.<br>
        6. L'utilisateur ne peut pas transférer, transférer, prêter ou payer le service payant obtenu en utilisant les
        points à d'autres utilisateurs ou le service ou tout autre service similaire.
      </p>
      <p>&nbsp;</p>
      <p>Article 13 (frais d'utilisation)<br>
        1. L'utilisateur paiera les frais d'utilisation affichés sur ce service à la société par le mode de paiement
        spécifié par la société séparément comme contrepartie pour l'utilisation du service payant spécifié dans les
        deux articles précédents et comme contrepartie pour l'obtention des points payés. Je paierai. Cependant, si
        notre entreprise approuve, l'utilisateur sera en mesure de payer les frais d'utilisation avec le montant
        équivalent de points.<br>
        2. Nous ne rembourserons pas les frais d'utilisation que vous avez payés pour quelque raison que ce soit, sauf
        si le remboursement est requis par la loi.<br>
        3. Les mineurs sont tenus d'obtenir le consentement du représentant légal avant d'acheter des services payants
        ou des points d'achat.
      </p>
      <p>&nbsp;</p>
      <p>
        Article 14 (Récompenses)<br>
        1. La Société paiera l'utilisateur qui a décrit le Contenu Utilisateur, sur la base du contenu du Contenu
        Utilisateur, du comportement du Contenu Utilisateur, et d'autres évaluations basées sur les normes fixées par la
        Société. Les critères d'évaluation et le mode de paiement de la rémunération seront déterminés par la Société et
        celle-ci pourra les modifier le cas échéant.<br>

        2. S'il est jugé inapproprié de payer la récompense, par exemple si l'utilisateur viole cet accord, nous ne
        paierons pas la récompense à l'utilisateur. De plus, dans ce cas, il est possible de demander le remboursement
        de la récompense déjà versée.<br>

      </p>

      <p>&nbsp;</p>

      <p>Article 15 (Attribution des droits)<br>
        1. Les droits d'auteur et tous les autres droits liés à ce service, au contenu fourni et au contenu utilisateur
        appartiennent à la société ou au titulaire du droit qui a accordé une licence à la société ou autorisé à fournir
        le service.<br>

        2. La Société accorde à l'utilisateur une utilisation non exclusive et privée des contenus fournis qui ne
        peuvent être transférés ou sous-licenciés aux fins de l'utilisation de ce service et du site WEB et du site SNS
        gérés par la Société.<br>

        3. Les utilisateurs ne peuvent pas utiliser le contenu fourni par copie, adaptation, transmission publique ou
        toute autre méthode.<br>

        4. Étant donné que l'utilisateur utilise ce service lui-même, il / elle peut enregistrer le profil utilisateur
        dans ce service à sa seule discrétion.<br>

        5. Si le contenu et le profil de l'utilisateur incluent des droits de propriété intellectuelle sur lesquels
        d'autres ont des droits, l'utilisateur doit exécuter les procédures relatives à l'autorisation des droits avec
        le titulaire des droits.<br>

        6. Si un problème survient du fait que l'utilisateur a violé les dispositions des trois paragraphes précédents,
        l'utilisateur doit résoudre le problème à ses propres frais et responsabilités et ne doit pas causer de
        désagréments ou de dommages à la Société. Je vais.
      </p>
      <p>&nbsp;</p>
      <p>Article 16 (Interdictions)<br>
        1. L'utilisateur ne doit pas effectuer les actions suivantes lors de l'utilisation de ce service.<br>
        (1) Assistance aux rencontres, à la prostitution, à la prostitution, etc., ou à leur sollicitation<br>
        (2) Sollicitation d' enfants (hommes et femmes de moins de 18 ans) pour être partenaires de relations<br>
        hétérosexuelles, ou auprès d'adultes L'acte de solliciter pour être un partenaire dans une relation
        hétérosexuelle avec un enfant<br>
        (3) Violation des droits de propriété intellectuelle tels que les droits d'auteur, la vie privée, les droits de
        portrait, les droits de publicité et autres droits ou intérêts de la Société ou d'autres utilisateurs et
        d'autres tiers Actes ou actes pouvant porter atteinte à<br>
        (4) Actes calomniant d'autres utilisateurs, des tiers ou notre entreprise, ou portant atteinte à notre
        réputation ou à notre crédit<br>
        (5) Inconvénient pour d'autres utilisateurs, des tiers ou notre entreprise Actions à donner<br>
        (6) Actions qui violent ou promeuvent des lois ou des mesures administratives ou qui mènent à des crimes<br>
        (7) Actions qui violent l'ordre public et la morale<br>
        (8) Fournir des bénéfices aux forces antisociales ou à d'autres actions de coopération<br>
        (9) Ventes et publicité , Publicité, sollicitation ou autres activités commerciales (à l'exclusion de celles
        reconnues par la Société), sollicitation à des activités religieuses ou à des groupes religieux, sollicitation à
        des activités politiques, etc. Actes d'utilisation<br>
        (10) Actes de perturbation de l'utilisation de ce service par d'autres utilisateurs<br>
        (11) Actes d'utilisation de ce service en usurpant l'identité d'autres utilisateurs ou d'un tiers<br>
        (12) Particuliers acquis par le biais de ce service Actes de communication d'informations à un tiers<br>
        (13) Actes de don et de réception d'argent entre utilisateurs<br>
        (14) Actes de falsification ou d'effacement illégal des informations pouvant être utilisées par ce service<br>
        (15) Une tentative d'obtenir un accès non autorisé à un autre système informatique ou réseau connecté à ce
        service<br>
        (16) Un acte d'utiliser ou de fournir un programme nuisible tel qu'un virus informatique, ou un acte de
        recommander ces actes<br>
        (17) Modification, altération, octroi de l'autorisation d'utilisation de ce service ou logiciel utilisé pour ce
        service, traduction, vente, imitation, décodage, décompilation après analyse du code source<br>
        (18) livres Outre les services, les actes qui interfèrent avec les services exploités par la société (20) Les
        actes qui mènent à des sites ou des services autres que ce service, tels que des liens vers des sites
        externes<br>

        (19) Informations qui contiennent des expressions excessivement obscènes, des informations qui contiennent des
        informations personnelles qui peuvent identifier d'autres utilisateurs, des expressions cruelles ou d'autres
        informations qui peuvent offenser autrui, ou toute autre information que la Société juge inappropriée Actes
        d'envoi / affichage vers des services<br>
        (20) Actes de guidage vers des sites ou des services autres que ce service, tels que des liens vers des sites
        externes<br>

        (21) Actions qui favorisent les actions
        spécifiées dans les<br>
        éléments précédents (22) Actions spécifiées dans les éléments précédents ou actions suspectées d'être celles-ci
        (23) Autres actions que la Société juge inappropriées<br><br>

        2. L'utilisateur ne doit pas être en mesure de transférer, vendre, prêter, fournir des garanties ou autrement
        disposer de tout ou partie de ses droits en tant qu'utilisateur.
      </p>
      <p>&nbsp;</p>
      <p>
        Article 17 (Modification, suspension, cessation de ce service)<br>
        1. Nous pouvons modifier ou ajouter une partie ou la totalité de ce service sans en informer l'utilisateur à
        l'avance.<br><br>
        2. La Société pourra suspendre tout ou partie du fonctionnement du Service à sa discrétion en cas de survenance
        d'un motif correspondant à l'un des éléments suivants.<br>
        (1) Lorsque ce service ne peut pas être fourni normalement en raison d'un cas de force majeure comme une guerre,
        une émeute, un chaos, un conflit de travail, une catastrophe naturelle, un tremblement de terre, une éruption,
        une inondation, un tsunami, un incendie ou une panne de courant.<br>
        (2) Lors de l'exécution des inspections de maintenance d'urgence en raison de la maintenance temporaire ou à
        grande échelle des systèmes nécessaires au fonctionnement de ce service, des défaillances des équipements de
        télécommunications, des défaillances des fournisseurs, des problèmes de système, etc.<br>
        (3) Lorsque la Société le juge nécessaire, par exemple lorsque des raisons inévitables surviennent.<br><br>

        3. Si nous suspendons tout ou partie de ce service conformément au paragraphe précédent, nous vous en
        informerons au préalable par une méthode que nous jugerons appropriée. Cependant, cela ne s'applique pas en cas
        d'urgence.<br>
        4. Notre société pourra mettre fin à la fourniture de ce service en prévenant préalablement l'utilisateur par
        une méthode que nous jugeons appropriée.<br>

        5. Pour quelque raison que ce soit, notre société ne sera pas responsable des dommages même si l'utilisateur
        subit des inconvénients en raison des dispositions des paragraphes précédents.
      </p>
      <p>&nbsp;</p>
      <p>
        Article 18 (équipement, etc.)<br>
        1. Lorsque la Société découvre que les installations de la Société ou les installations d'exploitation de ce
        service ont échoué ou ont été perdues, la Société s'efforcera de réparer ou de restaurer rapidement les
        installations. Dans un tel cas, nous ne serons pas responsables même si l'utilisateur ou un tiers subit des
        dommages.<br>
        2. L'utilisateur prépare, installe et exploite les équipements de communication, les logiciels et autres
        équipements, les lignes de communication et les autres environnements de connexion nécessaires à l'utilisation
        de ce service, et met ce service à disposition à ses frais et sous sa responsabilité. ça ira.
      </p>
      <p>&nbsp;</p>
      <p>Article 19 (Traitement des informations personnelles)<br>
        1. Sauf si la loi l'exige, nous ne divulguerons ni ne fournirons les informations personnelles contenues dans
        les informations utilisateur à un tiers sans le consentement préalable de la personne qui est l'utilisateur.
        Cependant, les cas suivants sont exclus.<br>
        (1) Lorsqu'il est prescrit par la loi sur la protection des informations personnelles, par exemple lorsqu'il est
        basé sur des lois et règlements, ou lorsqu'une demande légale est reçue d'une institution publique telle qu'un
        tribunal ou une police.<br>
        (2) Lorsque l'approbation et le consentement préalable de l'utilisateur sont obtenus.<br>
        (3) Lors de la divulgation à un sous-traitant nécessaire à la fourniture de ce service.<br>
        (4) Lorsque l'entreprise est réussie par la fusion, la scission ou le transfert de l'entreprise et que les
        informations sur l'utilisateur sont réussies.<br><br>

        2. La Société pourra utiliser gratuitement les informations de l'utilisateur aux fins suivantes en copiant ou
        par d'autres méthodes et les fournir à un tiers, et l'utilisateur y consent.<br>
        (1) Pour réaliser et fournir<br>
        ce service a. Pour étendre et améliorer ce service, pour rechercher / analyser de nouveaux développements
        b. Pour répondre aux demandes de renseignements et de soutien<br>
        c. Pour d'autres fins, avec le consentement du client pour<br>

        (2) Créer des informations statistiques relatives à l'état d'utilisation de ce service et les publier sur ce
        service ou sur le site Internet exploité par notre société.<br>

        3. Nous ne sommes pas obligés de sauvegarder les informations d'enregistrement des utilisateurs. Vous êtes
        responsable de la sauvegarde des informations utilisateur et du profil utilisateur nécessaires.<br>

        4. Lorsque l'utilisateur se retire du service ou que le fonctionnement du service est terminé, la Société sera
        en mesure de supprimer toutes les informations d'enregistrement de l'utilisateur à sa discrétion, et ne répondra
        à aucune demande de retour, etc. .. De plus, nous déclinons toute responsabilité pour les inconvénients,
        dommages, etc. causés aux utilisateurs.<br>

        5. Il appartient à l'utilisateur de nous fournir des informations personnelles. Il est de la responsabilité de
        l'utilisateur de décider d'enregistrer ou non des informations personnelles. Cependant, si nous ne sommes pas en
        mesure de fournir les informations personnelles que nous demandons pour fournir ce service, ou si les
        informations personnelles fournies sont incomplètes, nous ne serons pas en mesure de fournir le service dans le
        but d'obtenir les informations personnelles. Il y a des cas.<br>

        6. Ce service peut utiliser des cookies. Il s'agit de vérifier l'état de navigation des sites WEB, des
        applications et des publicités par les utilisateurs dans le but d'améliorer ce service et d'analyser l'état de
        l'utilisation, et il est utilisé comme informations statistiques de marketing. Aucune information personnelle
        n'est incluse dans le cookie. De plus, nous n'obtenons pas d'informations personnelles ni ne les associons à des
        informations utilisateur. De plus, ce service utilise le module de collecte d'informations fourni par les
        entreprises suivantes pour obtenir automatiquement des informations via les entreprises.</p>
      <p>——————–<br>
        a. Nom du service: Google Analystics<br>
        Nom du fournisseur: Google Inc.<br>
        Éléments d'information à acquérir: cookie HTTP, adresse IP, variables d'environnement du navigateur, IDFA,
        UUID,<br>
        utilisation du système d'exploitation : ce service Amélioration et analyse du statut d'utilisation<br>
        Fourni par un tiers: Aucun<br>
        http://www.google.com/intl/ja/policies/privacy/<br>
        ——————–<br>
        7. Nous répondrons aux demandes de notification, de divulgation, de correction, d'ajout, de suppression, de
        suspension d'utilisation ou de suspension de la fourniture par des tiers d'informations personnelles fournies
        par les utilisateurs. Pour toute demande et réclamation concernant le traitement des informations personnelles,
        veuillez contacter les demandes suivantes.<br>
        KAWAII JAPAN Co., Ltd.THE SOHO 706 2-7-4 Aomi, Koto-ku, Tokyo 1350064
      </p>
      <p>TEL : 03-5530-7056</p>
      <p>Courriel :<a href="mailto:info@kawaiijapan.co.jp">info@kawaiijapan.co.jp</a></p>
      <p>
        Article 20 (Clause de non-responsabilité)<br>
        1. Notre société ne garantit pas le contenu de ce service, toutes les informations, données, etc. fournies par
        ce service, y compris son exhaustivité, son exactitude, sa légalité et son adéquation à des fins, et nous
        n'assumons aucune responsabilité.<br>
        2. La Société ne garantit pas que toutes les informations, données, etc. fournies par la Société par ce service,
        ou toutes les informations, données, etc. publiées par les utilisateurs seront stockées en toute sécurité, et
        que ces informations, données, etc. seront perdues. Cependant, nous ne prenons aucune responsabilité.<br>

        3. La Société ne doit pas fournir de dommages aux utilisateurs et aux tiers causés par la fourniture, le retard,
        la modification, l'annulation ou la résiliation de ce service, la perte ou la disparition d'informations, etc.
        fournies ou fournies via ce service, et d'autres dommages liés à ce service. Pas responsable.<br>

        4. Si l'utilisateur cause des dommages à un autre utilisateur ou à un tiers en relation avec l'utilisation de ce
        service, ou si l'utilisateur a un différend avec l'autre utilisateur ou un tiers, l'utilisateur supportera ses
        propres frais. Nous prendrons la responsabilité de la solution et ne causerons aucun inconvénient ni dommage à
        notre entreprise. En outre, si un utilisateur nous cause des dommages dans le cadre de l'utilisation de ce
        service, l'utilisateur doit réparer les dommages qui nous sont causés.<br>

        5. Nous ne garantissons pas l'état de fonctionnement du système informatique qui fournit ce service, et nous ne
        serons pas responsables des dommages causés par une cause quelconque, comme une défaillance du système
        informatique pour fournir ce service.<br>

        6. Nous ne sommes pas responsables des dommages causés à votre ordinateur, périphériques, lignes, logiciels,
        etc. en utilisant ce service, téléchargement à partir de ce site, infection par un virus informatique, etc.<br>

        7. Même si nous sommes responsables des dommages causés à l'utilisateur conformément à cet accord, la gamme des
        dommages que nous devons indemniser se produira directement et de manière réaliste pour l'utilisateur, sauf en
        cas de négligence intentionnelle ou grave de la part de l'entreprise. Limité aux dommages.
      </p>
      <p>&nbsp;</p>
      <p>Article 21 (tribunal compétent)<br>
        1. Si un problème survient entre vous et nous dans le cadre de ce service, nous en discuterons de bonne foi avec
        vous.<br>
        2. Si le cas n'est pas résolu même après consultation, le tribunal de district de Tokyo sera le tribunal de
        juridiction exclusive de première instance.</p>
      <p>&nbsp;</p>
      <p>Article 22 (Loi applicable)<br>
        La loi applicable à cet accord est la loi japonaise.</p>
      <p>
        Règles supplémentaires Cet accord sera mis en œuvre à partir du 14 février 2017 .<br>
        Révisé le 1er août, 1er Reiwa</p>
      <p>&nbsp;</p>
    </div>
  </ng-container>
  <ng-container *ngIf="lang==='kor'">
    <h2 class="cospo-title">COSPO 이용 약관</h2>
    <div class="entry entry-content">
      <p>&nbsp;</p>
      <h1>COSPO 이용 약관</h1>
      <p>
        제 1 조 (목적)<br>
        본 약관은 KAWAIIJAPAN 주식회사 (이하 "회사"라고합니다.)가 제공하는 서비스 "COSPO (코스뽀)"를 사용자 (제 2 조에 정의)이 사용하기위한 조건 를 정하는 것입니다.
      </p>
      <p>&nbsp;</p>
      <p>제 2 조 (정의) 본 약관에서 용어의 정의는 다음과 같다합니다.</p>
      <p>(1) "서비스"당사가 운영하는 「COSPO (코스뽀) "에서 제공되는 모든 서비스를 말합니다. </p>
      <p>(2) "사용자" 본 약관을 승인 한 후, 당사 소정의 절차에 따라 서비스 이용을 신청 한 개인 또는 회사의 승낙을 얻은 개인이 아닌 개인 회사가 사용자 등록을 승인 한자를 말합니다 있습니다.</p>
      <p>(3) "사용자 정보" 사용자가 본 서비스를 이용하는데 필요한 당초 소정의 포맷에 따라 당사가 등록 또는 제공을 요구하는 정보를 말합니다</p>
      <p>(4) "사용자 콘텐츠" 본 서비스 내에서 사용자가 전송, 게시 또는 등록 표시 등 (이하, 이러한 행위를 총칭하여 "표기"라고합니다) 한 모든 정보를 말합니다.</p>
      <p>(5) "제공 콘텐츠" 사용자가 본 서비스를 이용하기 위해서는 회사가 제공하는 사진, 이미지, 동영상, 텍스트, 음성, 아이템 기타 정보 (사용자 컨텐츠를 포함합니다)를 말합니다합니다.</p>
      <p>(6) "포인트" 본 서비스에서 유료로 제공되는 서비스 또는 기타 당사가 지정한 콘텐츠와 교환 할 수있는 점수를 말합니다.</p>
      <p>&nbsp;</p>
      <p>제 3 조 (약관의 동의 적용범위)<br>
        1. 사용자는 본 약관의 규정에 따라 본 서비스를 이용해야합니다.<br>

        2. 사용자는 당사가 제공하는 어플리케이션 「COSPO "스마트 폰 기타 정보 단말에 다운로드 본 약관에 동의 절차를 밟지 않는 한 본 서비스를 이용할 수 없습니다.<br>

        3. 본 서비스의 제공에있어서 별도로 정한 약관 당사가 실시하는 약관의 공지 본 제품에 대한 도움말 및 사용자에게 알리는 통지 등에 기재된 정보 (이하 "관련 규정"이라 함)은 본 약관 의 일부를
        구성하는 것으로 당해 고지, 통지 등의 내용이 본 약관의 조항과 다른 결정을 할 경우 해당 고지, 통지 등의 규정이 우선하여 적용되는 것으로합니다.</p>
      <p>&nbsp;</p>
      <p>제 4 조 (사용자 정보의 등록)<br>
        1. 사용자가되기를 희망하는자는 본 서비스를 이용함에있어 본 약관을 승인에 당사가 지정하는 절차에 따라 사용자 등록 절차를 실시하는 것으로합니다.<br>

        2. 사용자가 미성년자의 경우 법정 대리인의 동의 (본 약관에 동의 포함)를 얻어야합니다.<br>

        3. 당사는 사용자 등록 절차가 진행된 경우에는 당사 소정의 인증 절차에 따라 사용자 정보를 등록 할 계정을 부여합니다. 그러나 우리는 부적당하다고 인정한 때에는 사용자 등록을 거절 할 수 있으며,이
        경우 해당 등록 절차를 거친 자에 대하여 거절 이유를 공개 할 의무를지지 않는 것으로합니다.<br>

        4. 전항에 규정하는 계정 부여를 가지고 사용자와 회사 사이에 본 서비스의 이용 계약이 성립합니다. 또한, 당사가 부적당하다고 판단하는 경우에는 계정 부여 후에도 계정을 취소하고 본 서비스의 이용
        계약을 해지 할 수 있습니다. 그러나 우리는 승낙을 취소 한 이유에 대해 답변 할 의무를지지 않습니다.
      </p>
      <p>&nbsp;</p>
      <p>제 5 조 (변경신고)<br>
        1. 사용자 정보에 변경이 발생한 경우, 사용자는 즉시 해당 등록 정보의 변경 수속을 실시하는 것으로합니다.<br>
        2. 사용자 정보에 허위, 오류가있는 경우 또는 사용자가 제 1 항의 신고를하지 않음으로써 불이익을하더라도 당사는 일체의 책임을지지 않습니다합니다.
      </p>
      <p>&nbsp;</p>
      <p>제 6 조 (계정 삭제등)<br>
        1. 사용자가 다음 각 호의 어느 하나에 해당하는 경우 또는 당사가 부적당하다고 판단한 경우 회사는 사전 통지없이 해당 사용자에 대한 서비스 이용 정지 또는 제한, 계정 삭제를 포함 이용 계약 해지
        등의 조치를 취할 수있는 것으로합니다. 또한, 당사는 당해 조치의 이유를 공개 할 의무를지지 않는 것으로합니다.<br>
        (1) 본 약관의 어떤 조항을 위반 한 경우<br>
        <br>
        (2) 당사에 제공 한 정보에 허위가있을 경우<br>
        (3) 사용자가 미성년자, 성년 피후견인, 피 보좌인 또는 피 보조인 중 하나 인 경우, 법정 대리인, 성년 후견인, 보좌인 또는 보조인의 동의 등을 얻지 않은 경우<br>
        (4) 사용자가 사망 또는 후견, 보좌 또는 보조가 시작되었을 때<br>

        (5) 기타 사용자로서 부적절 또는 등록의 계속이 곤란하다고 당사가 판단하는 경우<br>

        (6) 기타 전 각호에 유사한 사유가 있다고 당사가 판단한 경우<br><br>

        2. 항에 따라 등록의 삭제 또는 이용 계약의 해제를받은 경우에 당해 사용자가 다시 등록 신청을 한 경우 당사는 해당 신청을 승낙하지 않을 수 있습니다.<br>
        3. 당사는 본 조에 따라 당사가 수행 한 조치로 사용자에게 발생한 불이익, 손해에 대해 책임을지지 않습니다합니다.<br>
      </p>


      <p>
        제 7 조 (사용자 콘텐츠의 취급)<br>
        1. 사용자 컨텐츠를 표기 한 사용자는 해당 사용자 콘텐츠에 관하여 모든 책임을 져야합니다.<br><br>

        2. 사용자는 다음 정보를 표시 할 수 없습니다.<br>

        (1) 당사 또는 다른 사용자 기타 제 3 자의 저작권 등 지적 재산권, 프라이버시, 초상권, 퍼블리시티권 기타 권리 또는 이익을 침해하는 것

        (2) 다른 사용자 타사 또는 당사를 비방하거나 명예 또는 신용을 해칠 것<br>

        (3) 다른 이용자, 제삼자 또는 당사에 불이익을주는 것<br>

        (4) 법률, 행정 조치에 위반하거나이를 조장하는 것 또는 범죄에 결합되는<br>

        (5) 공서 양속에 반하는 것<br>

        (6) 반사회적 세력에 대한 이익 공여 기타 협력 행위<br>

        (7) 원조 교제 · 매춘 · 매춘 등을 실시해, 또는 그들을 초대한다<br>

        (8) 아동 (만 18 세 미만의 남녀를 말합니다.) 이성 교제의 상대방이되도록 권유 또는 성인에게 아동과의 이성교 제의 상대방이되도록 권유하는 것<br>

        (9) 영업, 홍보, 광고, 권유 기타 영리를 목적으로하는 것 (우리가 인정하는 것은 제외), 종교 활동이나 종교 단체에 권유 정치 활동에 대한 권유 등 본 서비스의 취지에 반 물건<br>

        (10) 컴퓨터 바이러스 등 유해한 프로그램을 사용 또는 포함하는<br>

        (11) 지나치게 외설적 인 표현을 포함하는 잔혹한 표현 기타 타인에게 불쾌감을 줄 우려가있는 것<br>

        (12) 기타 당사가 부적절하다고 판단하는 것<br><br>

        3. 당사는 사용자 컨텐츠가 본 약관을 위반 한 경우 기타 당사가 부적당하다고 판단한 경우에는 사전 통지없이 사용자 컨텐츠의 전부 또는 일부를 제거 할 수있는 것으로합니다. 또한, 당사는 당해 조치의
        이유를 공개 할 의무를지지 않는 것으로합니다.<br>

        4. 당사는 사용자 콘텐츠를 무료로 서비스의 제공 및 이용 촉진을 위해, 복제, 편집, 전시, 공중 송신, 출판 등을 이용할 수 있으며, 사용자는이 것을 미리 우리에게 부여 물건합니다.<br>

        5. 사용자는 사용자 콘텐츠에 대해서 당사 이외의 제삼자에게 부여 할 수없는 것으로합니다.<br>

      </p>



      <p>
        제 8 조 (사용자 계정관리)<br>
        1. 사용자는 본 서비스의 계정을 자기 책임하에 관리하여야하며이를 제삼자에게 이용하게하거나 대여, 양도, 명의 변경 등을 할 수 없습니다.
        2. 사용자는 본 서비스의 계정을 모든 이메일 주소와 비밀번호로 관리 할 수 있습니다. 사용자는 자신이 등록한 이메일 주소 및 비밀번호가 부정하게 사용되는 일이 없도록 자기의 책임으로 엄중하게<br>
        관리해야합니다.<br>
        3. 이메일 주소 및 비밀번호의 관리 소홀, 사용상의 과실, 제 3 자 라이센스 등에 의한 손해의 책임은 사용자가 부담하며, 당사는 일절 책임을지지 않습니다.<br>
        4. 계정을 통한 서비스의 이용은 사용자 본인 이외의 제삼자에 의한 이용 및 행위의 경우도 포함하여 모든 해당 사용자의 이용 및 행위로 간주합니다. 해당 사용자는 해당 이용 및 행위로 인하여 발생한
        일체의 채무를 부담하는 것으로합니다.<br>
        5. 사용자는 이메일 주소와 암호를 부당하게 취득 된 또는 계정이 제삼자에 의해 이용되고 또는 그 우려가있는 경우에는 즉시 당사에 그 취지 연락 함과 동시에 회사의 지시가있는 경우에는이를 에
        따라야합니다.<br>

        6. 당사는 계정의 관리 소홀 또는 제삼자의 사용 등에 의한 손해에 대해 책임을지지 않습니다.<br>

        7. 사용자가 실수로 계정을 삭제하는 등 사용자의 행위로 인해 계정이 삭제 된 경우 사용자 계정 콘텐츠 기타 서비스에 대한 정보를 제공 할 수 없게되는 것을 미리 승낙하는 것으로합니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 9 조(탈퇴)<br>
        1. 사용자는 당사가 지정하는 탈퇴 절차에 따라 탈퇴하여 본 서비스의 이용을 정지 할 수 있습니다.<br>
        2. 전조 제 3 항 내지 제 6 항은 본조에 따라 탈퇴 한 경우에도 적용되는 것으로합니다.<br>
        3. 사용자가 탈퇴 한 경우에도 유상 서비스 요금은 환불하지 않습니다.
      </p>
      <p>&nbsp;</p>
      <p>제 10 조 (약관의 개정 ·변경)<br>
        1. 우리는 필요에 따라 언제든지 본 약관 및 관련 규정을 수정하거나 개정 할 수있는 것으로합니다. 또한, 본 약관 및 관련 규정의 변경은 본 서비스 내 또는 당사가 운영하는 웹 사이트에 게시 된
        시점에 효력이 발생하며, 변경 후 사용자가 본 서비스를 이용한 경우는 변경된 약관에 동의 한 것으로 간주합니다.<br>
        2. 당사는 미래에 본 서비스 사업을 계승하는 회사에 본 서비스에 대한 우리의 지위를 양도 할 수 있습니다. 이 경우의 지위 양도의 절차는 전항의 규정을 준용하는 것으로합니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 11 조 (유료 서비스판매)<br>
        1. 당사는 본 서비스의 일부를 사용자에게 유료로 제공 할 수 있습니다.<br>
        2. 사용자는 전항에 정하는 유료 서비스 (이하 "유상 서비스"라고합니다.)의 이용 권한을 당사가 별도로 정한 서비스에 게재하는 대가를 지불함으로써 얻을 수 있습니다.<br>
        3. 사용자는 취득한 유상 서비스를 다른 사용자에게 이전, 양도, 대여 또는 본 서비스 또는 그와 유사한 서비스에 표기하지 않는 것으로합니다.<br>
        4. 당사는 유상 서비스에 대해 당사의 재량에 따라 그 가격을 변경할 수 있습니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 12 조(포인트)<br>
        1. 포인트는 본 서비스에서 "구매"기타 당사가 지정하는 방법으로 사용자에게 부여되는 것으로합니다. 포인트 구매 단위, 지불 방법, 기타 부여 조건은 당사가 별도로 정한 서비스에 게재하는
        것으로합니다.<br>
        2. 포인트는 유료 서비스 기타 당사가 지정한 콘텐츠로만 교체 또는 이용할 수 있으며, 기타 콘텐츠 또는 서비스, 현금 기타 경제상의 이익과 교환하거나 사용할 수 없습니다. 교환 또는 이용하기 위해
        필요한 포인트 등 포인트의 이용 조건은 당사가 별도로 정한 서비스에 게재하는 것으로합니다.<br>
        3. 당사는 이유 여하를 막론하고 포인트 재발급 및 환전을하지 않습니다. 또한 포인트의 환불은 일절하지 않습니다.<br>
        4. 당사가 본 서비스를 종료 또는 중지하거나 제 6 조의 규정에 의한 계정 삭제 사용자의 탈퇴 등으로 사용자 자격을 상실한 경우 일정 공지 기간 경과 시점에 잔존 포인트가 소멸하는 것으로 있습니다.
        또한 그러한주지 기간 동안 새 포인트의 취득은 할 수 없습니다.<br>
        5. 사용자는 당사가 특별히 정한 경우를 제외하고 보유 포인트를 타인에게 이전, 양도, 대여 할 수 없습니다.<br>
        6. 사용자는 포인트를 사용하여 얻은 유상의 서비스를 타인에게 이전, 양도, 대여 또는 본 서비스 또는 그와 유사한 서비스에 표기되지 않습니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 13 조 (이용요금)<br>
        1. 사용자는 전에 2 조에서 규정 한 유료 서비스를 이용하는 대가 유상 포인트를 획득하는 대가로 별도 회사가 정한 본 서비스에 게시하는 이용 요금을 당사가 지정한 지불 방법으로 당사에 지불해야합니다.
        단, 당사가 인정하는 경우에는 사용자는 이용 요금을 이에 상당하는 액수의 포인트로 지불 할 수있는 것으로합니다.<br>
        2. 법령 등에 의해 환불이 요구되는 경우를 제외하고 당사는 이유 여하를 막론하고 지불 한 요금을 환불하지 않습니다.<br>
        3. 미성년자는 유료 서비스 구매 포인트 구매함에있어 사전에 법정 대리인의 동의가 필요합니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 14 조 (보수)<br>
        1. 당사는 사용자 콘텐츠의 내용 및 해당 사용자 콘텐츠에 대한 사용자의 행동 기타 당사가 정하는 기준에 의한 평가에 따라 사용자 컨텐츠를 표기 한 사용자에게 보상을 지불해야합니다. 또한, 평가 기준
        및 보상의 지불 방법 등은 당사의 규정에 의한 것으로 우리는 적절하게이를 변경할 수있는 것으로합니다.<br>
        2. 사용자가 본 규약에 위반하는 등 보상을 지불 할 것이 부적당하다고 인정되는 경우, 당사는 사용자에게 보상을 지불하지 않습니다. 또한이 경우 이미 지불 된 보수의 반환을 청구 할 수있는
        것으로합니다.
      </p>

      <p>&nbsp;</p>

      <p>
        제 15 조 (권리의귀속)<br>
        1. 본 서비스 및 제공 콘텐츠 사용자 콘텐츠에 대한 저작권 기타 일체의 권리는 당사 또는 당사에 라이센스를 부여하거나 본 서비스의 제공을 허락 한 권리자에게 귀속합니다.<br>
        2. 당사는 본 서비스 및 당사의 관리 WEB 사이트, SNS 사이트에서 사용할 목적으로 사용자에게 양도 및 재 허락 할 수없는 제안 내용의 비 독점적이고 사적인 이용을 허락합니다.<br>
        3. 사용자는 제공 콘텐츠를 복사, 공중 송신 또는 기타 방법으로 이용해서는 안됩니다.<br>
        4. 사용자는 본 서비스에서 자기가 이용하기 때문에 자신의 판단에 의해 본 서비스에 사용자 프로필을 등록 할 수있는 것으로합니다.<br>
        5. 사용자 콘텐츠 및 사용자 프로필에 다른 사람이 권리를 가지고 지적 재산권 등이 포함 된 경우에는 사용자가 해당 권리자에게 권리 부여 등에 관한 절차를 실시하는 것으로합니다.<br>
        6. 사용자가 이전 3 항의 규정에 위반하여 문제가 발생한 경우, 사용자는 자신의 비용과 책임 걸리는 문제를 해결하는 것으로 당사에 하등의 폐 또는 손해를주지 않는 것으로합니다 있습니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 16 조 (금지사항)<br>
        1. 사용자는 본 서비스의 이용에있어서 다음의 행위를해서는 안됩니다.<br>
        (1) 원조 교제 · 매춘 · 매춘 등을 실시해, 또는 그들을 권유하는 행위<br>
        (2) 아동 (만 18 세 미만의 남녀를 말합니다.) 이성 교제의 상대방이되도록 권유 또는 성인에 대해 아동과의 이성교 제의 상대방이되도록 권유하는 행위<br>
        (3) 당사 또는 다른 사용자 기타 제 3 자의 저작권 등 지적 재산권, 프라이버시, 초상권, 퍼블리시티권 기타 권리 또는 이익을 침해하는 행위 또는 침해 할 우려가있는 행위<br>
        (4) 다른 사용자 타사 또는 당사를 비방하거나 명예 또는 신용을 손상시키는 행위<br>
        (5) 다른 이용자, 제삼자 또는 당사에 불이익을 주는 행위<br>
        (6) 법률, 행정 조치에 위반하거나이를 조장하는 행위 또는 범죄에 결부되는 행위<br>
        (7) 공서 양속에 반하는 행위<br>
        (8) 반사회적 세력에 대한 이익 공여 기타 협력 행위<br>
        (9) 영업 홍보 광고, 권유 기타 영리를 목적으로하는 행위 (우리가 인정하는 것은 제외), 종교 활동이나 종교 단체에 권유 행위, 정치 활동에 대한 권유 목적 등 본 서비스의 취지에 반하는 목적으로
        본 서비스 를 이용하는 행위<br>
        (10) 다른 이용자의 서비스 이용을 방해하는 행위<br>
        (11) 다른 이용자 또는 제 3 인척 본 서비스를 이용하는 행위<br>
        (12) 서비스를 통해 취득한 개인 정보 등을 제삼자에게 제공하는 행위<br>
        (13) 이용자간에 금전의 수수를하는 행위<br>
        (14) 본 서비스를 통해 이용할 수있는 정보를 위조 · 부정하게 삭제하는 행위<br>
        (15) 본 서비스에 연결되어있는 다른 컴퓨터 시스템 또는 네트워크에 대한 무단 액세스를 시도하는 행위<br>
        (16) 컴퓨터 바이러스 등 유해한 프로그램을 사용 또는 제공하는 행위, 또는 이러한 행위를 권장하는 행위<br>
        (17) 본 서비스 또는 본 서비스에 사용되는 소프트웨어의 수정, 사용 권한 부여, 번역, 판매, 소스 코드를 분석 한 후 모방, 해독, 디 컴파일 행위<br>
        (18) 본 서비스 다른 회사가 운영하는 서비스를 방해하는 행위 (20) 외부 사이트로의 링크 등 본 서비스 이외의 사이트, 서비스에 유도하는 행위<br>

        (19) 지나치게 외설적 인 표현을 포함한 정보 다른 사용자를 식별 가능한 개인 정보를 포함한 정보 잔혹한 표현 기타 타인에게 불쾌감을 줄 우려가있는 정보 기타 당사가 부적절하다고 판단하는 정보를 본
        서비스에 전송 · 게시하는 행위<br>
        (20) 외부 사이트로의 링크 등 본 서비스 이외의 사이트, 서비스에 유도하는 행위<br>

        (21) 전 각 호에 정하는 행위를 조장하는 행위<br>
        (22) 전 각 호에 정하는 행위 또는 이와 의심되는 행위<br>
        (23) 기타 당사가 부적절하다고 판단하는 행위<br><br>

        2. 사용자는 사용자로서 가지는 권리의 전부 또는 일부를 타인에게 양도, 매매, 대여, 담보로 제공 행위 기타 일체의 처분 행위를 할 수없는 것으로합니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 17 조 (본 서비스의 변경 · 중단 ·종료)<br>
        1. 당사는 사용자에게 사전 통지없이 본 서비스의 일부 또는 전부를 변경, 추가 할 수 있습니다.<br><br>
        2. 당사는 다음 각 호의 어느 하나에 해당하는 사유가 발생한 경우에는 당사의 판단에 의해 본 서비스의 운용의 전부 또는 일부를 중단 할 수있는 것으로합니다.<br>
        (1) 전쟁, 폭동, 노동 쟁의, 천재 지변, 지진, 분화, 홍수, 해일, 화재, 정전 등 불가항력으로 인하여 서비스가 정상적으로 제공 할 수 없게 된 경우.<br>
        (2) 본 서비스의 운영에 필요한 시스템 등 임시 또는 대규모 유지 보수, 전기 통신 설비의 장애, 공급자 등의 장애, 시스템 문제 등으로 인한 긴급 보수 점검을 할 경우.<br>
        (3) 기타 부득이한 사유가 발생한 경우 등 당사가 필요하다고 판단한 경우<br><br>

        3. 당사는 전항에 의해 본 서비스의 전부 또는 일부를 중단 할 경우, 당사가 적절하다고 판단하는 방법으로 사전에 공지합니다. 그러나 긴급한 경우에는 그러하지 않습니다.<br>
        4. 당사는 당사가 적절하다고 판단하는 방법으로 사전에 사용자에게 그 취지를 통지함으로써 본 서비스의 제공을 종료 할 수있는 것으로합니다.<br>

        5. 당사는 이유 여하를 막론하고 전 각항의 규정에 따라 사용자가 어떤 불이익을받은 경우에도 손해 배상 책임을 포함한 모든 책임을지지 않습니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 18 조 (시설등)<br>
        1. 당사의 설비 또는 서비스의 운영 설비에 장애가 발생하거나 그 설비가 멸실 된 것을 우리가 알게되면, 우리는 즉시 그 설비를 수리 · 복구하도록 노력하는 것으로합니다. 걸리는 경우 사용자 또는
        제삼자에게 손해가 발생한 경우에도 당사는 일절 책임을지지 않는 것으로합니다.<br>
        2. 사용자는 자기 부담과 책임하에 본 서비스의 이용에 필요한 통신 기기, 소프트웨어 기타 장비 및 통신 회선 기타 접속 환경 등을 준비, 설치, 조작, 본 서비스를 이용 가능한 상태로한다 합니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 19 조 (개인 정보의취급)<br>
        1. 당사는 법령에 정하는 경우를 제외하고 사전에 사용자 인 본인의 동의없이 사용자 정보에 포함되어있는 개인 정보를 제삼자에게 공개, 제공하지 않습니다. 그러나 다음의 경우는 제외합니다.<br>
        (1) 법령에 근거하는 경우 등 개인 정보 보호법에서 규정하거나 법원 · 경찰 등의 공적 기관으로부터 법률에 의한 요청을받은 경우.<br>
        (2) 사용자의 사전 승인 및 동의를 얻은 경우.<br>
        (3) 본 서비스를 제공하는 데 필요한 업무 위탁 처에 공개하는 경우.<br>
        (4) 회사의 합병, 분사화, 사업 양도에 따라 사업을 상속 사용자 정보를 관리해야 할 경우.<br><br>

        2. 당사는 사용자 정보를 다음으로 내거는 목적에 따라 무상으로 복제 또는 기타 방법으로 이용하여 제삼자에게 제공 할 수있는 것으로, 사용자는이를 승낙하는 것으로합니다.<br>
        (1) 본 서비스 실시, 제공에 대한<br>
        a. 서비스의 확충, 개선, 신규 개발을위한 조사 · 분석을위한<br>
        b. 문의 지원 대응을위한<br>
        c. 기타 고객의 동의를 얻은 이용 목적 위한<br>

        (2) 본 서비스의 이용 상황 등에 관한 통계 작성, 서비스 또는 회사가 운영하는 웹 사이트 등의 공표를 위해.<br>

        3. 당사는 사용자 등록 정보를 저장 의무를지지 않습니다. 사용자는 필요한 사용자 정보 및 사용자 프로필에 대한 스스로의 책임으로 백업을 취하는 것으로합니다.<br>

        4. 당사는 이용자가 본 서비스를 탈퇴 또는 당사가 본 서비스의 운영을 종료했을 때는 우리의 판단에 따라 사용자 등록을 지운다있는 것으로 그 반환 등의 요청에 일절 응하지 않습니다 . 또한, 당사는이를
        통해 사용자에게 발생하는 불이익, 손해 등에 대해 책임을지지 않습니다.<br>

        5. 개인 정보를 당사에 제공하거나 여부는 사용자 본인의 선택 사항입니다. 개인 정보 등록 여부는 사용자 스스로 판단하십시오. 그러나 당사가 본 서비스를 제공하는 데 부탁하는 개인 정보를 제공받을 수
        있지 않는 경우 나, 제공 한 개인 정보가 미비한 경우에는 개인 정보의 취득 목적대로 서비스를 제공 할 수없는 수 있습니다.<br>

        6. 본 서비스는 쿠키를 사용하는 경우가 있습니다. 이것은 본 서비스 개선 및 이용 상황의 분석을위한 사용자의 WEB 사이트, 앱, 광고의 열람 상황을 확인하는 것으로, 통계적인 마케팅 정보로
        활용하겠습니다. 쿠키에 개인 정보가 포함되는 것은 아닙니다. 또한 개인 정보의 취득 및 사용자 정보와의 연결을 의미하는 것은 아닙니다. 또한 본 서비스는 아래의 사업자가 제공하는 정보 수집 모듈을
        사용하여 해당 사업자를 통해 정보를 자동 검색합니다.
      </p>
      <p>——————–<br>
        서비스 이름 : Google Analystics<br>
        제공 회사 명 : Google Inc.<br>
        취득하는 정보의 항목 : HTTP Cookie IP 주소 및 브라우저 환경 변수 IDFA, UUID 이용 OS<br>
        이용 목적 : 본 서비스 개선 사용 현황 분석<br>
        제삼자 제공의 유무 : 무<br>
        http://www.google.com/intl/ja/policies/privacy/<br>
        ——————–<br>
        7. 당사는 사용자가 제공 한 개인 정보의 이용 목적의 통지, 개시, 정정, 추가, 삭제, 이용 정지 또는 제삼자 제공 정지의 청구에 대응합니다. 개인 정보의 취급에 관한 문의, 불만 등은 다음의
        연락처로 부탁드립니다.<br>
        KAWAII JAPAN 주식회사(우) 135-0064 도쿄도 코토 구 아오 미 2-7-4 THE SOHO 706
      </p>
      <p>TEL : 03-5530-7056</p>
      <p>이메일 :<a href="mailto:info@kawaiijapan.co.jp">info@kawaiijapan.co.jp</a></p>
      <p>
        제 20 조 (면책조항)<br>
        1. 당사는 본 서비스의 내용 본 서비스를 통해 제공하는 일체의 정보, 데이터 등에 대해 그 완전성, 정확성, 적법성, 목적에의 적합성 등 어떠한 보증도하지 않으며, 일체의 책임을지지 않습니다.<br>
        2. 당사는 당사가 본 서비스를 통해 제공하는 일체의 정보, 데이터 등 또는 사용자가 게시하는 일체의 정보, 데이터 등을 저장되도록 보장하는 것이 아니라 이러한 정보 데이터 등이 소실 된로 도
        책임을지지 않습니다.<br>
        3. 본 서비스의 제공 지체, 변경, 중지 또는 종료 서비스를 통해 등록, 제공되는 정보 등의 유실 또는 소실 등 기타 본 서비스와 관련하여 발생한 사용자 또는 제 3 자의 손해에 대해서 당사는 일체의
        책임을지지 않습니다.<br>
        4. 본 서비스의 이용과 관련하여 사용자가 다른 사용자 또는 제삼자에게 손해를 입힌 경우 또는 사용자가 다른 사용자 또는 제삼자와 분쟁이 발생한 경우, 해당 사용자는 자신의 비용 과 책임으로 해결하는
        것으로 당사에 하등의 폐 또는 손해를주지 않는 것으로합니다. 또한 사용자가 본 서비스의 이용과 관련하여 회사에 손해를 생 불사 한 경우 해당 사용자는 당사에게 발생한 손해를 배상하여야합니다.<br>
        5. 당사는 본 서비스를 제공하는 컴퓨터 시스템의 가동 상황에 대해 보증하는 것이 아니며, 본 서비스 제공을위한 컴퓨터 시스템의 장애 등, 어떠한 원인에 따라 발생한 손해에 대해서도 책임을지지
        않습니다.<br>
        6. 당사는 본 서비스의 이용 본 사이트에서 다운로드 컴퓨터 바이러스 감염 등에 의해 컴퓨터 주변 기기, 회선, 소프트웨어 등으로 발생한 손해에 대해 책임을지지 않습니다.<br>
        7. 본 약관에 따라 당사가 사용자에게 손해 배상 책임을지는 경우에도 당사에 고의 또는 중과실이 존재하는 경우를 제외하고 당사가 배상해야 할 손해의 범위는 사용자에게 직접적이고 현실적으로 발생 손해에
        한정됩니다.
      </p>
      <p>&nbsp;</p>
      <p>
        제 21 조 (관할 법원) <br>
        1. 본 서비스와 관련하여 사용자와 당사 사이에 문제가 생긴 경우에는 사용자와 회사에서 성실하게 협의하여야합니다.<br>
        2. 협의해도 해결되지 않고 소송이되었을 경우, 도쿄 지방 법원을 제 1 심 전속 적 합의 관할 법원으로합니다.</p>
      <p>&nbsp;</p>
      <p>제 22 조 (준거법)<br>
        본 규약에 관한 준거법은 일본 법으로합니다.</p>
      <p>
        부칙<br>
        본 약관은 2017 년 2 월 14 일부터 실시합니다.<br>
        令和1 년 8 월 1 일 개정<br>
      </p>
      <p>&nbsp;</p>
    </div>
  </ng-container>
  <ng-container *ngIf="lang==='zh'">
    <h2 class="cospo-title">COSPO服务条款</h2>
    <div class="entry entry-content">
      <p>&nbsp;</p>
      <h1>COSPO服务条款</h1>
      <p>
        第1条（目的）<br>
        本协议是用户（在第2条中定义）使用KAWAII JAPAN Co.，Ltd.（以下简称“公司”）提供的“ COSPO”服务的条件。是确定的。
      </p>
      <p>&nbsp;</p>
      <p>第2条（定义）本协议中的术语定义如下。</p>
      <p>（1）“服务” 是指公司运营的“ COSPO”提供的所有服务。</p>
      <p>（2）“用户” 是指按照公司规定的程序批准了本协议并申请使用本服务的人或获得公司同意的人，并且已经批准了公司的用户注册的人。我会。</p>
      <p>（3）“用户信息”这是公司要求以初始指定格式注册或提供的信息，这是用户使用此服务所必需的。</p>
      <p>（4）“用户内容”指在服务内发送，发布，注册，显示等的所有信息（在下文中，这些行为统称为“符号”）。</p>
      <p>（5）“提供的内容”是指公司提供给用户的使用该服务的信息（包括用户内容），例如照片，图像，视频，句子，声音，项目和其他信息。</p>
      <p>（6）“积分”可以兑换为有偿服务或公司在此服务中指定的其他内容的积分。</p>
      <p>&nbsp;</p>
      <p>
        第三条（协议，适用范围）<br>
        1。用户必须根据本协议的规定使用此服务。<br>
        2。除非将本公司提供的应用程序“ COSPO”下载到智能手机或其他信息终端上，并且执行了同意本协议的程序，否则用户无法使用该服务。<br>
        3。在提供此服务时，分别制定的条款中描述的信息（以下称为“相关规则”），公司实施的使用条件通知，与该服务有关的帮助以及发送给用户的通知等。如果通知，通知等的内容与本协议的规定不同，则以通知，通知等的提供为准。
      </p>
      <p>&nbsp;</p>
      <p>
        第4条（用户信息的注册）<br>
        1。希望成为用户的用户在使用本服务后接受本协议后，应按照我公司指定的程序执行用户注册程序。<br>
        2。如果您是未成年人，则必须获得法律代表的同意（包括您对这些条款的同意）。<br>
        3。执行用户注册程序时，公司应根据公司规定的认证程序注册用户信息并授予帐户。但是，我们公司在认为不合适时可以拒绝用户注册，在这种情况下，我们没有义务向执行注册程序的人透露拒绝的原因。<br>
        4。通过授予前款规定的帐户，该服务的使用合同应在用户与我公司之间订立。另外，如果我们确定不适当，甚至可以在授予帐户后取消帐户，并取消使用该服务的合同。但是，我们不承担任何提供撤回同意理由的义务。
      </p>
      <p>&nbsp;</p>
      <p>
        第5条（变更通知）<br>
        1。更改用户信息时，用户应及时更改注册信息。<br>
        2。即使用户信息有误或不正确，或者由于前款未通知用户给用户带来任何不利影响，本公司概不负责。
      </p>
      <p>&nbsp;</p>
      <p>
        第六条（删除账户等）
        1。如果用户属于以下任何一项，或者公司确定不合适，则公司将暂停或限制用户使用该服务以及删除帐户，恕不另行通知。应该可以采取诸如取消使用合同之类的措施。公司没有义务披露采取此类措施的原因。<br>
        （1）当违反本协议的任何条款时<br>
        （2）如果提供给公司的信息是错误的<br>
        （3）如果用户是未成年人，成人病房，养护者或助手，法定代表人，成人监护人，未获得监护人或助手的同意时
        （4）用户死亡或监护时，开始监护或协助<br>
        （5）另外，在认定作为用户不合适或者难以继续登录的时候<br>
        （6）公司判断有其他与上述项目相似的原因时<br><br>
        2。根据前款规定删除用户注册或取消使用合同后，如果用户再次申请用户注册，我们可能不接受该申请。<br>
        3。对于公司根据本条采取的措施对用户造成的任何不利或损害，公司概不负责。
      </p>


      <p>
        第7条（用户内容的处理）<br>

        1。表达用户内容的用户对有关用户内容承担全部责任。<br><br>

        2。用户无法提供以下信息：<br>

        （1）侵犯知识产权，例如公司或其他用户或其他第三方的版权，隐私权，肖像权，公开权或其他权利或利益的知识产权<br>

        （2）诽谤其他用户，第三方或我们公司，或损害其荣誉或信誉的。<br>

        （3）其他用户，第三方或对我们不利的用户<br>

        （4）任何违反或促进法律或行政措施，或导致犯罪或<br>

        （五）违反公共秩序和道德的<br>

        （六）为反社会力量和其他合作活动提供利润<br>

        （7）提供约会，卖淫，卖淫等，或招揽他们<br>

        （8）邀请儿童（18岁以下的男人和女人）成为异性恋关系的伴侣，或邀请成人成为与孩子异性恋关系的伴侣。<br>

        （9）违反本服务的目的，例如销售，广告，广告，拉客或其他牟利目的（不包括公司认可的目的），对宗教活动或宗教团体的拉客，对政治活动的拉客等。事情<br>

        （10）使用或包含有害程序（例如计算机病毒）的程序<br>

        （11）包含过分淫秽的表情，残酷的表情或其他可能冒犯他人的东西<br>

        （12）公司认为不适当的其他事项<br><br>

        3。当用户内容违反本协议或公司认为不适当时，公司应能够删除全部或部分用户内容，恕不另行通知。公司没有义务披露采取此类措施的原因。<br>

        4。本公司可以通过复制，编辑，显示，公开传输，宣传等方式使用用户内容，以免费提供和推广使用此服务，并且用户事先同意我们。我会。<br>

        5，用户不能将用户内容许可给我们公司以外的第三方。

      </p>



      <p>
        第8条（用户帐户管理）<br>
        1。用户应自行承担管理该服务帐户的风险，不得让第三方使用，借出，转让，更改名称等。<br>
        2。用户可以使用任何电子邮件地址和密码来管理此服务的帐户。用户必须严格管理自己注册的电子邮件地址和密码，后果自负，以免被非法使用。<br>
        3。由于对电子邮件地址和密码的管理不充分，使用错误，由第三方使用等原因，用户应承担赔偿责任，我们不承担任何责任。<br>
        4。通过帐户使用此服务被视为用户的使用和行为，包括除用户本人以外的第三方的使用和行为。用户应承担使用和行为所产生的所有债务。<br>
        5，如果用户获得了未授权的电子邮件地址和密码，或者该帐户正在被第三方使用或很可能被第三方使用，则应立即通知用户，并且在公司的指示下，受。<br>
        6。对于因账户管理不足或使用第三方造成的损失，我们不承担任何责任。<br>
        7 如果通过用户的操作（例如，用户意外删除了该帐户）删除了该帐户，则用户事先同意该用户不能使用该帐户，内容和与服务相关的其他信息。
      </p>
      <p>&nbsp;</p>
      <p>
        第9条（撤回）<br>
        1。用户可以通过公司指定的退出程序退出以取消使用此服务。<br>
        2。即使您根据本条退出会员资格，也应适用前条第3至6款。<br>
        3。即使用户退出，付费服务的费用也不会退还。
      </p>
      <p>&nbsp;</p>
      <p>
        第十条（本协议的修改/变更）<br>
        1。本公司可随时根据需要更改或修订本协议及相关规定。此外，对本协议和相关法规的任何更改应在发布在本网站或本公司运营的网站上时生效，并且如果用户在更改后使用此服务，则更改后的条款应为我们认为您已同意。<br>
        2。将来，我们可能会将我们对这项服务的立场转移给将来继承这项服务业务的公司。在这种情况下，前款的规定应比照适用于地位转让程序。
      </p>
      <p>&nbsp;</p>
      <p>
        第十一条（有偿服务的销售）<br>
        1。我们可能会向用户收费提供此服务的一部分。<br>
        2。用户通过支付由公司单独发布并发布在服务上的对价，可以获得前款规定的付费服务（以下称为“付费服务”）的使用权。<br>
        3。用户不得将获取的付费服务转让，转让，出借或支付给其他用户，也不得在此服务或其他类似服务上进行描述。<br>
        4。我们可能会酌情更改付费服务的价格。
      </p>
      <p>&nbsp;</p>
      <p>
        第十二条（要点）<br>
        1。积分应通过“购买”或我们在此服务上指定的其他方法提供给用户。积分购买单位，付款方式和其他授予条件应由公司另行确定，并发布在此服务上。<br>
        2。积分只能交换或用于本公司指定的付费服务或其他内容，而不能交换或用于其他内容或服务，现金或其他经济利益。交换或使用所需的积分数量以及使用其他积分的条件应由公司另行确定，并发布在服务上。<br>
        3。我们不会出于任何原因重新发行或兑换积分。此外，积分将不予退还。<br>
        4。当公司终止或取消该服务时，或者由于根据第六条的规定删除帐户而导致用户资格丧失，用户撤回等时，剩余的积分在已知的一定时间后将消失我会。请注意，在此期间无法获得新的积分。<br>
        5，除非公司另有规定，否则用户不能将拥有的积分转让，转让或出借给其他用户。<br>
        6。用户不得将通过使用积分获得的付费服务转让，转让，出借或支付给其他用户或该服务或任何其他类似服务。
      </p>
      <p>&nbsp;</p>
      <p>
        第十三条（使用费）<br>
        1。用户应将公司单独发布在该服务上的使用费确定为使用前两条中指定的付费服务的对价以及通过公司指定的付款方式获得付费点的对价。我会付钱。但是，经我公司批准，用户可以按等额积分支付使用费。<br>
        2。除非法律要求退款，否则我们不会以任何理由退还您支付的使用费。<br>
        3。未成年人在购买付费服务或购买积分之前必须获得法律代表的同意。
      </p>
      <p>&nbsp;</p>
      <p>
        第十四条（奖励）<br>
        1。公司应根据用户内容的内容，用户内容的行为以及根据公司设定的标准进行的其他评估，向描述了用户内容的用户付款。补偿的评估标准和支付方式由公司确定，公司可以酌情更改。<br>
        2。如果认为支付奖励不适当，例如用户违反了此协议，我们将不向用户支付奖励。另外，在这种情况下，可以要求退还已经支付的奖励。
      </p>

      <p>&nbsp;</p>

      <p>
        第十五条（权利的归属）<br>
        1。与该服务，提供的内容和用户内容有关的版权和所有其他权利属于公司或向公司授予许可或被许可提供服务的权利持有者。<br>
        2。公司授予用户非排他性和私人使用所提供内容的权利，这些内容不能出于使用此服务以及本公司管理的WEB网站和SNS网站的目的而转让或再许可。<br>
        3。用户不得通过复制，改编，公开传输或任何其他方式使用提供的内容。<br>
        4。由于用户自己使用此服务，因此他/她可以自行决定在此服务中注册用户个人资料。<br>
        5，如果用户内容和用户资料包括他人拥有的知识产权，则用户应与权利持有人进行与权利许可有关的程序。<br>
        6。因用户违反前三款规定而引起的任何问题，用户应自负费用解决问题，不会给公司造成任何不便或损害。我会。
      <p>&nbsp;</p>
      <p>
        第十六条（禁止）
        1。使用此服务时，用户不得执行以下操作。<br>
        （1）协助约会，卖淫，卖淫等，或诱使他们<br>
        （2）诱使儿童（18岁以下的男女）成为异性恋伴侣或成人要求与孩子成为异性恋伴侣的行为<br>
        （3）侵犯知识产权，例如公司或其他用户或其他第三方的版权，隐私，肖像权，宣传权和其他权利或利益可能构成侵权的行为<br>
        （4）诽谤其他用户，第三方或我们公司的行为，或损害我们的声誉或信誉的行为<br>
        （5）不利于其他用户，第三方或我们公司的行为给予行为<br>
        （6）违反或促进法律或行政措施或导致犯罪的行为<br>
        （7）违反公共秩序和道德行为<br>
        （8）向反社会势力或其他合作活动提供利润<br>
        （9）销售和广告，广告，招揽或其他商业活动（不包括公司认可的活动），招揽宗教活动或宗教团体，招揽政治活动等。使用行为<br>
        （10）干扰其他用户使用该服务的行为<br>
        （11）通过冒充其他用户或第三方来使用该服务的行为<br>
        （12）通过该服务获得的个人向第三方提供信息等的行为<br>
        （13）用户之间 互惠互利的行为<br>
        （14）伪造或非法擦除本服务可以使用的信息的行为<br>
        （15）企图非法访问与该服务连接的其他计算机系统或网络<br>
        （16）使用或提供有害程序（例如计算机病毒）的行为或推荐这些行为的行为<br>
        （17）在对源代码进行分析之后，对本服务或用于本服务的软件进行修改，变更，许可，翻译，销售，仿制，解码，反编译<br>
        （18）除服务外，还有干扰公司运营的服务的行为（20）导致指向该服务以外的站点或服务的行为，例如指向外部站点的链接<br>
        （19）包含过分淫秽言论的信息，包含可识别其他用户的个人信息的残酷言论或可能冒犯他人的其他信息，或公司认为不适当的任何其他信息发送/发布到服务的行为<br>
        （20）引导到该服务以外的站点或服务的行为，例如到外部站点的链接<br>
        （21）促进前述各项规定的行为<br>
        （22）前述各项规定的行为或可疑的行为<br>
        （23）公司认为不适当的其他行为<br><br>
        2。用户不得转让，出售，出借，提供抵押品或任何其他方式处置用户的全部或部分权利。
      </p>
      <p>&nbsp;</p>
      <p>
        第十七条（本服务的变更，中止，终止）<br>
        1。我们可以更改或添加此服务的一部分或全部，而无需事先通知用户。<br>
        2。如果发生以下情况之一，公司有权自行决定中止本服务的全部或部分运作。<br>
        （1）如果由于不可抗力如战争，骚乱，混乱，劳资纠纷，自然灾害，地震，喷发，洪水，海啸，火灾或停电等原因无法正常提供此服务。<br>
        （2）在进行紧急维护检查时，由于对该服务的运行需要对系统进行临时或大规模维护，电信设备故障，提供商故障，系统故障等。<br>
        （3）公司认为有必要时，例如不可避免的原因时。<br><br>
        3。如果我们根据上一段暂停全部或部分服务，我们将通过我们认为适当的方法提前通知您。但是，这不适用于紧急情况。<br>
        4。公司应能够通过以公司认为适当的方式提前通知用户来终止此服务的提供。<br>
        5，由于任何原因，即使用户由于前款的规定遭受任何不利影响，我公司也不承担任何赔偿责任。
      </p>
      <p>&nbsp;</p>
      <p>
        第18条（设备等）<br>
        1。当公司发现公司的设施或该服务的运营设施发生故障或丢失时，公司应努力及时维修或恢复设施。在这种情况下，即使用户或第三方受到损害，我们也不承担任何责任。<br>
        2。用户准备，安装和操作使用此服务所需的通信设备，软件和其他设备，通信线路和其他连接环境，并自行承担费用提供该服务。会做。
      </p>
      <p>&nbsp;</p>
      <p>
        第十九条（个人信息的处理）<br>
        1。除法律要求外，未经用户个人事先同意，我们不会向第三方披露或提供用户信息中包含的个人信息。但是，以下情况不包括在内。<br>
        （1）根据《个人信息保护法》的规定，例如基于法律法规的情况，或者从法院或警察等公共机构收到法律要求时。<br>
        （2）获得用户的事先批准和同意。<br>
        （3）披露给提供这项服务所必需的分包商。<br>
        （4）当通过合并成功进行业务时，业务的分拆或转移以及用户信息成功。<br><br>
        2.公司应能够通过复制或其他方式将用户信息免费用于以下目的，并提供给第三方，并且用户同意。<br>
        （1）进行和提供<br>
        此服务a。扩展和改善此服务，进行新开发的研究/分析<br>
        b。回应查询和支持<br>
        c。用于其他目的，但必须得到客户的同意对于<br>
        （2）创建与此服务的使用状态相关的统计信息，并将其发布在该服务或我们公司运营的网站上。<br>
        3。我们没有义务保存用户注册信息。您负责备份必要的用户信息和用户配置文件。<br>
        4。当用户退出服务或服务的操作被用户终止时，我们将能够自行决定删除所有用户注册信息，并且我们将不响应诸如退回之类的请求。 .. 此外，我们对用户造成的不利，损坏等不承担任何责任。<br>
        5，用户需要向我们提供个人信息。用户有责任决定是否注册个人信息。但是，如果我们无法提供在提供此服务时要求的个人信息，或者提供的个人信息不完整，则我们将无法以获取个人信息为目的提供服务。有情况。<br>
        6。此服务可能使用cookie。这是为了检查用户的网站，应用程序和广告的浏览状态，以改善此服务并分析使用状态，并用作统计营销信息。Cookie中不包含任何个人信息。此外，我们不会获取个人信息或将其与用户信息相关联。此外，此服务使用以下企业提供的信息收集模块来自动通过企业获取信息。
      </p>
      <p>——————–<br>
        a。服务名称：Google Analystics<br>
        提供商名称：Google Inc.<br>
        要获取的信息项：HTTP cookie，IP地址，浏览器环境变量，IDFA，UUID，OS<br>
        使用目的：此服务改进和使用状态分析，<br>
        由第三方提供：无<br>
        http://www.google.com/intl/ja/policies/privacy/<br>
        ——————–<br>
        7。我们将响应通知，披露，更正，添加，删除，中止使用或中止用户提供的个人信息的第三方提供的请求。有关个人信息处理的查询和投诉，请联系以下查询。<br>
        KAWAII JAPAN Co.，Ltd.东京都江东区青海2-7-4 SOHO 706 135-0064
      </p>
      <p>电话：03-5530-7056</p>
      <p>电子邮件 :<a href="mailto:info@kawaiijapan.co.jp">info@kawaiijapan.co.jp</a></p>
      <p>
        第20条（免责声明）<br>
        1。我们公司不保证本服务的内容，本服务提供的所有信息，数据等，包括其完整性，准确性，合法性和适用性，我们不承担任何责任。<br>
        2。本公司不保证本公司通过此服务提供的所有信息，数据等或用户发布的所有信息，数据等将被安全地存储，并且此类信息，数据等将丢失。但是，我们不承担任何责任。<br>
        3。本公司不得因本服务的提供，延迟，变更，取消或终止，通过本服务提供或提供的信息等的丢失或消失以及与本服务有关的其他损害而对用户和第三方造成任何损害。不负责<br>
        4。如果用户因使用此服务而对另一用户或第三方造成损害，或者如果用户与另一用户或第三方有争议，则用户将自费。我们将对解决方案负责，不会给我们的公司带来任何不便或损害。此外，如果用户因使用此服务而对我们造成损害，则用户应赔偿对我们造成的损害。<br>
        5，我们不保证提供此服务的计算机系统的运行状态，对于由任何原因（例如，计算机系统无法提供此服务）引起的任何损害，我们概不负责。<br>
        6。对于使用此服务，从本站点下载，计算机病毒感染等对您的计算机，外围设备，线路，软件等造成的任何损害，我们概不负责。<br>
        7 即使我们根据本协议对用户造成的损害负责，但除非公司有意或重大过失，否则我们应直接对用户造成的损害范围应由用户承担。仅限于损害赔偿。
      </p>
      <p>&nbsp;</p>
      <p>
        第21条（管辖法院） <br>
        1。如果您和我们之间在此服务方面出现问题，我们将真诚地与您讨论。<br>
        2。如果即使经过协商仍未解决此案，则东京地方法院将是一审专属协议管辖法院。</p>
      <p>&nbsp;</p>
      <p>
        第二十二条（适用法律）<br>
        本协议的适用法律为日本法律。</p>
      <p>
        补充<br>
        规则本协议自2017年2月14日起实施。<br>
        修订于1月1日，Reiwa
      </p>
      <p>&nbsp;</p>
    </div>
  </ng-container>
  <ng-container *ngIf="lang==='zh-TW'">
    <h2 class="cospo-title">COSPO服務條款</h2>
    <div class="entry entry-content">
      <p>&nbsp;</p>
      <h1>COSPO服務條款</h1>
      <p>
        第1條（目的）<br>
        本協議是用戶（在第2條中定義）使用KAWAII JAPAN Co.，Ltd.（以下簡稱“公司”）提供的“ COSPO”服務的條件。是確定的。
      </p>
      <p>&nbsp;</p>
      <p>（1）“服務” 是指公司運營的“ COSPO”提供的所有服務。</p>
      <p>（2）“用戶”是 指已同意本協議的人，但不是按照公司規定的程序申請使用該服務的個人或獲得公司同意的人以外的人，並且已經批准了公司的用戶註冊。我會。</p>
      <p>（3）“用戶信息” 這是公司要求以初始指定格式註冊或提供的信息，這是用戶使用此服務所必需的。</p>
      <p>（4）“用戶內容” 指在服務內發送，發布，註冊，顯示等的所有信息（在下文中，這些行為統稱為“符號”）。</p>
      <p>（5）“提供的內容” 是指公司提供給用戶的使用該服務的信息（包括用戶內容），例如照片，圖像，視頻，句子，聲音，項目和其他信息。</p>
      <p>（6）“積分” 可以兌換為有償服務或公司在此服務中指定的其他內容的積分。</p>
      <p>&nbsp;</p>
      <p>
        第三條（協議，適用範圍）<br>
        1。用戶必鬚根據本協議的規定使用此服務。<br>
        2。除非將本公司提供的應用程序“ COSPO”下載到智能手機或其他信息終端上，並且執行同意本協議的程序，否則用戶無法使用該服務。<br>
        3。在提供此服務時，將在條款，公司實施的使用條件通知，有關此服務的幫助以及發送給用戶的通知方面分別描述的信息（以下稱為“相關規則”）（以下稱為“相關法規”）如果此類通知，通知等的內容與本協議的規定不同，則以此類通知，通知等的規定為準。
      </p>
      <p>&nbsp;</p>
      <p>
        第4條（用戶信息的註冊）<br>
        1。希望成為用戶的用戶在使用本服務後接受本協議後，應按照我公司指定的程序執行用戶註冊程序。<br>
        2。如果您是未成年人，則必須獲得法律代表的同意（包括您對這些條款的同意）。<br>
        3。執行用戶註冊程序時，公司應根據公司規定的認證程序註冊用戶信息並授予帳戶。但是，我們公司在認為不合適時可以拒絕用戶註冊，在這種情況下，我們沒有義務向執行註冊程序的人透露拒絕的原因。<br>
        4。通過授予前款規定的帳戶，該服務的使用合同應在用戶與我公司之間訂立。另外，如果我們確定不適當，甚至可以在授予帳戶後取消帳戶，並取消使用該服務的合同。但是，我們不承擔任何提供撤回同意理由的義務。
      </p>
      <p>&nbsp;</p>
      <p>
        第5條（變更通知）<br>
        1。更改用戶信息時，用戶應及時更改註冊信息。<br>
        2。即使用戶信息有誤或不正確，或者由於前款未通知用戶給用戶帶來任何不利影響，本公司概不負責。
      </p>
      <p>&nbsp;</p>
      <p>
        第六條（刪除賬戶等）<br>
        1。如果用戶屬於以下任何一項，或者公司確定不合適，則公司將暫停或限制用戶使用該服務以及刪除帳戶，恕不另行通知。應該可以採取諸如取消使用合同之類的措施。公司沒有義務披露採取此類措施的原因。）<br>
        （1）當違反本協議的任何條款時）<br>
        （2）如果提供給公司的信息是錯誤的）<br>
        （3）如果用戶是未成年人，成人病房，受保護的人或助手，法定代表人，成人監護人，未獲得 監護人或助手的同意時）<br>
        （4）用戶死亡或監護時，開始監護或協助）<br>
        （5）另外，在認定作為用戶不合適或者難以繼續登錄的時候）<br>
        （6）公司判斷有其他與上述項目相似的原因時）<br>）<br>
        2。根據前款規定刪除用戶註冊或取消使用合同後，如果用戶再次申請用戶註冊，我們可能不接受該申請。）<br>
        3。對於公司根據本條採取的措施對用戶造成的任何不利或損害，公司概不負責
      </p>


      <p>
        第7條（用戶內容的處理）<br>

        1。表達用戶內容的用戶對有關用戶內容承擔全部責任。<br>

        2。用戶無法提供以下信息：<br>

        （1）侵犯知識產權，例如公司或其他用戶或其他第三方的版權，隱私權，肖像權，公開權或其他權利或利益的知識產權<br>

        （2）誹謗其他用戶，第三方或我們公司，或損害其榮譽或信譽的。<br>

        （3）其他用戶，第三方或對我們不利的用戶<br>

        （4）任何違反或促進法律或行政措施，或導致犯罪或<br>

        （五）違反公共秩序和道德的<br>

        （六）為反社會力量和其他合作活動提供利潤<br>

        （7）提供約會，賣淫，賣淫等，或招攬他們<br>

        （8）邀請兒童（18歲以下的男人和女人）成為異性戀關係的伴侶，或邀請成人成為與孩子異性戀關係的伴侶。<br>

        （9）違反本服務的目的，例如銷售，廣告，廣告，拉客或任何其他牟利目的（不包括公司認可的目的），對宗教活動或宗教團體的拉客，對政治活動的拉客等。事情<br>

        （10）使用或包含有害程序（例如計算機病毒）的程序<br>

        （11）那些包含過分淫穢的表情，殘酷的表情或可能冒犯他人的表情。<br>

        （12）公司認為不適當的其他事項<br><br>

        3。當用戶內容違反本協議或公司認為不適當時，公司應能夠刪除全部或部分用戶內容，恕不另行通知。公司沒有義務披露採取此類措施的原因。<br>

        4。本公司可以通過複製，編輯，顯示，公開傳輸，宣傳等方式使用用戶內容，以免費提供和推廣使用此服務，並且用戶事先同意我們。我會。<br>

        5，用戶不能將用戶內容許可給我們公司以外的第三方。<br>

      </p>

      <p>
        第8條（用戶帳戶管理）<br>
        1。用戶應自行承擔管理該服務帳戶的風險，不得讓第三方使用，借出，轉讓，更改名稱等。<br>
        2。用戶可以使用任何電子郵件地址和密碼來管理此服務的帳戶。用戶必須嚴格管理自己註冊的電子郵件地址和密碼，並自行承擔風險，以免被非法使用。<br>
        3。由於對電子郵件地址和密碼的管理不充分，使用錯誤，由第三方使用等原因，用戶應承擔賠償責任，我們不承擔任何責任。<br>
        4。通過帳戶使用此服務被視為用戶的使用和行為，包括除用戶本人以外的第三方的使用和行為。用戶應承擔使用和行為所產生的所有債務。<br>
        5，如果電子郵件地址和密碼獲取不正確，或者該帳戶正在被第三方使用或可能被第三方使用，則用戶應立即與我們聯繫，如果我們指示，受。<br>
        6。對於因賬戶管理不足或使用第三方造成的損失，我們不承擔任何責任。<br>
        7 如果用戶誤刪除帳戶（例如誤刪除帳戶），則用戶事先同意用戶將無法使用該帳戶，內容和與服務相關的其他信息。
      </p>
      <p>&nbsp;</p>
      <p>
        第9條（撤回）<br>
        1。用戶可以通過公司指定的退出程序退出以取消使用此服務。<br>
        2。即使您根據本條退出會員資格，也應適用前條第3至6款。<br>
        3。即使用戶退出，付費服務的費用也不會退還。
      </p>
      <p>&nbsp;</p>
      <p>
        第十條（本協議的修改/變更）<br>
        1。我公司可以根據需要隨時更改或修訂本協議和相關法規。此外，對本協議和相關法規的任何更改應在發佈在本網站或本公司運營的網站上時生效，並且如果用戶在更改後使用此服務，則更改後的條款應為我們認為您已同意。<br>
        2。將來，我們可能會將我們對這項服務的立場轉移給將來繼承這項服務業務的公司。在這種情況下，前款的規定應比照適用於地位轉讓程序。
      </p>
      <p>&nbsp;</p>
      <p>
        第十一條（有償服務的銷售）<br>
        1。我們可能會向用戶收費提供此服務的一部分。<br>
        2。用戶通過支付由公司單獨發布並發佈在服務上的對價，可以獲得前款規定的付費服務（以下稱為“付費服務”）的使用權。<br>
        3。用戶不得將獲取的付費服務轉讓，轉讓，出借或支付給其他用戶，也不得在此服務或其他類似服務上進行描述。<br>
        4。我們可能會酌情更改付費服務的價格。
      </p>
      <p>&nbsp;</p>
      <p>
        第十二條（要點）<br>
        1。積分應通過“購買”或我們在此服務上指定的其他方法提供給用戶。積分購買單位，付款方式和其他授予條件應由公司另行確定，並發佈在此服務上。<br>
        2。積分只能交換或用於本公司指定的付費服務或其他內容，而不能交換或用於其他內容或服務，現金或其他經濟利益。交換或使用所需的積分數量以及使用其他積分的條件應由公司另行確定，並發佈在服務上。<br>
        3。我們不會出於任何原因重新發行或兌換積分。此外，積分將不予退還。<br>
        4。如果公司終止或取消此服務，或者根據第6條的規定，由於刪除帳戶，從用戶取款等原因導致用戶資格喪失，則剩餘的積分將在一定的已知期限後消失。我會。請注意，在此期間無法獲得新的積分。<br>
        5，除非公司另有規定，否則用戶不能將擁有的積分轉讓，轉讓或出借給其他用戶。<br>
        6。用戶不得將通過使用積分獲得的付費服務轉讓，轉讓，出借或支付給其他用戶或該服務或任何其他類似服務。
      </p>
      <p>&nbsp;</p>
      <p>
        第十三條（使用費）<br>
        1。用戶應按照本公司指定的付款方式，分別將支付給該服務的使用費支付給本公司，作為使用前兩項規定的有償服務的對價和獲取積分的對價。我會付錢。但是，經我公司批准，用戶可以按等額積分支付使用費。<br>
        2。除非法律要求退款，否則我們不會以任何理由退還您支付的使用費。<br>
        3。未成年人在購買有償服務或購買積分之前，必須獲得法律代表的同意。
      </p>
      <p>&nbsp;</p>
      <p>
        第十四條（獎勵）<br>
        1。公司應根據用戶內容的內容，用戶內容的行為以及根據公司設定的標准進行的其他評估，向描述了用戶內容的用戶付款。薪酬的考核標準和支付方式由公司確定，公司可以適當調整。<br>
        2。如果認為支付獎勵不適當，例如用戶違反了此協議，我們將不向用戶支付獎勵。另外，在這種情況下，可以要求退還已經支付的獎勵。
      </p>

      <p>&nbsp;</p>

      <p>
        第十五條（權利的歸屬）<br>
        1。與本服務，提供的內容和用戶內容有關的版權和所有其他權利屬於我們或為我們授予許可或授予我們提供此服務的權利持有者。<br>
        2。公司授予用戶非排他性和私人使用所提供內容的權利，這些內容不能出於使用此服務以及本公司管理的WEB網站和SNS網站的目的而轉讓或再許可。<br>
        3。用戶不得通過複製，改編，公開傳輸或任何其他方式使用提供的內容。<br>
        4。由於用戶自己使用該服務，因此他/她可以自行決定在該服務中註冊用戶資料。<br>
        5，如果用戶內容和用戶配置文件包含他人擁有的知識產權，則用戶應與權利持有人執行與許可協議有關的程序。<br>
        6。因用戶違反前三款規定而引起的任何問題，用戶應自負費用解決問題，不會給公司造成任何不便或損害。我會。
      <p>&nbsp;</p>
      <p>
        第十六條（禁止）<br>
        1。使用此服務時，用戶不得執行以下操作。<br>
        （1）協助約會，賣淫，賣淫等，或誘使他們<br>
        （2）誘使兒童（18歲以下的男女）成為異性戀伴侶或成人要求與孩子成為異性戀伴侶的行為<br>
        （3）侵犯知識產權，例如公司或其他用戶和其他第三方的版權，隱私，肖像權，宣傳權以及其他權利或利益可能構成侵權的行為<br>
        （4）誹謗其他用戶，第三方或我們公司的行為，或損害我們的聲譽或信譽的行為<br>
        （5）不利於其他用戶，第三方或我們公司的行為採取的行動<br>
        （6）違反或促進法律或行政措施或導致犯罪的 行動<br>
        （7）違反公共秩序和道德的行動<br>
        （8）為反社會勢力或其他合作行為提供利潤<br>
        （9）銷售和廣告，廣告，招攬或其他商業活動（不包括公司認可的活動），招攬宗教活動或宗教團體，招攬政治活動等。<br>
        （10）的行為與由其他用戶使用該服務的干擾<br>
        （11）使用由該服務的行為冒充其他用戶或第三者 通過此服務獲取<br>
        （12）的個人向第三方提供信息的行為<br>
        （13）用戶之間 互惠互利的行為<br>
        （14）偽造或非法擦除該服務可以使用的信息的行為<br>
        （15）企圖非法訪問與此服務連接的其他計算機系統或網絡<br>
        （16）使用或提供有害程序（例如計算機病毒）的行為，或推薦這些行為的行為<br>
        （17）在分析了本服務或本服務所使用的軟件的源代碼之後，對其進行了修改，變更，使用，翻譯，銷售，模仿，解碼，反編譯的許可<br>
        （18）除服務外，還有乾擾公司運營的服務的行為<br>
        （20）導致指向該服務以外的站點或服務的行為，例如指向外部站點的鏈接<br>
        （19）包含過分淫穢言論的信息，包含可識別其他用戶的個人信息的殘酷言論或可能冒犯他人的其他信息，或公司認為不適當的任何其他信息發送/發佈到服務的
        行為<br>
        （20）引導到該服務以外的站點或服務的行為，例如到外部站點的鏈接<br>
        （21）促進前述各項規定的行為<br>
        （22）前述各項規定的行為或可疑的行為<br>
        （23）公司認為不適當的其他行為<br><br>
        2。用戶不得轉讓，出售，出借，提供抵押品或任何其他方式處置用戶的全部或部分權利。
      </p>
      <p>&nbsp;</p>
      <p>
        第十七條（本服務的變更，中止，終止）<br>
        1。我們可以更改或添加此服務的一部分或全部，而無需事先通知用戶。<br>
        2。如果發生以下情況之一，公司有權自行決定中止本服務的全部或部分運作。<br>
        （1）如果由於不可抗力如戰爭，騷亂，混亂，勞資糾紛，自然災害，地震，噴發，洪水，海嘯，火災或停電等原因無法正常提供此服務。<br>
        （2）在進行緊急維護檢查時，由於對該服務的運行需要對系統進行臨時或大規模維護，電信設備故障，提供商故障，系統故障等。<br>
        （3）公司認為有必要時，例如不可避免的原因時。<br>
        3。如果我們根據上一段暫停全部或部分服務，我們將通過我們認為適當的方法提前通知您。但是，這不適用於緊急情況。<br>
        4。公司應能夠通過以公司認為適當的方式提前通知用戶來終止此服務的提供。<br>
        5，由於任何原因，即使用戶由於前款的規定遭受任何不利影響，我公司也不承擔任何賠償責任。
      </p>
      <p>&nbsp;</p>
      <p>
        第18條（設備等）<br>
        1。當公司發現公司的設施或該服務的運營設施發生故障或丟失時，公司應努力及時維修或恢復設施。在這種情況下，即使用戶或第三方受到損害，我們也不承擔任何責任。<br>
        2。用戶準備，安裝和操作使用此服務所需的通信設備，軟件和其他設備，通信線路和其他連接環境，並自行承擔費用提供該服務。會做。
      </p>
      <p>&nbsp;</p>
      <p>
        第十九條（個人信息的處理）<br>
        1。除非法律另有要求，否則未經用戶本人事先同意，公司不會向第三方披露或提供用戶信息中包含的個人信息。但是，以下情況不包括在內。<br>
        （1）根據《個人信息保護法》的規定，例如基於法律法規的情況，或者從法院或警察等公共機構收到法律要求時。<br>
        （2）獲得用戶的事先批准和同意。<br>
        （3）披露給提供這項服務所必需的分包商。<br>
        （4）當通過合併成功進行業務時，業務的分拆或轉移以及用戶信息成功。<br>
        2.公司應能夠通過複製或其他方式將用戶信息免費用於以下目的，並提供給第三方，並且用戶同意。<br>
        （1）進行和提供 此服務a。研究和分析此服務的擴展，改進和新發展<br>
        b。響應查詢和支持<br>
        c。在客戶同意下用於其他目的對於<br>
        （2）創建與此服務的使用狀態相關的統計信息，並將其發佈在該服務或我們公司運營的網站上。<br>
        3。我們沒有義務保存用戶註冊信息。您負責備份必要的用戶信息和用戶配置文件。<br>
        4。當用戶退出服務或該服務的操作被用戶終止時，我們將能夠自行決定刪除所有用戶註冊信息，並且我們將不響應諸如退回之類的請求。 .. 此外，我們對用戶造成的不利，損壞等不承擔任何責任。<br>
        5，用戶需要向我們提供個人信息。用戶有責任決定是否註冊個人信息。但是，如果我們無法提供在提供此服務時要求的個人信息，或者提供的個人信息不完整，我們將無法以獲取個人信息為目的提供服務。有情況。<br>
        6。此服務可能使用cookie。這是為了檢查用戶的網站，應用程序和廣告的瀏覽狀態，以改善此服務並分析使用狀態，並用作統計營銷信息。Cookie中不包含任何個人信息。此外，我們不會獲取個人信息或將其與用戶信息相關聯。此外，此服務使用以下企業提供的信息收集模塊來自動通過企業獲取信息。
      </p>
      <p>——————–<br>
        a。服務名稱：Google Analystics<br>
        提供商名稱：Google Inc.<br>
        要獲取的信息項：HTTP cookie，IP地址和瀏覽器環境變量，IDFA，UUID，操作系統<br>
        使用目的：此服務改進和使用狀態分析，<br>
        由第三方提供：無<br>
        http://www.google.com/intl/ja/policies/privacy/<br>
        ——————–<br>
        7。我們將響應通知，披露，更正，添加，刪除，中止使用或中止用戶提供的個人信息的第三方提供的請求。有關個人信息處理的查詢和投訴，請聯繫以下查詢。<br>
        KAWAII JAPAN Co.，Ltd.东京都江东区青海2-7-4 SOHO 706 135-0064
      </p>
      <p>电话：03-5530-7056</p>
      <p>电子邮件 :<a href="mailto:info@kawaiijapan.co.jp">info@kawaiijapan.co.jp</a></p>
      <p>
        第20條（免責聲明）<br>
        1。本公司不保證本服務的內容，本服務提供的所有信息和數據的完整性，準確性，合法性和適用性，並且我們不承擔任何責任。<br>
        2。我們不保證本服務提供的公司提供的所有信息，數據等或用戶發布的所有信息，數據等將被安全保存，並且此類信息，數據等將丟失。但是，我們不承擔任何責任。<br>
        3。對於因本服務的提供，延遲，變更，取消或終止，通過本服務提供或提供的信息的丟失或丟失等對用戶和第三方造成的任何損害，本公司概不負責。不負責<br>
        4。如果用戶因使用此服務而對另一用戶或第三方造成損害，或者如果用戶與另一用戶或第三方有爭議，則用戶將自費。我們將對解決方案負責，不會給我們的公司帶來任何不便或損害。此外，如果用戶因使用此服務而對我們造成損害，則用戶應賠償對我們造成的損害。<br>
        5，我們不保證提供此服務的計算機系統的運行狀態，對於由任何原因（例如，計算機系統無法提供此服務）引起的任何損害，我們概不負責。<br>
        6。對於使用此服務，從本站點下載，計算機病毒感染等對您的計算機，外圍設備，線路，軟件等造成的任何損害，我們概不負責。<br>
        7 即使公司根據這些條款對用戶承擔賠償責任，除非公司有意或嚴重失職，否則公司應直接對用戶實際支付賠償金的範圍。僅限於損害賠償。
      </p>
      <p>&nbsp;</p>
      <p>
        第21條（管轄法院）<br>
        1。如果您和我們之間在此服務方面出現問題，我們將真誠地與您討論。<br>
        2。如果即使經過協商仍未解決此案，則東京地方法院將是一審專屬協議管轄法院。
      <p>&nbsp;</p>
      <p>
        第二十二條（適用法律）<br>
        本協議的適用法律為日本法律。
      </p>
      <p>
        補充
        規則本協議自2017年2月14日起實施。<br>
        修訂於1月1日，Reiwa
      </p>
      <p>&nbsp;</p>
    </div>
  </ng-container>
</article>
