import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map } from "rxjs/operators";
import * as playPostAction from '../actions';
import { CacheCospoService } from "../services/cache.service";
@Injectable()
export class PlayerPostEffect {
  constructor(private api: CacheCospoService, private action: Actions) { }

  @Effect()
  fetchGoldPost$ = this.action.pipe(
    ofType(playPostAction.FETCH_GOLD_POST),
    mergeMap(() => this.api.getGoldPost(0)
      .pipe(
        map((json: any) => new playPostAction.FetchGoldPostSuccess(json.existNext, json.content))
      ))
  )
  @Effect()
  fetchGoldPostMore$ = this.action.pipe(
    ofType(playPostAction.FETCH_GOLD_POST_MORE),
    mergeMap((payload: any) => this.api.getGoldPost(payload.currentPage)
      .pipe(
        map((json: any) => new playPostAction.FetchGoldPostMoreSuccess(json.existNext, json.content))
      ))
  )

  @Effect()
  fetchSilverPost$ = this.action.pipe(
    ofType(playPostAction.FETCH_SILVER_POST),
    mergeMap(() => this.api.getSliverPost(0)
      .pipe(
        map((json: any) => new playPostAction.FetchSilverPostSuccess(json.existNext, json.content))
      ))
  )
  @Effect()
  fetchSliverPostMore$ = this.action.pipe(
    ofType(playPostAction.FETCH_SILVER_POST_MORE),
    mergeMap((payload: any) => this.api.getSliverPost(payload.currentPage)
      .pipe(
        map((json: any) => new playPostAction.FetchSilverPostMoreSuccess(json.existNext, json.content))
      ))
  )

  @Effect()
  fetchVtuberPost$ = this.action.pipe(
    ofType(playPostAction.FETCH_VTUBER_POST),
    mergeMap(() => this.api.getVtuberPost(0)
      .pipe(
        map((json: any) => new playPostAction.FetchVtuberPostSuccess(json.existNext, json.content))
      ))
  )
  @Effect()
  fetchVtuberPostMore$ = this.action.pipe(
    ofType(playPostAction.FETCH_VTUBER_POST_MORE),
    mergeMap((payload: any) => this.api.getVtuberPost(payload.currentPage)
      .pipe(
        map((json: any) => new playPostAction.FetchVtuberPostMoreSuccess(json.existNext, json.content))
      ))
  )


  @Effect()
  fetchHistoryPost$ = this.action.pipe(
    ofType(playPostAction.FETCH_HISTORY_WAKAII),
    mergeMap(() => this.api.getHistory(0)
      .pipe(
        map((json: any) => new playPostAction.FetchHistoryKawaiiSuccess(json.existNext, json.content))
      ))
  )
  @Effect()
  fetchHistoryPostMore$ = this.action.pipe(
    ofType(playPostAction.FETCH_HISTORY_WAKAII_MORE),
    mergeMap((payload: any) => this.api.getHistory(payload.currentPage)
      .pipe(
        map((json: any) => new playPostAction.FetchHistoryKawaiiMoreSuccess(json.existNext, json.content))
      ))
  )


  @Effect()
  fetchFavorite$ = this.action.pipe(
    ofType(playPostAction.FETCH_FAVORITE),
    mergeMap(() => this.api.getFavorite(0)
      .pipe(
        map((json: any) => new playPostAction.FetchFavoriteSuccess(json.existNext, json.content))
      ))
  )
  @Effect()
  fetchFavoriteMore$ = this.action.pipe(
    ofType(playPostAction.FETCH_FAVORITE_MORE),
    mergeMap((payload: any) => this.api.getFavorite(payload.currentPage)
      .pipe(
        map((json: any) => new playPostAction.FetchFavoriteMoreSuccess(json.existNext, json.content))
      ))
  )

  @Effect()
  searchPost$ = this.action.pipe(
    ofType(playPostAction.FETCH_SEARCH_POST),
    mergeMap((data: playPostAction.FetchSearchPost) => this.api.searchPost(data.query, 0).pipe(
      map((json: any) => new playPostAction.FetchSearchPostSuccess(json.existNext, json.content))
    ))
  )
  @Effect()
  searchPostMore$ = this.action.pipe(
    ofType(playPostAction.FETCH_SEARCH_POST_MORE),
    mergeMap((data: playPostAction.FetchSearchPostMore) => this.api.searchPost(data.query, data.currentPage).pipe(
      map((json: any) => new playPostAction.FetchSearchPostMoreSuccess(json.existNext, json.content))
    ))
  )
  @Effect()
  kwaiiPlayer$ = this.action.pipe(
    ofType(playPostAction.FETCH_KAWAII_PLAYER),
    mergeMap(() => this.api.getKawaiiPlayer().pipe(
      map((json: any) => new playPostAction.FetchKwaiiPlayerSuccess(json.content, json.existNext))
    ))
  )
  @Effect()
  playerBlocl$ = this.action.pipe(
    ofType(playPostAction.FETCH_BLOCK_COSPLAYER),
    mergeMap(() => this.api.getPlayerBlock().pipe(
      map((json: any) => new playPostAction.FetchBlockCosplayerSuccess(json.content))
    ))
  )

  @Effect()
  mypost$ = this.action.pipe(
    ofType(playPostAction.FETCH_MYPOST),
    mergeMap((payload: playPostAction.FetchMyPost) => this.api.getMyPost(payload.playerId, 0).pipe(
      map((json: any) => new playPostAction.FetchMyPostSuccess(json.content, json.existNext))
    ))
  )
  @Effect()
  mypostMore$ = this.action.pipe(
    ofType(playPostAction.FETCH_MYPOST_MORE),
    mergeMap((payload: playPostAction.FetchMyPostMore) => this.api.getMyPost(payload.playerId, payload.currentPage).pipe(
      map((json: any) => new playPostAction.FetchMyPostMoreSuccess(json.content, json.existNext))
    ))
  )
  @Effect()
  getPostDetail = this.action.pipe(
    ofType(playPostAction.FETCH_POST_DETAIL),
    mergeMap(
      (payload: playPostAction.FetchPostDetail) => this.api.postDetail(payload.postId).pipe(
        map((json: any) => new playPostAction.FetchPostDetailSuccess(json))
      ))
  )
}
