import { Injectable } from '@angular/core';
import { Session } from '../models/session';
import { Router, NavigationExtras } from '@angular/router';
import { UserReducer, getProfile } from '@app/@store/reducers/user.reduder';
import { Store } from '@ngrx/store';
import { FetchUserProfile } from '@app/@store/actions';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environments/environment';
@Injectable()
export class SessionService {
  public token: string;
  public isLayer: any;
  public session = new Session();
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private store: Store<UserReducer>,
    private cookieService: CookieService
  ) {
    // store.select(getProfile).subscribe((data:any) => {
    //   let json = JSON.parse(JSON.stringify(data));
    //   if (json) {
    //     this.session.userId = json.id;
    //     this.session.name = json.name;
    //     this.session.image = json.image;
    //     this.session.level = json.level;
    //     this.session.nextLevel = json.cheerCountNextLevel;
    //     this.session.cheerCount = json.cheerCount;
    //     this.session.cheerBar = Math.floor(json.cheerCount / json.maxStamina * 100) / 100 * 100;
    //   }
    // })
  }

  authenticated() {
    const token = this.cookieService.get('token');
    if (token) {
      this.session.login = token;
      this.store.dispatch(new FetchUserProfile());
    }
    this.session.isLayer = Number(this.cookieService.get('isLayer'));
  }

  login(token: string, isLayer: number, prevUrl?: any): void {
    this.session.login = token;
    this.session.isLayer = isLayer;
    this.setToken(token);
    this.setPlayer(isLayer.toString());
    this.store.dispatch(new FetchUserProfile());
    if (typeof prevUrl === 'string') {
      this.router.navigate([prevUrl]);
    } else if (!prevUrl) {
      this.router.navigate(['my-page']);
    } else {
      this.router.navigate(['post'], prevUrl);
    }
  }

  logout(): void {
    this.removeCookie();
    this.router.navigate(['/login']);
  }
  setToken(token) {
    this.cookieService.set('token', token, 432000, '', '', environment.production)
  }
  setPlayer(isLayer) {
    this.cookieService.set('isLayer', isLayer, 432000, '', '', environment.production)
  }
  removeCookie() {
    this.session = new Session();
    this.cookieService.deleteAll('', '', environment.production);

    const winPaypal = window.open(
      `${environment.apiUrl}/web/logout`,
      'Secure Payment');
    setTimeout(() => {
      winPaypal.close();
    }, 500)
  }
  redirectLogin(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        'error': this.translateService.instant('please_login')
      }
    };
    this.router.navigate(['/login'], navigationExtras);
  }
}
