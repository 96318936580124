import * as eventAction from '../actions/event.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface EventReducer {
  events: Array<any>;
  loading: boolean;
  eventJoins: any
}
export const initState: EventReducer = {
  events: [],
  eventJoins: [],
  loading: false
}
export function eventReducer(state = initState, action: eventAction.EventActions) {
  switch (action.type) {
    case eventAction.FETCH_EVENTS:
      return {
        ...state,
        events: state.events,
        loading: state.events.length === 0 ? true : false
      }
    case eventAction.FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.events,
        loading: false
      }
    case eventAction.FETCH_EVENTS_JOIN_SUCCESS:
      return {
        ...state,
        eventJoins: action.eventJoins
      }
    default:
      return state;
  }
}
export const events = createFeatureSelector<EventReducer>('events');
export const getEvents = createSelector(events, (state: EventReducer) => state);
