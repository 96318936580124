import { Injectable } from "@angular/core";
import { CacheCospoService } from "../services/cache.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { mergeMap, map } from "rxjs/operators";
import * as rankingActions from '../actions/ranking.action';
@Injectable()
export class RankingEffect {
  constructor(private api: CacheCospoService, private action: Actions) { }

  @Effect()
  fetchRanking$ = this.action.pipe(
    ofType(rankingActions.FETCH_RANKING),
    mergeMap(() => this.api.getRankings().pipe(
      map((json: any) => new rankingActions.FetchRankingSuccess(json.content))
    ))
  )
  @Effect()
  fetchRankingPast$ = this.action.pipe(
    ofType(rankingActions.FETCH_RANKING_PAST),
    mergeMap(() => this.api.getListPastRanking().pipe(
      map((json: any) => new rankingActions.FetchRankingPastSuccess(json.content))
    ))
  )
  @Effect()
  fetchDetailEvent$ = this.action.pipe(
    ofType(rankingActions.FETCH_DETAIL_RANKING),
    mergeMap((payload: rankingActions.FetchDetailRanking) => this.api.getDetailRanking(payload.rankingId).pipe(
      map((json: any) => new rankingActions.FetchDetailRankingSuccess(json.content))
    ))
  )

}
