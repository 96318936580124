import { Injectable } from "@angular/core";
import { CacheCospoService } from "../services/cache.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import * as eventActions from '../actions/event.action';
import { mergeMap, map } from "rxjs/operators";

@Injectable()
export class EventEffect {
    constructor(private api: CacheCospoService, private action: Actions) { }

    @Effect()
    fetchEvent$ = this.action.pipe(
        ofType(eventActions.FETCH_EVENTS),
        mergeMap(() => this.api.getEvents().pipe(
            map((json: any) => new eventActions.FetchEventsSuccess(json.rankingEvents))
        ))
    )
    @Effect()
    fetchEventJoin$ = this.action.pipe(
        ofType(eventActions.FETCH_EVENTS_JOIN),
        mergeMap(() => this.api.getEventJoins().pipe(
            map((json: any) => new eventActions.FetchEventJoinSuccess(json.content))
        ))
    )
}
