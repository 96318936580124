import { Component, OnInit, OnDestroy } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Store } from '@ngrx/store';
import { FetchPostDetail } from '@app/@store/actions';
import { getPostDetail } from '@app/@store/reducers/playerPost.reducer';
import { Subscription } from 'rxjs';
import { SessionService } from '@app/@store/services/session.service';
@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {
  post;
  post_id;
  post_ids = [];
  player_id;
  private subscription: Subscription;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private store: Store<any>,
    public sessionService: SessionService
  ) { }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  ngOnInit() {
    this.route.params.subscribe(params => { this.post_id = params['id']; });
    this.store.dispatch(new FetchPostDetail(this.post_id));
    this.subscription = this.store.select(getPostDetail).subscribe(json => {
      this.post = json;
      this.player_id = json.playerId;
    })
  }

  postDelete() {
    Swal.fire({
      title: this.translateService.instant('notify.title'),
      text: this.translateService.instant('confirm_delete_post'),
      confirmButtonText: this.translateService.instant('notify.confirm'),
      cancelButtonText: this.translateService.instant('notify.cancel'),
      showCancelButton: true,
      preConfirm: () => {
        this.post_ids = this.post_id;
        this.http.delete<any>(environment.apiUrl + '/api/playerPost' + '?playerPostIds=' + this.post_ids)
          .subscribe(() => {
            this.router.navigate(['/my-posts']);
          });
      }
    })
  }
}
