export class Session { // 追加
  login: string;
  isLayer: number;
  userId: number;
  name: string;
  image: string;
  level: number;
  nextLevel: number;
  cheerCount: number;
  cheerBar: number;
  point: number = 0;
  constructor() {
    this.login = '';
    this.isLayer = 0;
    this.userId = 0;
    this.name = '';
    this.image = '';
    this.level = 0;
    this.nextLevel = 0;
    this.cheerCount = 0;
    this.cheerBar = 0;
    this.point = 0;
  }

  reset(): Session { // 追加
    this.login = '';
    this.isLayer = 0;
    this.userId = 0;
    this.name = '';
    this.image = '';
    this.level = 0;
    this.nextLevel = 0;
    this.cheerCount = 0;
    this.cheerBar = 0;
    this.point = 0;
    return this;
  }
}
