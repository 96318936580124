<div [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
<div class="responsive-content" *ngIf="lang==='ja'" >
  <h1 class="cospo-title">退会の手続き</h1>

  <h3>
    退会と同時に<br>
    全データがリセットされます。
  </h3>

  <p>
    ※リセットした場合<br>
    他の端末でのログインや<br>
    データの復旧が出来ません
  </p>

  <div class="point">
    <b>リセットされるポイント<br>
      {{ point | number }} {{'points'|translate}}</b>
  </div>

  <form class="form" [formGroup]="withdrawalForm" (ngSubmit)="onSubmit()">
    <div class="text-center">
      <button type="submit" [disabled]="!withdrawalForm.valid" class="load-more m-t-20">退会</button>
    </div>
  </form>
</div>

<div class="responsive-content" *ngIf="lang==='en'" >
  <h1 class="cospo-title">Procedures for withdrawal</h1>

  <h3>
    At the same time as unsubscribing<br>
    All data is reset.
  </h3>

  <p>
    * If you reset<br>
    Log in to other devices<br>
    I can't recover my data
  </p>

  <div class="point">
    <b>Points to be reset<br>
      {{ point | number }} {{'points'|translate}}</b>
  </div>

  <form class="form" [formGroup]="withdrawalForm" (ngSubmit)="onSubmit()">
    <div class="text-center">
      <button type="submit" [disabled]="!withdrawalForm.valid" class="load-more m-t-20">Unsubscribe</button>
    </div>
  </form>
</div>

<div class="responsive-content" *ngIf="lang==='fr'" >
  <h1 class="cospo-title">Procédure de retrait</h1>

  <h3>
    Toutes les données seront réinitialisées dès que vous vous retirez .<br>
  </h3>

  <p>
    * En cas de réinitialisation, vous ne pourrez pas vous<br>
    connecter ni<br>
    récupérer de données sur d' autres appareils .
  </p>

  <div class="point">
    <b>Points à réinitialiser
      Points<br>
      {{ point | number }} {{'points'|translate}}</b>
  </div>

  <form class="form" [formGroup]="withdrawalForm" (ngSubmit)="onSubmit()">
    <div class="text-center">
      <button type="submit" [disabled]="!withdrawalForm.valid" class="load-more m-t-20">Retrait</button>
    </div>
  </form>
</div>

<div class="responsive-content" *ngIf="lang==='kor'" >
  <h1 class="cospo-title">탈퇴 절차</h1>

  <h3>
    탈퇴와 동시에<br>
    모든 데이터가 초기화됩니다.
  </h3>

  <p>
    ※ 재설정 한 경우<br>
    다른 기기에서 로그인 또는<br>
    데이터의 복구가 불가능합니다
  </p>

  <div class="point">
    <b>리셋되는 포인트<br>
      {{ point | number }} {{'points'|translate}}</b>
  </div>

  <form class="form" [formGroup]="withdrawalForm" (ngSubmit)="onSubmit()">
    <div class="text-center">
      <button type="submit" [disabled]="!withdrawalForm.valid" class="load-more m-t-20">탈퇴</button>
    </div>
  </form>
</div>
<div class="responsive-content" *ngIf="lang==='zh'" >
  <h1 class="cospo-title">提款程序</h1>

  <h3>
    탈퇴와 所有数据将在撤回时重置。
  </h3>

  <p>
    *如果重置，您将无法在<br>
    其他终端<br>
    上登录或恢复数据。
  </p>

  <div class="point">
    <b>重设积分<br>
      {{ point | number }} {{'points'|translate}}</b>
  </div>

  <form class="form" [formGroup]="withdrawalForm" (ngSubmit)="onSubmit()">
    <div class="text-center">
      <button type="submit" [disabled]="!withdrawalForm.valid" class="load-more m-t-20">退出</button>
    </div>
  </form>
</div>
<div class="responsive-content" *ngIf="lang==='zh-TW'" >
  <h1 class="cospo-title">提款程序</h1>

  <h3>
    탈퇴와 所有數據將在撤回時重置。
  </h3>

  <p>
    *如果重置，您將無法在<br>
    其他終端<br>
    上登錄或恢復數據。
  </p>

  <div class="point">
    <b>重設積分<br>
      {{ point | number }} {{'points'|translate}}</b>
  </div>

  <form class="form" [formGroup]="withdrawalForm" (ngSubmit)="onSubmit()">
    <div class="text-center">
      <button type="submit" [disabled]="!withdrawalForm.valid" class="load-more m-t-20">退出</button>
    </div>
  </form>
</div>

</div>
