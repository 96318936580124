import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { CorpComponent } from './pages/footer/corp/corp.component';
import { CommercialCodeComponent } from './pages/footer/commercial-code/commercial-code.component';
import { ShiketsuhouComponent } from './pages/footer/shiketsuhou/shiketsuhou.component';
import { ContactComponent } from './pages/footer/contact/contact.component';
import { RegisterThanksComponent } from './pages/authen/register-thanks/register-thanks.component';
import { FanComponent } from './pages/rankings/fan/fan.component';
import { KawaiiAnalysisComponent } from './pages/users/kawaii-analysis/kawaii-analysis.component';
import { UserMenuComponent } from './pages/users/user-menu/user-menu.component';
import { SessionService } from './@store/services/session.service';
import { UserProfileComponent } from './pages/users/user-profile/user-profile.component';
import { DetailComponent } from './pages/users/player-posts/detail/detail.component';
import { PurchaseComponent } from './pages/users/purchase/purchase.component';
import { WithdrawalComponent } from './pages/users/withdrawal/withdrawal.component';
import { WithdrawalThanksComponent } from './pages/users/withdrawal-thanks/withdrawal-thanks.component';
import { LoadingService } from './@store/services/loading.service';
import { NewsDetailComponent } from './pages/headers/new/news-detail/news-detail.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from 'src/pipes/pipe.module';
import { PolicyModule } from './pages/footer/policy/policy.module';
import { JwtInterceptor } from './@helper/jwt.interceptor';
import { AppStoreModule } from './@store/store.module';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { CookieService } from 'ngx-cookie-service';
import { CospoProgressBarModule } from './@components/progress-bar/progress-bar.module';
//material import
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { ErrorInterceptor } from './@helper/error.interceptor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PlayGuideModule } from './@components/play-guide/play-guide.module';
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    CorpComponent,
    CommercialCodeComponent,
    ShiketsuhouComponent,
    ContactComponent,
    RegisterThanksComponent,
    FanComponent,
    KawaiiAnalysisComponent,
    UserMenuComponent,
    UserProfileComponent,
    DetailComponent,
    PurchaseComponent,
    WithdrawalComponent,
    WithdrawalThanksComponent,
    NewsDetailComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    }),
    TranslateModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    OverlayModule,
    RouterModule,
    MatMenuModule,
    MatInputModule,
    AppStoreModule,
    PipeModule,
    PolicyModule,
    CospoProgressBarModule,
    MatSnackBarModule,
    FlexLayoutModule,
    PlayGuideModule
  ],

  entryComponents: [MatSpinner],
  providers: [
    CookieService,
    SessionService,
    LoadingService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
