import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayGuideComponent } from './play-guide.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
@NgModule({
  declarations: [PlayGuideComponent],
  entryComponents:[PlayGuideComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule.forChild(),
    PdfViewerModule
  ]
})
export class PlayGuideModule { }
