import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { User } from "firebase";
import { CryptService } from "@app/@store/services/crypt.service";
import { environment } from "src/environments/environment";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FetchUserProfile } from '../actions';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from './session.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Injectable({ providedIn: 'root' })
export class AuthService {
  private AES_KEY: string = environment.AES_KEY;
  private AES_IV: string = environment.AES_IV;
  constructor(
    private http: HttpClient,
    private cryptService: CryptService,
    private snackBar: MatSnackBar,
    private router: Router,
    private store: Store<any>,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private sessionService: SessionService
  ) { }
  changePointToKwaii(kwaii: number) {
    let point = this.cryptService.createAes256(kwaii.toString(), this.AES_IV, this.AES_KEY).toString().split(',');
    const params = '?echeer=' + encodeURIComponent(point[0]) + '&echeerIv=' + encodeURIComponent(point[1]);
    return this.http.post<any>(`${environment.apiUrl}/api/cheerRecovery${params}`, {})
  }
  logIn(form): Observable<any> {
    let emails = this.cryptService.createAes256(form.email, this.AES_IV, this.AES_KEY).toString().split(',');
    let passwords = this.cryptService.createAes256(form.password, this.AES_IV, this.AES_KEY).toString().split(',');
    const params = '?email=' + encodeURIComponent(emails[0]) + '&emailIv=' + encodeURIComponent(emails[1]) + '&epw=' + encodeURIComponent(passwords[0]) + '&epwIv=' + encodeURIComponent(passwords[1])
    return this.http.post<any>(`${environment.apiUrl}/api/login${params}`, {})
  }

  signUp(form): Observable<User> {
    let enames = this.cryptService.createAes256(form.name, this.AES_IV, this.AES_KEY).toString().split(',');
    let eterminalIds = this.cryptService.createAes256(this.getRndStr(20), this.AES_IV, this.AES_KEY).toString().split(',');
    let eterminalKinds = this.cryptService.createAes256(this.getRndStr(20), this.AES_IV, this.AES_KEY).toString().split(',');
    return this.http.post<any>(environment.apiUrl + '/api/first_regist' +
      '?ename=' + encodeURIComponent(enames[0]) +
      '&enameIv=' + encodeURIComponent(enames[1]) +
      '&eterminalId=' + encodeURIComponent(eterminalIds[0]) +
      '&eterminalIdIv=' + encodeURIComponent(eterminalIds[1]) +
      '&eterminalKind=' + encodeURIComponent(eterminalKinds[0]) +
      '&eterminalKindIv=' + encodeURIComponent(eterminalKinds[1])
      , form);
  }
  regist(email, password, httpOptions) {
    let emails = this.cryptService.createAes256(email, this.AES_IV, this.AES_KEY).toString().split(',');
    let passwords = this.cryptService.createAes256(password, this.AES_IV, this.AES_KEY).toString().split(',');
    return this.http.post<any>(environment.apiUrl + '/api/regist' +
      '?email=' + encodeURIComponent(emails[0]) +
      '&emailIv=' + encodeURIComponent(emails[1]) +
      '&epw=' + encodeURIComponent(passwords[0]) +
      '&epwIv=' + encodeURIComponent(passwords[1]) +
      '&webRegistrationStatus=true'
      , {}, httpOptions)
  }
  changePassword(email, password) {
    let emails = this.cryptService.createAes256(email, this.AES_IV, this.AES_KEY).toString().split(',');
    let passwords = this.cryptService.createAes256(password, this.AES_IV, this.AES_KEY).toString().split(',');
    return this.http.post<any>(environment.apiUrl + '/api/regist' +
      '?email=' + encodeURIComponent(emails[0]) +
      '&emailIv=' + encodeURIComponent(emails[1]) +
      '&epw=' + encodeURIComponent(passwords[0]) +
      '&epwIv=' + encodeURIComponent(passwords[1]) +
      '&webRegistrationStatus=' + 'false'
      , {})
  }
  private getRndStr(no) {
    var str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var len = no;
    var result = "";
    for (var i = 0; i < len; i++) {
      result += str.charAt(Math.floor(Math.random() * str.length));
    }
    return result;
  }
  loginTwitter(data: any) {
    let params = '';
    for (let key in data) {
      params += `${key}=${data[key]}&`
    }
    setTimeout(() => {
      let currentRouter = `/${this.router.url.split('?')[0]}`;
      this.router.navigate([currentRouter]);
    }, 100);
    return this.http.post(`${environment.apiUrl}/api/login/twitter?${params.slice(0, params.length - 1)}`, {})
  }

  redirectTwitterConnect(userHash?: string) {
    let redirectUrl = encodeURIComponent(location.href);
    if (userHash) {
      redirectUrl = redirectUrl + '?userId=' + userHash;
    }
    const params = new HttpParams().set('redirectUrl', redirectUrl)
    this.http.get(`${environment.apiUrl}/api/regist/twitter/authUrl`, { params: params })
      .subscribe(res => {
        window.location.href = res['webUrl'];
      })
  }
  connectTwitter(data, redirectMyPage: boolean = false, redirectThanks: boolean = false) {
    if (data.oauth_token && data.oauth_verifier) {
      setTimeout(() => {
        let currentRouter = `/${this.router.url.split('?')[0]}`;
        this.router.navigate([currentRouter]);
      }, 100);
      this.loadingService.loadStart();
      const httpOptions = {
        headers: new HttpHeaders(),
        params: new HttpParams().set('oauth_token', data.oauth_token).set('oauth_verifier', data.oauth_verifier)
      };
      if (data.userId) {
        httpOptions.headers = new HttpHeaders().set('userID', data.userId);
      }
      this.http.post(`${environment.apiUrl}/api/regist/twitter`, {}, httpOptions)
        .pipe(finalize(() => this.loadingService.loadEnd()))
        .subscribe((res: any) => {
          if (res.result) {
            if (data.userId) {
              this.sessionService.login(data.userId, 0);
            } else {
              this.store.dispatch(new FetchUserProfile());
            }
            if (redirectThanks) {
              this.router.navigate(['/register-thanks'])
            }
            this.snackBar.open(this.translateService.instant('connect_twitter_success'), 'Close', { duration: 3000, panelClass: 'snackBarCospo' });
          } else {
            if (redirectMyPage || redirectThanks) {
              this.router.navigate(['/login'])
            }
            this.snackBar.open(res.cause, 'Close', { duration: 3000, panelClass: 'snackBarCospo' });
          }
        }, error => {
          console.log(error);
          this.snackBar.open(error.error.cause, 'Close', { duration: 3000, panelClass: 'snackBarCospo' });
        });
    }
  }
  unSubscriptionAccount(): void {
    Swal.fire({
      title: this.translateService.instant('notify.title'),
      text: this.translateService.instant('unsubscribe_confirm'),
      confirmButtonText: this.translateService.instant('notify.confirm'),
      cancelButtonText: this.translateService.instant('notify.cancel'),
      showCancelButton: true,
      preConfirm: () => {
        this.http.post<any>(environment.apiUrl + '/api/withdrawal', {})
          .subscribe(() => {
            this.sessionService.removeCookie();
            this.router.navigate(['/withdrawal-thanks']);
          })
      }
    })
  }
}
