import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { getLanguage, FetchRanking } from '../../../@store/actions';
import { Store } from '@ngrx/store';
import { getRankings } from '@app/@store/reducers/ranking.reducer';
import { CacheCospoService } from '@app/@store/services/cache.service';

@Component({
  selector: 'app-corp',
  templateUrl: './corp.component.html',
  styleUrls: ['./corp.component.scss']
})
export class CorpComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  lang: string;
  rankings = [];
  constructor(private store: Store<any>, private api: CacheCospoService) {
    this.subscription = this.store.select(getLanguage).subscribe(lang => this.lang = lang);
  }
  ngOnInit() {
    this.store.dispatch(new FetchRanking());
    // this.store.select(getRankings).subscribe((res: any) => {
    //   if (res.rankings.length) {
    //     this.api.getDetailRanking(res.rankings[1].id)
    //       .subscribe(data => {
    //         this.rankings = (data.content || []).filter((x, index) => index <= 2);
    //       })
    //   }
    // })
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
