<div class="performance">
  <h2 translate="ranking_achievements" class="cospo-title"></h2>
  <table aria-describedby="">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col" translate="paid"></th>
        <th scope="col" translate="free"></th>
      </tr>
    </thead>
    <tbody [@animateStagger]="{value:'50'}">
      <tr *ngFor="let point of points;trackBy:trackByFn" [@animate]="{value:'*',params:{y:'100%'}}">
        <td>{{ point?.targetDate }}</td>
        <td>{{ point?.totalPay}}</td>
        <td>{{ point?.totalFree}}</td>
      </tr>
    </tbody>
  </table>
</div>
