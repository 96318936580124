import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { FETCH_NEWS, FetchNewsSuccess } from "../actions";
import { mergeMap, map } from "rxjs/operators";
import { CacheCospoService } from "../services/cache.service";

@Injectable()
export class NewsEffect {
    constructor(private api: CacheCospoService, private action: Actions) { }

    @Effect()
    fetchNews$ = this.action.pipe(
        ofType(FETCH_NEWS),
        mergeMap(() => this.api.getNewList()
            .pipe(
                map((json: any) => new FetchNewsSuccess(json.content, json.existNext))
            ))
    )
}
