<div class="responsive-content">
  <h1>
    <img src="/assets/purchace/p.png" alt="ポイント" style="width:1em;">
    {{'purchase.add_point'|translate}}
  </h1>
  <p>
    {{'purchase.bonus_point'|translate}}
    <br />
    <span class="pink">{{'purchase.enought_to_buy'|translate}}</span>
  </p>

  <div class="flex">
    <div class="hidari">{{'purchase.prossetion'|translate}}</div>
    <div class="migi"><span class="app-color">♥{{ point | number }}</span></div>
  </div>
  <div class="center">
    <a href="{{ link }}" target="_blank">
      <button class="load-more m-t-20 m-b-20" (click)="getLink()" [disabled]="!link">{{'purchase.view_advertisement'|translate}}</button>
    </a>
    <br>
    <img src="/assets/purchace/paypal.png" alt="ポイント" style="width:180px;">
    <br>
    <a href="https://cospo.net/web/user/paypal">
      <button class="load-more m-t-20 m-b-20">{{'purchase.buy_with_paypal'|translate}}</button>
    </a>
    <div>{{'purchase.after_click'|translate}}<br>
      <span style="font-weight:600;font-size:130%;" class="app-color">
        {{'purchase.email_password_required'|translate}}
        <br>
      </span>
      {{'purchase.please_login'|translate}}
    </div>
  </div>
</div>
