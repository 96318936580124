import { Action } from "@ngrx/store";
import { UserInfo } from '../models/user';

export const FETCH_USER_PROFILE = '[PROFILE] FETCH_USER_PROFILE_INFORMATION';
export const FETCH_USER_PROFILE_SUCCESS = '[PROFILE] FETCH_USER_PROFILE_SUCCESS INFORMATION';
export const CHANGE_USER_POINT = '[PROFILE] CHANGE_USER_POINT';
export const CHANGE_USER_AVATAR = '[PROFILE] CHANGE_USER_AVATAR';
export const GET_SOCIAL_PROVIDER = "[PROFILE] GET SOCIAL PROVIDER";
export const CHANGE_USER_NEXT_LEVER = "[PROFILE] CHANGE USER NEXT LEVER";
export class FetchUserProfile implements Action {
  readonly type = FETCH_USER_PROFILE;
}
export class FetchUserProfileSuccess implements Action {
  readonly type = FETCH_USER_PROFILE_SUCCESS;
  constructor(public profile: UserInfo) { }
}
export class ChangeUserPoint implements Action {
  readonly type = CHANGE_USER_POINT;
  constructor(public point: number) { }
}
export class ChangeAvatar implements Action {
  readonly type = CHANGE_USER_AVATAR;
  constructor(public image: string) { }
}
export class GetSocialProvider implements Action {
  readonly type = GET_SOCIAL_PROVIDER;
  constructor(public socialProviders: string) { }
}
export class ChangeUserNextLever implements Action {
  readonly type = CHANGE_USER_NEXT_LEVER;
  constructor(public nextLever: number) { }
}
export type ProfileActions =
  FetchUserProfile
  | FetchUserProfileSuccess
  | ChangeUserPoint
  | ChangeAvatar
  | GetSocialProvider
  | ChangeUserNextLever;
