<div class="responsive-content content-shadow">
  <h1 translate="fan_ranking" class="cospo-title"></h1>
  <div class="box">
    <ul [@animateStagger]="{value:'50'}">
      <li *ngFor="let fan of rankings;trackBy:trackByFn; index as i" [@animate]="{value:'*',params:{y:'100%'}}">
        <div class="text">
          <div class="number">{{ i + 1 }}</div>
          <div class="img-part">
            <span class="fan_image">
              <img [src]="fan.image?fan.image:'./assets/images/no_image.jpg'" alt=""
                onerror="this.src='./assets/images/no_image.jpg'"></span>
          </div>
          <div class="post_name">
            {{ fan.name }}
          </div>
        </div>
        <div class="cheer_count">{{ fan.totalCheeredCount | number }} <span class="app-color m-l-5">♥</span>
        </div>
        <a [routerLink]="['/user-profile', fan.userId]" class="nav-link-position"></a>
      </li>
    </ul>
  </div>
</div>
