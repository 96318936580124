import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SessionGuard, LoggedIn } from './@helper/session.guard';
import { FanComponent } from './pages/rankings/fan/fan.component';
import { NewsDetailComponent } from './pages/headers/new/news-detail/news-detail.component';
import { CorpComponent } from './pages/footer/corp/corp.component';
import { RegisterThanksComponent } from './pages/authen/register-thanks/register-thanks.component';
import { DetailComponent } from './pages/users/player-posts/detail/detail.component';
import { KawaiiAnalysisComponent } from './pages/users/kawaii-analysis/kawaii-analysis.component';
import { UserProfileComponent } from './pages/users/user-profile/user-profile.component';
import { WithdrawalComponent } from './pages/users/withdrawal/withdrawal.component';
import { WithdrawalThanksComponent } from './pages/users/withdrawal-thanks/withdrawal-thanks.component';
import { CommercialCodeComponent } from './pages/footer/commercial-code/commercial-code.component';
import { ShiketsuhouComponent } from './pages/footer/shiketsuhou/shiketsuhou.component';
import { ContactComponent } from './pages/footer/contact/contact.component';
import { AppRoutingPreloaderService } from './@helper/router-reload';
import { PolicyComponent } from './pages/footer/policy/policy.component';
import { PurchaseComponent } from './pages/users/purchase/purchase.component';



const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  { path: 'coming-soon', loadChildren: () => import("./pages/coming-soon/coming-soon.module").then(m => m.ComingSoonModule) },
  {
    path: 'about',
    loadChildren: () => import('./pages/headers/about/about.module').then(m => m.AboutModule),
    data: { preload: false }
  },
  {
    path: 'gold-post',
    loadChildren: () => import('./pages/headers/feed/gold-post/gold-post.module').then(m => m.GoldPostModule),
    data: { preload: false }
  },
  {
    path: 'silver-post',
    loadChildren: () => import('./pages/headers/feed/silver-post/silver-post.module').then(m => m.SilverPostModule),
    data: { preload: false }
  },
  {
    path: 'v-tuber',
    loadChildren: () => import('./pages/headers/feed/vtuber/vtuber-post.module').then(m => m.VtuberPostModule),
    data: { preload: false }
  },
  {
    path: 'event',
    loadChildren: () => import('./pages/headers/event/event.module').then(m => m.EventModule),
    data: { preload: false }
  },
  {
    path: 'ranking',
    loadChildren: () => import('./pages/rankings/ranking/ranking.module').then(m => m.RankingModule),
    data: { preload: false }
  },
  {
    path: 'past-ranking',
    loadChildren: () => import('./pages/rankings/past-ranking/past-ranking.module').then(m => m.PastRankingModule),
    canActivate: [SessionGuard], data: { preload: false }
  }, //041
  {
    path: 'news',
    loadChildren: () => import('./pages/headers/new/news/news.module').then(m => m.NewsModule),
    data: { preload: false }
  },
  {
    path: 'movie',
    loadChildren: () => import('./pages/headers/movie/movie.module').then(m => m.MovieModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/authen/login/login.module').then(m => m.LoginModule),
    data: { preload: false },
    canActivate: [LoggedIn]
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/footer/faq/faq.module').then(m => m.FaqModule),
    data: { preload: false }
  },
  {
    path: 'player/search/:query',
    loadChildren: () => import('./pages/players/search-player/search-player.module').then(m => m.SearchPlayerModule),
    data: { preload: true }
  },
  {
    path: 'player-detail/:id',
    loadChildren: () => import('./pages/players/player-detail/player-detail.module').then(m => m.PlayerDetailModule),
    canActivate: [SessionGuard], data: { preload: true }
  },
  {
    path: 'player-posts/:id',
    loadChildren: () => import('./pages/players/player-posts/player-posts.module').then(m => m.PlayerPostsModule),
    canActivate: [SessionGuard], data: { preload: true }
  },
  {
    path: 'player-profile/:id',
    loadChildren: () => import('./pages/players/player-profile/player-profile.module').then(m => m.PlayerProfileModule),
    data: { preload: false }
  },
  {
    path: 'performance/:id',
    loadChildren: () => import('./pages/players/performance/performance.module').then(m => m.PerformanceModule),
    data: { preload: false }
  },
  {
    path: 'player-fan/:id',
    loadChildren: () => import('./pages/players/player-fan/player-fan.module').then(m => m.PlayerFanModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'post/search',
    loadChildren: () => import('./pages/headers/feed/search-post/search-post.module').then(m => m.SearchPostModule),
    canActivate: [SessionGuard], data: { preload: true }
  },
  {
    path: 'post-detail/:id',
    loadChildren: () => import('./pages/headers/feed/post-detail/post-detail.module').then(m => m.PostDetailModule),
    data: { preload: true }
  },
  {
    path: 'forget',
    loadChildren: () => import('./pages/authen/forget/forget.module').then(m => m.ForgetModule),
    data: { preload: false }
  },
  {
    path: 'my-page',
    loadChildren: () => import('./pages/users/my-page/my-page.module').then(m => m.MyPageModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'player-apply',
    loadChildren: () => import('./pages/users/player-apply/player-apply.module').then(m => m.PlayerApplyModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'privacy',
    loadChildren: () => import('./pages/footer/privacy/privacy.module').then(m => m.PrivacyModule),
    data: { preload: false }
  },
  {
    path: 'company',
    loadChildren: () => import('./pages/footer/company/company.module').then(m => m.CompanyModule),
    data: { preload: false }
  },
  {
    path: 'block-list',
    loadChildren: () => import('./pages/users/block-lists/block-lists.module').then(m => m.BlockListsModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'favorite',
    loadChildren: () => import('./pages/users/favorite/favorite.module').then(m => m.FavoriteModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'ranking-detail/:id',
    loadChildren: () => import('./pages/rankings/ranking-detail/ranking-detail.module').then(m => m.RankingDetailModule)
  },
  {
    path: 'post-create',
    loadChildren: () => import('./pages/users/player-posts/create/post-create.module').then(m => m.PostCreateModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'post-edit/:id',
    loadChildren: () => import('./pages/users/player-posts/post-edit/post-edit.module').then(m => m.PostEditModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'my-posts',
    loadChildren: () => import('./pages/users/player-posts/my-posts.module').then(m => m.MyPostsModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'user-edit',
    loadChildren: () => import('./pages/users/user-edit/user-edit.module').then(m => m.UserEditModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'pass-edit',
    loadChildren: () => import('./pages/users/pass-edit/pass-edit.module').then(m => m.PassEditModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'kawaii-history',
    loadChildren: () => import('./pages/users/kawaii-history/kawaii-history.module').then(m => m.KwaiiHistoryModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'kawaii-player',
    loadChildren: () => import('./pages/users/kawaii-player/kawaii-player.module').then(m => m.KwaiiPlayerModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: 'event-join',
    loadChildren: () => import('./pages/users/event-join/event-join.module').then(m => m.EventJoinModule),
    canActivate: [SessionGuard], data: { preload: false }
  },
  {
    path: '1',
    loadChildren: () => import('./pages/dynamic-link/dynamic-link.module').then(m => m.DynamicLinkModule),
  },
  {
    path: 'fan', component: FanComponent
  },
  {
    path: 'news/:id', component: NewsDetailComponent
  },
  {
    path: 'corp', component: CorpComponent
  },
  {
    path: 'register-thanks', component: RegisterThanksComponent, canActivate: [SessionGuard]
  },
  {
    path: 'detail/:id', component: DetailComponent, canActivate: [SessionGuard]
  },
  {
    path: 'kawaii-analysis', component: KawaiiAnalysisComponent, canActivate: [SessionGuard]
  },
  {
    path: 'user-profile/:id', component: UserProfileComponent, canActivate: [SessionGuard]
  },
  {
    path: 'purchase', component: PurchaseComponent, canActivate: [SessionGuard]
  },
  {
    path: 'withdrawal', component: WithdrawalComponent, canActivate: [SessionGuard]
  },
  {
    path: 'withdrawal-thanks', component: WithdrawalThanksComponent
  },
  {
    path: 'commercial-code', component: CommercialCodeComponent
  },
  {
    path: 'shiketsuhou', component: ShiketsuhouComponent
  },
  {
    path: 'policy', component: PolicyComponent
  },
  {
    path: 'contact', component: ContactComponent
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/authen/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: '**', redirectTo: '',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: false, preloadingStrategy: AppRoutingPreloaderService, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
