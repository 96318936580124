import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../@store/services/session.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanActivate {
  url: string;
  query: string;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private translate: TranslateService
  ) { }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.isEmpty(next.queryParams)) {
      this.query = next.queryParams['query'];
    } else {
      this.url = '';
      next.url.forEach((val) => {
        this.url += val + '/';
      })
    }

    if (!this.sessionService.session.login) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'error': this.translate.instant('please_login'),
          'prev_url': this.url,
          'query': this.query
        }
      };

      this.router.navigate(['/login'], navigationExtras);

      return false;
    }

    return true;
  }

  isEmpty(val) {
    if (!val) {
      if (val !== 0 && val !== false) {
        return true;
      }
    } else if (typeof val == "object") {
      return Object.keys(val).length === 0;
    }

    return false;
  }
}
@Injectable({ providedIn: 'root' })
export class LoggedIn implements CanActivate {
  constructor(private router: Router, private sessionService: SessionService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.sessionService.session.login) {
      this.router.navigate(['/my-page']);
      return false;
    }
    return true;
  }
}
