import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLanguage } from '@app/@store/actions/language.action';
import { getProfile } from '@app/@store/reducers/user.reduder';
import { Subscription } from 'rxjs';
import { CacheCospoService } from '@app/@store/services/cache.service';
@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit, OnDestroy {
  point;
  lang;
  link;
  private subscriptions: Subscription[] = [];
  constructor(
    readonly store: Store<any>,
    private api: CacheCospoService
  ) { }
  getLink() {
    this.api.getAdvertisementLink()
      .subscribe((data) => {
        if(data.content.length > 0) {
          this.store.select(getProfile).subscribe(user => {      
            if (user) {
              let urlObj = new URL(data.content[0].linkUrl);
              urlObj.searchParams.set("u", user.id.toString());
              this.link = urlObj.href;
            }
          });
          
        }
      });
  }
  ngOnInit() {
    this.link = null;
    const langSub = this.store.select(getLanguage).subscribe(lang => this.lang = lang);
    this.subscriptions.push(langSub);
    const profileSub = this.store.select(getProfile).subscribe(json => {
      this.point = json?.point || 0;
      this.getLink();
    });
    this.subscriptions.push(profileSub);
  }
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
