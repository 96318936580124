<article *ngIf="lang==='ja'">
  <h1>資金決済法に基づく表記</h1>
  <br>
  <div class="container">
    <div class="border">
      <div class="item">発行者</div>
      <div class="item2">
        KAWAII JAPAN 株式会社
        <br>
        〒135-0064　東京都江東区青海2-7-4　ＴＨＥＳＯＨＯ706
      </div>
    </div>
    <div class="border">
      <div class="item">購入限度額（支払可能金額等）</div>
      <div class="item2">上限はございません。</div>
    </div>
    <div class="border">
      <div class="item">ポイントの有効期限</div>
      <div class="item2">
        期限はありません。
        <br>
        ただし、当社は最終のアクセスから1年間以上経過しているアカウントを
        <br>
        あらかじめお客様に通知することなく削除する場合がございます。
        <br>
        この場合、未使用ポイントは失効致します。
      </div>
    </div>
    <div class="border">
      <div class="item">ご相談窓口</div>
      <div class="item2">
        KAWAII JAPAN 株式会社<br>
        nquiry by email: info@kawaiijapan.co.jp
      </div>
    </div>
    <div class="border">
      <div class="item">利用可能な場所</div>
      <div class="item2">AndroidまたはiPhone用COSPOアプリにて使用することができます。</div>
    </div>
    <div class="border">
      <div class="item">利用上の注意</div>
      <div class="item2">
        原則としてポイントの払戻しはいたしません。
        <br>
        ただし、弊社がCOSPOのサービスを終了する場合はこの限りではありません。
        <br>
        この場合、払戻し実施時に有効に残存するポイントを、購入時の1ポイント
        <br>
        日本円以外の通貨による払戻しや日本国外の銀行口座へ送金を希望される場合、為替交換手数料、外国送金手数料等を負担いただく場合があります。（無料で発行したポイントに関しては払い戻し対象外になります。）
      </div>
    </div>
    <div class="border">
      <div class="item">残高確認方法</div>
      <div class="item2">ポイント残高ページよりご確認ください。</div>
    </div>
    <div class="border">
      <div class="item">利用規約</div>
      <div class="item2">
        <a style="color:#fb628a; font-weight:600;cursor: pointer;" routerLink="/policy">
          COSPO利用規約
        </a>
        をご覧ください。
      </div>
    </div>

  </div>

</article>
<article *ngIf="lang==='en'">
  <h1> Notation based on the Funds Settlement Act</h1>
  <br>
  <div class="container">
    <div class="border">
      <div class="item">The issuer</div>
      <div class="item2">
        KAWAII JAPAN CORPORATION
        <br>
        2-7-4 Aomi, Koto-ku, Tokyo 135-0064 THESHO706
      </div>
    </div>
    <div class="border">
      <div class="item">Purchase limit (payable amount, etc.)</div>
      <div class="item2">There is no upper limit.</div>
    </div>
    <div class="border">
      <div class="item">Point expiration date</div>
      <div class="item2">
        There is no deadline
        <br>
        However, the company reserves the right to terminate an account that has been in use for more than 1 year from
        the last access.
        <br>
        We may delete your personal information without prior notice to you.
        <br>
        In this case, unused points will expire.
      </div>
    </div>
    <div class="border">
      <div class="item">Consultation desk</div>
      <div class="item2">
        KAWAII JAPAN Co., Ltd.<br>
        nquiry by email: info@kawaiijapan.co.jp
      </div>
    </div>
    <div class="border">
      <div class="item">Available places</div>
      <div class="item2">It can be used with the COSPO app for Android or iPhone.</div>
    </div>
    <div class="border">
      <div class="item">Precautions for use</div>
      <div class="item2">
        In principle, points will not be refunded.
        <br>
        However, this is not the case when we terminate the service of COSPO.
        <br>
        In this case, we will refund the remaining points in effect at the time of the refund, the amount calculated in
        Japanese yen based on the amount per 1 point at the time of purchase.
        <br>
        If you wish to make a refund in a currency other than Japanese yen or transfer to a bank account outside of
        Japan, you may be required to pay a foreign remittance fee or a foreign remittance fee. (Points issued free of
        charge will not be refunded.)
      </div>
    </div>
    <div class="border">
      <div class="item">How to check balance</div>
      <div class="item2">Please check from the point balance page.</div>
    </div>
    <div class="border">
      <div class="item">Terms of service</div>
      <div class="item2">
        <a style="color:#fb628a; font-weight:600;cursor: pointer;" routerLink="/policy">
          See COSPO Terms
        </a>
        Service
      </div>
    </div>

  </div>

</article>
<article *ngIf="lang==='fr'">
  <h1>Notation basée sur la Fund Settlement Act</h1>
  <br>
  <div class="container">
    <div class="border">
      <div class="item">Émetteur</div>
      <div class="item2">
        KAWAII JAPAN CORPORATION
        <br>
        2-7-4 Aomi, Koto-ku, Tokyo 135-0064 THESHO706
      </div>
    </div>
    <div class="border">
      <div class="item">Limite d'achat (montant payable, etc.)</div>
      <div class="item2">Thel n'y a pas de limite supérieure.</div>
    </div>
    <div class="border">
      <div class="item">Date d'expiration des points</div>
      <div class="item2">
        Il n'y a pas de date limite.
        <br>
        Cependant,
        <br>
        nous pouvons supprimer un compte qui a plus d'un an depuis son dernier accès sans en informer le client à
        l'avance.
        <br>
        Dans ce cas, les points non utilisés expireront.
      </div>
    </div>
    <div class="border">
      <div class="item">Comptoir de consultation</div>
      <div class="item2">
        KAWAII JAPAN Co., Ltd.<br>
        Demande par e-mail: info@kawaiijapan.co.jp
      </div>
    </div>
    <div class="border">
      <div class="item">Places disponibles</div>
      <div class="item2">
        Il peut être utilisé avec l'application COSPO pour Android ou iPhone.</div>
    </div>
    <div class="border">
      <div class="item">Précautions d'emploi</div>
      <div class="item2">
        En principe, les points ne seront pas remboursés.
        <br>
        Cependant, cela ne s'applique pas si nous mettons fin au service de COSPO.
        <br>
        Dans ce cas, nous rembourserons les points restants valides au moment du remboursement, calculés en yens
        japonais en fonction du montant par point au moment de l'achat.
        <br>
        Si vous demandez un remboursement dans une devise autre que le yen japonais ou un virement vers un compte
        bancaire en dehors du Japon, des frais de change ou des frais de virement à l'étranger peuvent vous être
        facturés. (Les points émis gratuitement ne sont pas remboursables.)
      </div>
    </div>
    <div class="border">
      <div class="item">Méthode de confirmation du solde</div>
      <div class="item2">Please check from the point balance page.</div>
    </div>
    <div class="border">
      <div class="item">Veuillez vérifier à partir de la page de solde de points.</div>
      <div class="item2">
        <a style="color:#fb628a; font-weight:600;cursor: pointer;" routerLink="/policy">
          Veuillez vérifier à partir de la page de solde de points. Service
        </a>
      </div>
    </div>

  </div>

</article>
<article *ngIf="lang==='kor'">
  <h1>자금 결제 법에 근거하는 표기</h1>
  <br>
  <div class="container">
    <div class="border">
      <div class="item">발행자</div>
      <div class="item2">
        KAWAII JAPAN 주식회사
        <br>
        (우) 135-0064 도쿄도 코토 구 아오 미 2-7-4 THESOHO706
      </div>
    </div>
    <div class="border">
      <div class="item">구매 한도액 (지불 가능 금액 등)</div>
      <div class="item2">상한은 없습니다.</div>
    </div>
    <div class="border">
      <div class="item">포인트의 유효 기간</div>
      <div class="item2">
        기한은 없습니다.<br>
        그러나 우리는 마지막 액세스로부터 1 년 이상 경과 한 계정을<br>
        미리 고객에게 통지없이 삭제할 수 있습니다.<br>
        이 경우 사용하지 않은 포인트는 만료드립니다.
      </div>
    </div>
    <div class="border">
      <div class="item">상담 창구</div>
      <div class="item2">
        KAWAII JAPAN 주식회사<br>
        메일 문의 : info@kawaiijapan.co.jp
      </div>
    </div>
    <div class="border">
      <div class="item">사용 가능한 위치</div>
      <div class="item2">Android 또는 iPhone 용 COSPO 응용 프로그램에서 사용할 수 있습니다.</div>
    </div>
    <div class="border">
      <div class="item">이용시주의 사항</div>
      <div class="item2">
        원칙적으로 포인트의 환불은하지 않습니다.<br>
        그러나 우리가 COSPO 서비스를 종료하는 경우에는 그러하지는 않습니다.<br>
        이 경우 환불 수행시 활성화 잔존하는 포인트를 구입시 1 포인트 당 금액에 따라 엔화로 계산 한 금액을 환불드립니다.<br>
        엔화 이외의 통화로 환불이나 일본 국외의 은행 계좌로 송금을 희망하시는 경우 교환 교환 수수료, 외국 송금 수수료 등을 부담하는 경우가 있습니다. (무료로 발행 한 포인트에 관해서는 환불 대상에서
        제외됩니다.)<br>
      </div>
    </div>
    <div class="border">
      <div class="item">잔액 확인 방법</div>
      <div class="item2">포인트 잔액 페이지에서 확인하시기 바랍니다.</div>
    </div>
    <div class="border">
      <div class="item">이용 약관</div>
      <div class="item2">
        <a style="color:#fb628a; font-weight:600;cursor: pointer;" routerLink="/policy">
          COSPO 이용 약관
        </a>
        을 참조하십시오.
      </div>
    </div>

  </div>

</article>
<article *ngIf="lang==='zh'">
  <h1>基於《基金結算法》的註釋</h1>
  <br>
  <div class="container">
    <div class="border">
      <div class="item">發行人</div>
      <div class="item2">
        川日本株式會社THESOHO706東京都江東區青海2-7-4 135-0064
      </div>
    </div>
    <div class="border">
      <div class="item">購買限額（應付金額等）</div>
      <div class="item2"> 沒有上限。</div>
    </div>
    <div class="border">
      <div class="item">積分到期日</div>
      <div class="item2">
        沒有截止日期。<br>
        但是，<br>
        我們可能會刪除自上次訪問以來已有超過一年曆史的帳戶，而不會提前通知客戶。<br>
        在這種情況下，未使用的積分將過期。
      </div>
    </div>
    <div class="border">
      <div class="item">諮詢櫃檯</div>
      <div class="item2">
        KAWAII JAPAN Co.，Ltd.<br>
        通過電子郵件查詢：info@kawaiijapan.co.jp
      </div>
    </div>
    <div class="border">
      <div class="item">可用的地方</div>
      <div class="item2">它可以與COSPO應用程序一起用於Android或iPhone。</div>
    </div>
    <div class="border">
      <div class="item">使用注意事項</div>
      <div class="item2">
        原則上，積分將不予退還。<br>
        但是，如果我們終止COSPO服務，這將不適用。<br>
        在這種情況下，如果您<br>
        想退還在購買時以日元以外的其他貨幣或轉移到日本境外的銀行賬戶時在一種貨幣退款時有效的剩餘積分，請承擔匯兌手續費，國外匯款手續費等。有情況。（免費發行的積分無法退款。）
      </div>
    </div>
    <div class="border">
      <div class="item">餘額確認方法</div>
      <div class="item2">請從積分餘額頁面檢查。</div>
    </div>
    <div class="border">
      <div class="item">服務條款</div>
      <div class="item2">
        <a style="color:#fb628a; font-weight:600;cursor: pointer;" routerLink="/policy">
          請參閱COSPO服務條款。
        </a>
      </div>
    </div>

  </div>

</article>
<article *ngIf="lang==='zh-TW'">
  <h1>基於《基金結算法》的註釋</h1>
  <br>
  <div class="container">
    <div class="border">
      <div class="item">發行人</div>
      <div class="item2">
        川日本株式會社THESOHO706東京都江東區青海2-7-4 135-0064
      </div>
    </div>
    <div class="border">
      <div class="item">購買限額（應付金額等）</div>
      <div class="item2"> 沒有上限。</div>
    </div>
    <div class="border">
      <div class="item">積分到期日</div>
      <div class="item2">
        沒有截止日期。<br>
        但是，<br>
        我們可能會刪除自上次訪問以來已有超過一年曆史的帳戶，而不會提前通知客戶。<br>
        在這種情況下，未使用的積分將過期。
      </div>
    </div>
    <div class="border">
      <div class="item">諮詢櫃檯</div>
      <div class="item2">
        KAWAII JAPAN Co.，Ltd.<br>
        通過電子郵件查詢：info@kawaiijapan.co.jp
      </div>
    </div>
    <div class="border">
      <div class="item">可用的地方</div>
      <div class="item2">它可以與COSPO應用程序一起用於Android或iPhone。</div>
    </div>
    <div class="border">
      <div class="item">使用注意事項</div>
      <div class="item2">
        原則上，積分將不予退還。<br>
        但是，如果我們終止COSPO服務，這將不適用。<br>
        在這種情況下，如果您<br>
        想退還在購買時以日元以外的其他貨幣或轉移到日本境外的銀行賬戶時在一種貨幣退款時有效的剩餘積分，請承擔匯兌手續費，國外匯款手續費等。有情況。（免費發行的積分無法退款。）
      </div>
    </div>
    <div class="border">
      <div class="item">餘額確認方法</div>
      <div class="item2">請從積分餘額頁面檢查。</div>
    </div>
    <div class="border">
      <div class="item">服務條款</div>
      <div class="item2">
        <a style="color:#fb628a; font-weight:600;cursor: pointer;" routerLink="/policy">
          請參閱COSPO服務條款。
        </a>
      </div>
    </div>

  </div>

</article>
