import { AuthActionTypes, AuthActions } from "../actions";
import { createFeatureSelector } from "@ngrx/store";

export interface AuthState {
    isAuthenticated: boolean;
    userHash: string
    errorMessage: string | null;
    isLayer: number;
}

export const initialState: AuthState = {
    isAuthenticated: false,
    userHash: null,
    errorMessage: null,
    isLayer: null
};
export function authReducer(state = initialState, action: AuthActions) {
    switch (action.type) {
        case AuthActionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                userHash: action.payload.userHash,
                isLayer: action.payload.isLayer,
                errorMessage: null
            };
        }
        case AuthActionTypes.LOGIN_FAILURE: {
            return {
                ...state,
                errorMessage: action?.payload?.cause||'Incorrect email and/or password.'
            };
        }
        case AuthActionTypes.SIGNUP_SUCCESS: {
            return {
                ...state,
                isAuthenticated: true,
                userHash: action.payload.userHash,
                isLayer: action.payload.isLayer,
                errorMessage: null,
            };
        }
        case AuthActionTypes.SIGNUP_FAILURE: {
            return {
                ...state,
                errorMessage: 'That email is already in use.'
            };
        }
        case AuthActionTypes.LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}
export const selectAuthState = createFeatureSelector<AuthState>('auth');
