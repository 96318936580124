import { Action } from "@ngrx/store";

export const FETCH_RANKING = '[RANKINGS] GET LIST RANKING';
export const FETCH_RANKING_SUCCESS = '[RANKINGS] GET LIST RANKING SUCCESS';

export const FETCH_RANKING_PAST = '[RANKINGS] GET LIST RANKING PAST';
export const FETCH_RANKING__PAST_SUCCESS = '[RANKINGS] GET LIST RANKING PAST SUCCESS';

export const FETCH_DETAIL_RANKING = '[RANKING] FETCH_DETAIL_RANKING';
export const FETCH_DETAIL_RANKING_SUCCESS = '[RANKING] FETCH_DETAIL_RANKING_SUCCESS';
export class FetchRanking implements Action {
  readonly type = FETCH_RANKING;
}
export class FetchRankingSuccess implements Action {
  readonly type = FETCH_RANKING_SUCCESS;
  constructor(public rankings: Array<any>) { }
}
export class FetchRankingPast implements Action {
  readonly type = FETCH_RANKING_PAST;
}
export class FetchRankingPastSuccess implements Action {
  readonly type = FETCH_RANKING__PAST_SUCCESS;
  constructor(public rankings: Array<any>) { }
}

export class FetchDetailRanking implements Action {
  readonly type = FETCH_DETAIL_RANKING;
  constructor(public rankingId: any) { }
}
export class FetchDetailRankingSuccess implements Action {
  readonly type = FETCH_DETAIL_RANKING_SUCCESS;
  constructor(public payload: any) { }
}
export type RankingActions =
  FetchRanking
  | FetchRankingSuccess
  | FetchDetailRanking
  | FetchDetailRankingSuccess
  | FetchRankingPast
  | FetchRankingPastSuccess;
