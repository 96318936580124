import { Action } from '@ngrx/store';

export const FETCH_GOLD_POST = '[GOLD_POST] GET LIST GOLD_POST';
export const FETCH_GOLD_POST_SUCCESS = '[GOLD_POST] GET LIST GOLD_POST SUCCESS';
export const FETCH_GOLD_POST_MORE = '[GOLD_POST] GET LIST FETCH_GOLD_POST_MORE';
export const FETCH_GOLD_POST_MORE_SUCCESS = '[GOLD_POST] GET LIST FETCH_GOLD_POST_MORE_SUCCESS';

export const FETCH_SILVER_POST = '[SILVER_POST] GET LIST SILVER_POST';
export const FETCH_SILVER_POST_SUCCESS = '[SILVER_POST] GET LIST SILVER_POST SUCCESS';
export const FETCH_SILVER_POST_MORE = '[SILVER_POST] GET LIST FETCH_SILVER_POST_MORE';
export const FETCH_SILVER_POST_MORE_SUCCESS = '[SILVER_POST] GET LIST FETCH_SILVER_POST_MORE_SUCCESS';

export const FETCH_VTUBER_POST = '[VTUBER_POST] GET LIST VTUBER_POST';
export const FETCH_VTUBER_POST_SUCCESS = '[VTUBER_POST] GET LIST VTUBER_POST SUCCESS';
export const FETCH_VTUBER_POST_MORE = '[VTUBER_POST] GET LIST FETCH_VTUBER_POST_MORE';
export const FETCH_VTUBER_POST_MORE_SUCCESS = '[VTUBER_POST] GET LIST FETCH_VTUBER_POST_MORE_SUCCESS';

export const FETCH_HISTORY_WAKAII = '[HISTORY_WAKAII] FETCH_HISTORY_WAKAII';
export const FETCH_HISTORY_WAKAII_SUCCESS = '[HISTORY_WAKAII] FETCH_HISTORY_WAKAII_SUCCESS';
export const FETCH_HISTORY_WAKAII_MORE = '[HISTORY_WAKAII] FETCH_HISTORY_WAKAII_MORE';
export const FETCH_HISTORY_WAKAII_MORE_SUCCESS = '[HISTORY_WAKAII] FETCH_HISTORY_WAKAII_MORE_SUCCESS';

export const FETCH_FAVORITE = '[FAVORITE] FETCH_FAVORITE';
export const FETCH_FAVORITE_SUCCESS = '[FAVORITE] FETCH_FAVORITE_SUCCESS';
export const FETCH_FAVORITE_MORE = '[FAVORITE] FETCH_FAVORITE_MORE';
export const FETCH_FAVORITE_MORE_SUCCESS = '[FAVORITE] FETCH_FAVORITE_MORE_SUCCESS';

export const FETCH_SEARCH_POST = '[POST] FETCH_SEARCH_POST';
export const FETCH_SEARCH_POST_SUCCESS = '[POST] FETCH_SEARCH_POST_SUCCESS';
export const FETCH_SEARCH_POST_MORE = '[POST] FETCH_SEARCH_POST_MORE';
export const FETCH_SEARCH_POST_MORE_SUCCESS = '[POST] FETCH_SEARCH_POST_MORE_SUCCESS';

export const FETCH_KAWAII_PLAYER = '[PLAYER] FETCH_KAWAII_PLAYER';
export const FETCH_KAWAII_PLAYER_SUCCESS = '[PLAYER] FETCH_KAWAII_PLAYER_SUCCESS';

export const FETCH_BLOCK_COSPLAYER = '[BLOCK] FETCH_BLOCK_COSPLAYER';
export const FETCH_BLOCK_COSPLAYER_SUCCESS = '[BLOCK] FETCH_BLOCK_COSPLAYER_SUCCESS';

export const FETCH_MYPOST = '[MY_POST] FETCH_MYPOST';
export const FETCH_MYPOST_SUCCESS = '[MY_POST] FETCH_MYPOST_SUCCESS';
export const FETCH_MYPOST_MORE = '[MY_POST] FETCH_MYPOST_MORE';
export const FETCH_MYPOST_MORE_SUCCESS = '[MY_POST] FETCH_MYPOST_MORE_SUCCESS';
export const FETCH_ERROR = '[PLAYER_POST] FETCH ERROR';

export const FETCH_POST_DETAIL = "[POST] FETCH_POST_DETAIL";
export const FETCH_POST_DETAIL_SUCCESS = "[POST] FETCH_POST_DETAIL_SUCCESS";

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
}
export class FetchGoldPost implements Action {
  readonly type = FETCH_GOLD_POST;
}
export class FetchGoldPostSuccess implements Action {
  readonly type = FETCH_GOLD_POST_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}

export class FetchGoldPostMore implements Action {
  readonly type = FETCH_GOLD_POST_MORE;
  constructor(public currentPage: number) { }
}
export class FetchGoldPostMoreSuccess implements Action {
  readonly type = FETCH_GOLD_POST_MORE_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}

export class FetchSilverPost implements Action {
  readonly type = FETCH_SILVER_POST;
}
export class FetchSilverPostSuccess implements Action {
  readonly type = FETCH_SILVER_POST_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}

export class FetchSilverPostMore implements Action {
  readonly type = FETCH_SILVER_POST_MORE;
  constructor(public currentPage: number) { }
}
export class FetchSilverPostMoreSuccess implements Action {
  readonly type = FETCH_SILVER_POST_MORE_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}

export class FetchVtuberPost implements Action {
  readonly type = FETCH_VTUBER_POST;
}
export class FetchVtuberPostSuccess implements Action {
  readonly type = FETCH_VTUBER_POST_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}

export class FetchVtuberPostMore implements Action {
  readonly type = FETCH_VTUBER_POST_MORE;
  constructor(public currentPage: number) { }
}
export class FetchVtuberPostMoreSuccess implements Action {
  readonly type = FETCH_VTUBER_POST_MORE_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}

export class FetchHistoryKawaii implements Action {
  readonly type = FETCH_HISTORY_WAKAII
}
export class FetchHistoryKawaiiSuccess implements Action {
  readonly type = FETCH_HISTORY_WAKAII_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}
export class FetchHistoryKawaiiMore implements Action {
  readonly type = FETCH_HISTORY_WAKAII_MORE;
  constructor(public currentPage: number) { }
}
export class FetchHistoryKawaiiMoreSuccess implements Action {
  readonly type = FETCH_HISTORY_WAKAII_MORE_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}

export class FetchFavorite implements Action {
  readonly type = FETCH_FAVORITE
}
export class FetchFavoriteSuccess implements Action {
  readonly type = FETCH_FAVORITE_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}
export class FetchFavoriteMore implements Action {
  readonly type = FETCH_FAVORITE_MORE;
  constructor(public currentPage: number) { }
}
export class FetchFavoriteMoreSuccess implements Action {
  readonly type = FETCH_FAVORITE_MORE_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}

export class FetchSearchPost implements Action {
  readonly type = FETCH_SEARCH_POST
  constructor(public query: string) { }
}
export class FetchSearchPostSuccess implements Action {
  readonly type = FETCH_SEARCH_POST_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}
export class FetchSearchPostMore implements Action {
  readonly type = FETCH_SEARCH_POST_MORE;
  constructor(public currentPage: number, public query: string) { }
}
export class FetchSearchPostMoreSuccess implements Action {
  readonly type = FETCH_SEARCH_POST_MORE_SUCCESS;
  constructor(public hasNext: boolean, public data: any) { }
}
export class FetchKwaiiPlayer implements Action {
  readonly type = FETCH_KAWAII_PLAYER;
}
export class FetchKwaiiPlayerSuccess implements Action {
  readonly type = FETCH_KAWAII_PLAYER_SUCCESS;
  constructor(public data: any, public hasNext: boolean) { }
}

export class FetchBlockCosplayer implements Action {
  readonly type = FETCH_BLOCK_COSPLAYER;
}
export class FetchBlockCosplayerSuccess implements Action {
  readonly type = FETCH_BLOCK_COSPLAYER_SUCCESS;
  constructor(public cosplayers: Array<any>) { }
}
export class FetchMyPost implements Action {
  readonly type = FETCH_MYPOST;
  constructor(public playerId: number) { }
}
export class FetchMyPostSuccess implements Action {
  readonly type = FETCH_MYPOST_SUCCESS;
  constructor(public posts: Array<any>, public hasNext: boolean) { }
}
export class FetchMyPostMore implements Action {
  readonly type = FETCH_MYPOST_MORE;
  constructor(public playerId: number, public currentPage) { }
}
export class FetchMyPostMoreSuccess implements Action {
  readonly type = FETCH_MYPOST_MORE_SUCCESS;
  constructor(public posts: Array<any>, public hasNext: boolean) { }
}
export class FetchPostDetail implements Action {
  readonly type = FETCH_POST_DETAIL;
  constructor(public postId: number) { }
}
export class FetchPostDetailSuccess implements Action {
  readonly type = FETCH_POST_DETAIL_SUCCESS;
  constructor(public payload: any) { }
}
export type PlayerPostActions =
  FetchGoldPost
  | FetchGoldPostSuccess
  | FetchGoldPostMore
  | FetchGoldPostMoreSuccess
  | FetchSilverPost
  | FetchSilverPostSuccess
  | FetchSilverPostMore
  | FetchSilverPostMoreSuccess
  | FetchVtuberPost
  | FetchVtuberPostSuccess
  | FetchVtuberPostMore
  | FetchVtuberPostMoreSuccess
  | FetchHistoryKawaii
  | FetchHistoryKawaiiSuccess
  | FetchHistoryKawaiiMore
  | FetchHistoryKawaiiMoreSuccess
  | FetchFavorite
  | FetchFavoriteSuccess
  | FetchFavoriteMore
  | FetchFavoriteMoreSuccess
  | FetchSearchPost
  | FetchSearchPostSuccess
  | FetchSearchPostMore
  | FetchSearchPostMoreSuccess
  | FetchKwaiiPlayer
  | FetchKwaiiPlayerSuccess
  | FetchBlockCosplayer
  | FetchBlockCosplayerSuccess
  | FetchMyPost
  | FetchMyPostSuccess
  | FetchMyPostMore
  | FetchMyPostMoreSuccess
  | FetchError
  | FetchPostDetail
  | FetchPostDetailSuccess;
