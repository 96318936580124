import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { LanguageReducer, getLanguage } from '../../../@store/actions';
import { CookieService } from 'ngx-cookie-service';
import { getProfile } from '@app/@store/reducers/user.reduder';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription[] = [];
  body: any;
  lang: string;
  constructor(
    private store: Store<LanguageReducer>,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService
  ) {
    const langSub = this.store.select(getLanguage).subscribe(lang => this.lang = lang);
    this.subscriptions.push(langSub);
  }
  ngOnInit() {

  }
  private initialize() {
    const token = this.cookieService.get('token');
    const info = this.deviceService.getDeviceInfo();
    const newLine = this.deviceService.isDesktop() ? '%0D' : escape("\n");
    if (token) {
      this.store.select(getProfile).subscribe(() => {
        this.body = `
『日時』と『お問い合わせ内容』をできるだけ詳細にご記入ください。${newLine}
====================${newLine}
■お問い合わせ内容%0D ${newLine} ${newLine} ${newLine}
==================== ${newLine} ${newLine}
ユーザ名：${newLine}
お客様番号（編集・削除しないでください）${newLine}
${info.os}名${newLine}
${info.browser}${newLine}
`})
    } else {
      this.body = `
『日時』と『お問い合わせ内容』をできるだけ詳細にご記入ください。${newLine}
====================${newLine}
■お問い合わせ内容%0D ${newLine} ${newLine} ${newLine}
==================== ${newLine} ${newLine}
${info.os}名${newLine}
${info.browser}${newLine}
`
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());

  }
  getAndroidVersion(ua) {
    ua = (ua || navigator.userAgent).toLowerCase();
    var match = ua.match(/ios\s([0-9\.]*)/i);
    return match ? match[1] : undefined;
  };
}
