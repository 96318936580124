import * as playerActions from '../actions/player.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface PlayerState {
  getPlayer: {
    players: Array<any>,
    hasNext: boolean
  },
  playerRankings: Array<any>,
  history: any,
  player: any;
  playerId: number
}
export const initState: PlayerState = {
  history: {
    histories: [],
    monthlyHistories: [],
    summary: {}
  },
  playerRankings: [],
  player: {},
  playerId: null,
  getPlayer: {
    players: [],
    hasNext: false
  }
}
export function playerReducer(state = initState, action: playerActions.PlayerActions) {
  switch (action.type) {
    case playerActions.FETCH_PLAYER_SUCCESS:
      return {
        ...state,
        getPlayer: Object.assign({}, state.getPlayer, { players: action.players, hasNext: action.hasNext })
      }
    case playerActions.FETCH_PLAYER_MORE_SUCCESS:
      return {
        ...state,
        getPlayer: Object.assign({}, state.getPlayer, { players: [...state.getPlayer.players, ...action.players], hasNext: action.hasNext })
      }
    case playerActions.FETCH_PLAYER_DETAIL:
      return {
        ...state,
        player: action.playerId == state.player.id ? state.player : {}
      }
    case playerActions.FETCH_PLAYER_FAN:
      return {
        ...state,
        playerRankings: action.playerId == state.playerId ? state.playerRankings : [],
        playerId: action.playerId
      }
    case playerActions.FETCH_PLAYER_HISTORY:
      return {
        ...state,
        history: action.playerId == state.playerId ? state.history : {
          histories: [],
          monthlyHistories: [],
          summary: {}
        },
        playerId: action.playerId
      }
    case playerActions.FETCH_PLAYER_HISTORY_SUCCESS:
      return {
        ...state,
        history: {
          histories: action.data.histories,
          monthlyHistories: action.data.monthlyHistories,
          summary: action.data.summary
        }
      }
    case playerActions.FETCH_PLAYER_FAN_SUCCESS:
      return {
        ...state,
        playerRankings: action.data
      }
    case playerActions.FETCH_PLAYER_DETAIL_SUCCESS:
      return {
        ...state,
        player: action.player
      }
    default:
      return state;
  }
}
export const player = createFeatureSelector<PlayerState>('player');
export const getPlayer = createSelector(player, (state: PlayerState) => state.getPlayer);
export const getPlayerHistory = createSelector(player, (state: PlayerState) => state.history);
export const getPlayerFan = createSelector(player, (state: PlayerState) => state.playerRankings);
export const getPlayerDetail = createSelector(player, (state: PlayerState) => state.player);
