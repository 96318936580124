import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptService {

  constructor(

  	) { }

  public createAes256(origin, AES_IV, AES_KEY) {
  	try {
  	  var randomString = this.getRndStr(16);

  	  var src = this.encodeAes256(origin, randomString, AES_KEY)

  	  var srcIv = this.encodeAes256(randomString, AES_IV, AES_KEY)

  	  var binary_data = src;
  	  binary_data += (',' + srcIv);
  	  return binary_data;

  	} catch (error) {
  	  
  	}
  }
  private encodeAes256(src, srcIv, AES_KEY) {
  	var byteText = CryptoJS.enc.Utf8.parse(src);
  	var byteKey = CryptoJS.enc.Utf8.parse(AES_KEY);

  	var iv = CryptoJS.enc.Utf8.parse(srcIv);

  	var options = {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7};
  	var encrypted = CryptoJS.AES.encrypt(src, byteKey, options);
  	return encrypted;
  }

  private getRndStr(no){
    var str = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var len = no;
    var result = "";
    for(var i=0;i<len;i++){
      result += str.charAt(Math.floor(Math.random() * str.length));
    }

    return result;
  }
}
