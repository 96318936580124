// Angular
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { locale as ja } from '@app/@helper/language/ja';
import { locale as en } from '@app/@helper/language/en';
import { locale as fr } from '@app/@helper/language/fr';
import { locale as kor } from '@app/@helper/language/kor';
import { locale as china } from '@app/@helper/language/china';
import { locale as china_tw } from '@app/@helper/language/china_tw';
import { ChangeLanguage } from '../actions/language.action';
import { SessionService } from './session.service';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
export interface Locale {
  lang: string;
  data: Object;
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private langIds: any = [];
  constructor(
    private translate: TranslateService,
    private store: Store<any>,
    private sessionService: SessionService,
    private http: HttpClient) {
    this.translate.addLangs(['ja', 'en', 'kor', 'fr', 'china', 'china_tw']);
    this.translate.setDefaultLang('ja');
  }
  initLanguage() {
    this.loadTranslations(ja, en, fr, kor, china, china_tw);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      this.translate.use(browserLang);
    } else {
      localStorage.setItem('locale', 'ja');
      this.translate.use('ja');
    }
  }
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];
    locales.forEach(locale => {
      this.translate.setTranslation(locale.lang, locale.data, true);
      this.langIds.push(locale.lang);
    });
    this.translate.addLangs(this.langIds);
  }
  setLanguage(lang, code) {
    this.translate.use(lang);
    let userID = this.sessionService.session.login;
    if (userID) {
      this.http.post(`${environment.apiUrl}/api/user/me/displayLanguage?language=${code}`, {})
        .subscribe((res) => {
          localStorage.setItem('locale', lang);
          this.store.dispatch(new ChangeLanguage(lang, code));
        });
    } else {
      localStorage.setItem('locale', lang);
      this.store.dispatch(new ChangeLanguage(lang, code));
    }
  }
}
