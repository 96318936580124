<div class="ranking_title">
	<h2><span class="viga" style="color:#fb628a;" translate="menu.news"></span></h2>
</div>
<div class="news-detail">
	<div class="newswidth">
		<div class="text">
			<div>{{ news?.day }}</div>
			<div class="news_title">
				<div class="title">{{ news?.meta.title }}</div>
			</div>
			<div class="image">
				<img class="image" src="{{ news?.image }}" alt="{{ news?.meta.title }}">
			</div>
			<p>
				{{ news?.text }}
			</p>
			<ul>
				<li *ngFor="let url of news?.urls">
					<a href="{{ url.url }}" target="_blank">{{ url.title }}</a>
				</li>
			</ul>
		</div>
	</div>
</div>
<div class="more">
	<a routerLink="/news"><span class="page_link shadow-button" translate="back_list"></span></a>
</div>
