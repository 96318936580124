export class User {
  userHash: string;
  isLayer: number;
  result: boolean;
  code: number;
  cause: string;
  success: number;
}
export class UserInfo {
  cheerCount: number;
  cheerCountNextLevel: number;
  displayLanguage: {
    code: string;
    label: string;
  }
  freePoint: number;
  freePointsExpiredNextMonth: number;
  freePointsExpiredThisMonth: number;
  id: number;
  image: string;
  imageUrl: string;
  isAuth: boolean;
  level: number;
  mail: string;
  maxStamina: number;
  thumbnail: string;
  message: string;
  messageForHtml: string;
  name: string;
  nextMaxStamina: number;
  oneStaminaRecoveryTime: number;
  payPoint: number;
  payStamina: number;
  point: number;
  pointCountAfterNextLevel: number;
  pointCountNextLevel: number;
  purchasePoint: number;
  registeredMail: boolean;
  tel: string;
  totalCheerCount: number;
  totalStamina: number;
  socialProviders: Array<any>;
  player: {
    bankAccount: {
      accountHolderName: string;
      accountNumber: string;
      accountTypeName: string;
      bankName: string;
      branchName: string;
      branchNumber: string;
    },
    place: string;
    twitterUserName: string;
    applyPlayerType: string;
    instagramUserName: string;
    blogUrl: string;
  }
}
