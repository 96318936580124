import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
export const CHANGE_LANGUAGE = '[LANGUAGE] CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = '[LANGUAGE] CHANGE_LANGUAGE_SUCCESS';

export class ChangeLanguage {
    readonly type = CHANGE_LANGUAGE;
    constructor(public lang: string, public code: string) { }
}
export class ChangeLanguageSuccess {
    readonly type = CHANGE_LANGUAGE_SUCCESS;
    constructor(public lang: string) { }
}
export type LanguageActions = ChangeLanguage | ChangeLanguageSuccess;

export interface LanguageReducer {
    language: string
}

export const initialState: LanguageReducer = {
    language: localStorage.getItem('locale') ? localStorage.getItem('locale') : 'ja',
}
export function languageReducer(state = initialState, action: LanguageActions) {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.lang
            }

        default:
            return state
    }
}
export const language = createFeatureSelector<LanguageReducer>('language');
export const getLanguage = createSelector(language, (state: LanguageReducer) => state.language);