import { Component, HostListener } from '@angular/core';
import { TranslationService } from './@store/services/translation.service';
import { SplashScreenService } from './@store/services/splash-screen.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  windowScrolled: boolean;
  constructor(
    readonly translate: TranslationService,
    readonly splashScreenService: SplashScreenService,
    private router: Router
  ) {
    // this.router.navigate(['/coming-soon']);
    translate.initLanguage();
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }
  scrollToTop() {
    (function smoothScroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothScroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

}
