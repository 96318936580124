import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';

@Injectable({
  providedIn: 'root'
})

export class LoadingService {
  spinner = this.overlay.create({
    hasBackdrop: true,
    panelClass: 'loading-backdrop',
    positionStrategy: this.overlay
      .position().global().centerHorizontally().centerVertically()
  });

  constructor(
    private overlay: Overlay
  ) { }

  loadStart(): void {
      setTimeout(() => this.spinner.attach(new ComponentPortal(MatSpinner)));
  }

  loadEnd(): void {
    if (this.spinner.hasAttached) {
      setTimeout(() => this.spinner.detach());
    }
  }
}
