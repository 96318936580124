import { Injectable } from "@angular/core";
import { CacheCospoService } from "../services/cache.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { FETCH_USER_PROFILE, FetchUserProfileSuccess, FETCH_USER_PROFILE_SUCCESS } from "../actions";
import { TranslationService } from '@app/@store/services/translation.service';
import { Store } from '@ngrx/store';
import { profile } from '../reducers/user.reduder';
import { UserInfo } from '../models/user';
@Injectable()
export class UserEffect {
  constructor(private api: CacheCospoService, private action: Actions, private tranSrv: TranslationService, private store: Store<any>) { }

  // @Effect()
  // fetchProfile$ = this.action.pipe(
  //   ofType(FETCH_USER_PROFILE),
  //   mergeMap(() => this.api.getProfile().pipe(
  //     map((json: any) => new FetchUserProfileSuccess(json))
  //   ))
  // )
  @Effect({ dispatch: false })
  fetchProfile$ = this.action.pipe(
    ofType(FETCH_USER_PROFILE),
    tap(() => {
      this.api.getProfile()
        .subscribe((profile: UserInfo) => {
          this.api.checkSocialProvider().subscribe(json => {
            this.store.dispatch(new FetchUserProfileSuccess(Object.assign({}, profile, { socialProviders: json.socialProviders })))
          })
        })
    })
  )

  @Effect({ dispatch: false })
  successProfile$ = this.action.pipe(
    ofType(FETCH_USER_PROFILE_SUCCESS),
    tap((payload: FetchUserProfileSuccess) => {
      let lang = 'ja';
      switch (payload.profile.displayLanguage.code) {
        case 'ENGLISH':
          lang = 'en';
          break;
        case 'FRENCH':
          lang = 'fr';
          break;
        case 'KOREAN':
          lang = 'kor';
          break;
        case 'CHINESE':
          lang = 'zh';
          break;
        case 'CHINESE_TW':
          lang = 'zh-TW';
          break;
        default:
          lang = 'ja';
          break;
      }
      this.tranSrv.setLanguage(lang, payload.profile.displayLanguage.code);
      // this.api.checkSocialProvider().subscribe(json => {
      //   this.store.dispatch(new GetSocialProvider(json.socialProviders))
      // })
    })
  )

}
