import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { cospoAnimations } from '@app/@animations';
import { CacheCospoService } from '@app/@store/services/cache.service';
@Component({
  selector: 'app-kawaii-analysis',
  templateUrl: './kawaii-analysis.component.html',
  styleUrls: ['./kawaii-analysis.component.scss'],
  animations: cospoAnimations
})
export class KawaiiAnalysisComponent implements OnInit {
  points = [];
  lang;
  constructor(
    private api: CacheCospoService
  ) {
  }
  ngOnInit() {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth() + 1
    for (let i = month; i >= 1; i--) {
      const target_date = this.yyyymm(year, i);
      const date_title = this.yyyyMm(year, i)
      this.api.getRakingDaily(target_date)
        .subscribe(
          json => {
            const total_pay = json.data.reduce((item, currentValue) => {
              return item += currentValue.payCheeredCount;
            }, 0);
            const total_free = json.data.reduce((item, currentValue) => {
              return item += (currentValue.totalCheeredCount - currentValue.payCheeredCount);
            }, 0);
            this.points.push({ targetDate: date_title, totalPay: total_pay, totalFree: total_free })
            this.points.sort(function (a, b) {
              if (a.targetDate > b.targetDate) {
                return -1;
              } else {
                return 1;
              }
            })
          });
    }


  }

  yyyymm(y, m) {
    const y0 = ('000' + y).slice(-4);
    const m0 = ('0' + m).slice(-2);
    return y0 + m0;
  }

  yyyyMm(y, m) {
    const y0 = ('000' + y).slice(-4);
    const m0 = ('0' + m).slice(-2);
    return y0 + ' - ' + m0;
  }
  trackByFn(index) { return index }
}
