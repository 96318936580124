import { MovieEffect } from "./movie.effect";
import { PlayerPostEffect } from "./playerPost.effect";
import { NewsEffect } from "./news.effect";
import { EventEffect } from "./event.effect";
import { UserEffect } from "./user.effect";
import { RankingEffect } from "./ranking.effect";
import { AuthEffects } from "./auth.effects";
import { PlayEffect } from "./player.effect";

export const effects: any[] = [PlayerPostEffect, MovieEffect, NewsEffect, EventEffect, UserEffect, RankingEffect, AuthEffects, PlayEffect];
export * from './playerPost.effect';
export * from './movie.effect';
export * from './news.effect';
export * from './event.effect';
export * from './user.effect';
export * from './ranking.effect';
export * from './auth.effects';
export * from './player.effect';
