import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { mergeMap, map } from "rxjs/operators";
import { FetchMoviesSuccess, FETCH_MOVIES } from "../actions";
import { CacheCospoService } from "../services/cache.service";
@Injectable()
export class MovieEffect {
    constructor(private api: CacheCospoService, private action: Actions) { }

    @Effect()
    fetchGoldPost$ = this.action.pipe(
        ofType(FETCH_MOVIES),
        mergeMap(() => this.api.getMovies()
            .pipe(
                map((json: any) => new FetchMoviesSuccess(json.movies))
            ))
    )
}
