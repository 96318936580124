import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({ name: 'image' })
export class ImagePipe implements PipeTransform {
  constructor(private http: HttpClient) { }

  transform(url: string) {
    return this.http.get('https://gamek.mediacdn.vn/2017/photo-4-1489646328560.jpg', { responseType: 'blob',headers:new HttpHeaders({'Content-Type': 'image/*'}) })

  }
}
