<article *ngIf="lang==='ja'">
  <h1> 特定商取引法に基づく表記</h1>
  <br>
  販売事業者の名称・所在地・電話番号
  <br>
  <br>
  〒135-0064 東京都江東区青海2-7-4 ＴＨＥＳＯＨＯ706
  <br>
  連絡先電話番号：ご請求いただいた場合、遅滞なく開示いたします。
  <br>
  ※お電話でのサポートは行っておりません。
  <br>
  <br>

  <div class="container">
    <div class="border">
      <div class="item">代表者名</div>
      <div class="item2">代表取締役 / CEO　笹谷 祐樹</div>
    </div>
    <div class="border">
      <div class="item">お問い合わせ</div>
      <div class="item2">info@kawaiijapan.co.jp</div>
    </div>
    <div class="border">
      <div class="item">販売価格</div>
      <div class="item2">
        <strong>購入手続きの際に画面に表示されます。</strong>
        <br>
        ※当アプリのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となる
        <br>
        インターネット接続料金、通信料金等はお客様の負担となります。それぞれの料金は、
        <br>
        お客様がご利用のインターネットプロバイダーまたは携帯電話会社にお問い合わせください。
      </div>
    </div>
    <div class="border">
      <div class="item">代金支払方法</div>
      <div class="item2">Google Play又はApp Storeが指定する決済方法。</div>
    </div>
    <div class="border">
      <div class="item">支払時期</div>
      <div class="item2">決済方法で定められた支払時期によります。</div>
    </div>
    <div class="border">
      <div class="item">提供時期</div>
      <div class="item2">決済手続き完了後、直ちに、ご利用いただけます。</div>
    </div>
    <div class="border">
      <div class="item">キャンセル・返品</div>
      <div class="item2">
        デジタルアイテム等購入後のお客様のご都合によるキャンセル・返品は、お受けできません。
      </div>
    </div>
    <div class="border">
      <div class="item">対応端末・動作環境</div>
      <div class="item2">iOS10.0以上、Android6.00以上</div>
    </div>

  </div>
</article>
<article *ngIf="lang==='en'">
  <h1>Notation based on the specified commercial transaction act</h1>
  <br>
  Name, address, and telephone number of the vendor
  <br>
  <br>
  2-7-4 Aomi, Koto-ku, Tokyo 135-0064 THESOHO706
  <br>
  Contact telephone number: If you request, we will disclose it without delay.
  <br>
  We do not provide telephone support.
  <br>
  <br>

  <div class="container">
    <div class="border">
      <div class="item">Representative's name</div>
      <div class="item2">Representative Director / CEO Yuki Sasaya</div>
    </div>
    <div class="border">
      <div class="item">Contact Us'</div>
      <div class="item2">info@kawaiijapan.co.jp</div>
    </div>
    <div class="border">
      <div class="item">Selling price</div>
      <div class="item2">
        <strong>Appears on the screen during the purchase process.</strong>
        <br>
        ※ It is necessary to view the page of this application, to purchase content, to download the software, etc'
        <br>
        Internet connection fee, communication fee, etc. will be the burden of the customer. Each charge is
        <br>
        Please contact your internet provider or mobile phone provider.
      </div>
    </div>
    <div class="border">
      <div class="item">Substitution method</div>
      <div class="item2">
        Payment method specified by Google Play or App Store.</div>
    </div>
    <div class="border">
      <div class="item">Payment time</div>
      <div class="item2">
        It depends on the payment period set by the payment method.</div>
    </div>
    <div class="border">
      <div class="item">Offer period</div>
      <div class="item2">
        You can use it immediately after completing the payment procedure
      </div>
    </div>
    <div class="border">
      <div class="item">Cancellation / return</div>
      <div class="item2">
        Cancellations / returns due to the customer's convenience after purchase of digital items etc. cannot be
        accepted.
      </div>
    </div>
    <div class="border">
      <div class="item">Supported terminals and operating environment</div>
      <div class="item2">OS 10.0 or later, Android 6.00 or later</div>
    </div>
  </div>
</article>
<article *ngIf="lang==='fr'">
  <h1>Notation basée sur la loi spécifiée sur les transactions commerciales</h1>
  <br>
  Nom, adresse et numéro de téléphone du vendeur Numéro de téléphone professionnel :
  <br>
  <br>
  THESOHO706 2-7-4 Aomi, Koto-ku, Tokyo 135-0064 Le
  <br>
  numéro de téléphone: Nous le divulguerons sans délai sur demande.
  <br>
  * Nous ne fournissons pas d'assistance téléphonique.
  <br>
  <br>
  <div class="container">
    <div class="border">
      <div class="item">Nom du représentant</div>
      <div class="item2">Directeur Représentant / PDG Yuki Sasaya</div>
    </div>
    <div class="border">
      <div class="item">Contactez nous</div>
      <div class="item2">info@kawaiijapan.co.jp</div>
    </div>
    <div class="border">
      <div class="item">Prix ​​de vente</div>
      <div class="item2">
        <strong>Il sera affiché à l'écran pendant la procédure d'achat.</strong>
        <br>
        ※ Les clients sont responsables des frais de connexion Internet, des frais de communication, etc. nécessaires
        pour parcourir les pages de cette applicat
        <br>
        ion, acheter du contenu, télécharger des logiciels, etc.
        <br>
        Veuillez contacter votre fournisseur d'accès Internet ou votre opérateur de téléphonie mobile pour connaître les
        frais respectifs .
      </div>
    </div>
    <div class="border">
      <div class="item">Mode de paiement</div>
      <div class="item2"> Le mode de paiement spécifié par Google Play ou App Store.</div>
    </div>
    <div class="border">
      <div class="item">Délai de paiement</div>
      <div class="item2">Cela dépend de la période de paiement spécifiée dans le mode de paiement.</div>
    </div>
    <div class="border">
      <div class="item">Offrir du temps</div>
      <div class="item2">Vous pouvez l'utiliser immédiatement après avoir terminé la procédure de paiement.</div>
    </div>
    <div class="border">
      <div class="item">Annulation / retour</div>
      <div class="item2">Les annulations et les retours dus à la commodité du client après l'achat d'articles
        numériques, etc. ne seront pas acceptés.</div>
    </div>
    <div class="border">
      <div class="item">Terminaux compatibles / environnement d'exploitation</div>
      <div class="item2">iOS 10.0 ou supérieur, Android 6.00 ou supérieur</div>
    </div>
  </div>
</article>
<article *ngIf="lang==='kor'">
  <h1>특정 상거래 법에 근거한 표기</h1>
  <br>
  판매 사업자의 명칭 · 소재지 · 전화 번호<br><br>

  (우) 135-0064 도쿄도 코토 구 아오 미 2-7-4 THESOHO706<br>
  연락처 전화 번호 : 청구받은 경우 지체없이 공개합니다.<br>
  ※ 전화로의 지원은 실시하지 않습니다.<br>
  <br>
  <br>
  <div class="container">
    <div class="border">
      <div class="item">대표자 명</div>
      <div class="item2">대표 이사 / CEO 사 사야 유우키</div>
    </div>
    <div class="border">
      <div class="item">문의</div>
      <div class="item2">info@kawaiijapan.co.jp</div>
    </div>
    <div class="border">
      <div class="item">판매 가격</div>
      <div class="item2">
        <strong>구입 수속시 화면에 표시됩니다.</strong><br>
        ※ 본 앱 페이지보기 콘텐츠 구매, 소프트웨어 다운로드 등에 필요한<br>
        인터넷 연결 요금, 통신 요금 등은 고객의 부담입니다. 각각의 요금은<br>
        고객이 이용 인터넷 공급자 또는 휴대 전화 회사에 문의하십시오.
      </div>
    </div>
    <div class="border">
      <div class="item">대금 지불 방법</div>
      <div class="item2">Google Play 또는 App Store가 지정하는 결제 방법.</div>
    </div>
    <div class="border">
      <div class="item">지급시기</div>
      <div class="item2">결제 방법으로 정해진 지급시기에 따라 달라집니다.</div>
    </div>
    <div class="border">
      <div class="item">제공시기</div>
      <div class="item2">결제 완료 후 즉시 사용할 수 있습니다.</div>
    </div>
    <div class="border">
      <div class="item">취소 반품</div>
      <div class="item2">디지털 아이템 등 구입 후 고객의 사정에 의한 캔슬 · 반품은받을 수 없습니다.</div>
    </div>
    <div class="border">
      <div class="item">지원 기기 · 작업 환경</div>
      <div class="item2">iOS10.0 이상 Android6.00 이상</div>
    </div>
  </div>
</article>
<article *ngIf="lang==='zh'">
  <h1> 基於特定商業交易法的註釋</h1>
  <br>
  賣方的名稱，地址和電話營業電話：
  <br>
  <br>
  東京都江東區青海2-7-4 135-0064 THESOHO706<br>
  聯繫電話：我們將根據要求立即披露信息。<br>
  *我們不提供電話支持。<br>

  <div class="container">
    <div class="border">
      <div class="item">代表姓名</div>
      <div class="item2">代表董事/首席執行官佐佐木幸紀</div>
    </div>
    <div class="border">
      <div class="item">聯繫我們</div>
      <div class="item2">info@kawaiijapan.co.jp</div>
    </div>
    <div class="border">
      <div class="item">售價</div>
      <div class="item2">
        <strong>在購買過程中，它將顯示在屏幕上。</strong>
        <br>
        ※当アプリのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となる
        <br>
        客戶負責瀏覽此應用程序的頁面，購買內容，下載軟件等所需的互聯網連接費，通訊費等。
        <br>
        請聯繫您的互聯網提供商或移動運營商以獲取相應費用。
      </div>
    </div>
    <div class="border">
      <div class="item">付款方法</div>
      <div class="item2">Google Play或App Store指定的付款方式。</div>
    </div>
    <div class="border">
      <div class="item">付款時間</div>
      <div class="item2">它取決於付款方式中指定的付款期限。</div>
    </div>
    <div class="border">
      <div class="item">提供時間</div>
      <div class="item2">完成付款程序後，您可以立即使用它。</div>
    </div>
    <div class="border">
      <div class="item">取消/退貨</div>
      <div class="item2">
        購買數碼產品等後，由於客戶的方便而導致的取消和退貨不被接受。
      </div>
    </div>
    <div class="border">
      <div class="item">兼容的終端/操作環境</div>
      <div class="item2">iOS 10.0或更高版本，Android 6.00或更高版本</div>
    </div>

  </div>
</article>
<article *ngIf="lang==='zh-TW'">
  <h1> 基于特定商业交易法的注释</h1>
  <br>
  卖方的名称，地址和电话号码公司电话号码：
  <br>
  <br>
  联系电话：当您提出要求时，我们将立即予以披露。<br>
  聯繫電話：我們將根據要求立即披露信息。<br>
  **我们不提供电话支持。<br>

  <div class="container">
    <div class="border">
      <div class="item">代表姓名</div>
      <div class="item2">代表董事/代表董事/首席执行官佐佐木幸纪</div>
    </div>
    <div class="border">
      <div class="item">联系我们</div>
      <div class="item2">info@kawaiijapan.co.jp</div>
    </div>
    <div class="border">
      <div class="item">售价</div>
      <div class="item2">
        <strong>在购买过程中，它将显示在屏幕上。</strong>
        <br>
        ※当アプリのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となる
        <br>
        客户负责浏览此应用程序的页面，购买内容，下载软件等所需的互联网连接费，通讯费等。
        <br>
        请联系您的互联网提供商或移动运营商以获取相应费用。
      </div>
    </div>
    <div class="border">
      <div class="item">付款方法</div>
      <div class="item2">Google Play或App Store指定的付款方式。。</div>
    </div>
    <div class="border">
      <div class="item">付款时间</div>
      <div class="item2">它取决于付款方式中指定的付款期限。</div>
    </div>
    <div class="border">
      <div class="item">提供时间</div>
      <div class="item2">完成付款程序后，您可以立即使用它。</div>
    </div>
    <div class="border">
      <div class="item">取消/退货/退貨</div>
      <div class="item2">
        购买数码产品等后，由于客户的方便而导致的取消和退货不被接受。
      </div>
    </div>
    <div class="border">
      <div class="item">兼容的终端/操作环境兼容的终端/操作环境</div>
      <div class="item2">iOS 10.0或更高版本，Android 6.00或更高版本</div>
    </div>

  </div>
</article>
