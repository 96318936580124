export const environment = {
  version: '0.0.0',
  production: false,
  apiUrl: 'http://18.182.126.196',
  AES_KEY: 'zj291dfffd32591onmqulp5sdd7ckn2o',
  AES_IV: 'd5432gdoblyweo5v',
  SHOP_ID: '364519201',
  ACCESS_KEY: '38559832',
  firebase: {
    apiKey: "AIzaSyBICLygtX9Zrb1NDWi0Ijgxz7Pxh2jY5c0",
    authDomain: "cospo-42a4f.firebaseapp.com",
    databaseURL: "https://cospo-42a4f.firebaseio.com",
    projectId: "cospo-42a4f",
    storageBucket: "cospo-42a4f.appspot.com",
    messagingSenderId: "519438639354",
    measurementId: "G-1W87DZQV4Q"
  },
  twitter: {
    consumerKey: 'wc3TfOQ6ykdnKlV63NtBgs8Ew',
    consumerSecret: 'pVEryFW1TM3ocVDmOT2gLAK0MUz9hMXi9jAPVVcHWgSpyP3Ui5'
  },
  facebook: {
    key: '2816940148393940',
    secret: 'b6e1f7a07dde2e722b707f151c0ebc49'
  }
};
// apiUrl: 'http://18.182.126.196',
// export const environment = {
//   version: '0.0.0',
//   production: false,
//   apiUrl: 'http://192.168.1.18:6000',
//   AES_KEY: 'zj291dfffd32591onmqulp5sdd7ckn2o',
//   AES_IV: 'd5432gdoblyweo5v',
//   SHOP_ID: '364519201',
//   ACCESS_KEY: '38559832',
//   firebase: {
//     apiKey: "AIzaSyBICLygtX9Zrb1NDWi0Ijgxz7Pxh2jY5c0",
//     authDomain: "cospo-42a4f.firebaseapp.com",
//     databaseURL: "https://cospo-42a4f.firebaseio.com",
//     projectId: "cospo-42a4f",
//     storageBucket: "cospo-42a4f.appspot.com",
//     messagingSenderId: "519438639354",
//     measurementId: "G-1W87DZQV4Q"
//   },
//   twitter: {
//     consumerKey: '85AYa4yAaJWikw0lsIQYI1PKI',
//     consumerSecret: 'jLpkhyazdKaVibDEqoQuJFjuxsCyj0Ugzu54vCC9gHHFY1yr77'
//   },
//   facebook: {
//     key: '2816940148393940',
//     secret: 'b6e1f7a07dde2e722b707f151c0ebc49'
//   }
// };
