export const locale = {
  lang: 'ja',
  data: {
    code: "JAPANESE",
    languages: {
      en: "英語",
      ja: "日本語",
      fr: "フランス語",
      kor: "韓国",
      china: "簡体字中国語",
      china_tw: "繁体字中国語"
    },
    language: "Japanese",
    slogan: "お気に入りのコスプレモデルの投稿を発見してKAWAII(応援)しよう",
    menu: {
      about: "報",
      feed: "フィード",
      event: "イベント",
      ranking: "ランキング",
      ranking_past: "ランキング（過去)",
      news: "ニュース",
      movie: "映画",
      search_cosplayer: "コスプレイヤー検索",
      search_post: "投稿検索",
      my_page: "マイページ",
      favorite: "お気に入り",
      kawaii_history: "Kawaii歴史",
      kawaii_total: "Kawaii合計",
      cosplayer_apply: "投稿者申請",
      info_edit: "ユーザー情報編集",
      block_list: "ブロックリスト",
      login_setting: "パスワード変更",
      withdrawal: "退会"
    },
    footer: {
      term: "利用規約",
      contact_us: "問い合わせ",
      faq: "よくある質問",
      company: "法人の方",
      privacy: "個人情報の取り扱いについて",
      commercial_code: "特定商法取引法に基づく表示",
      company_info: "運営会社案内",
      shiketsuhou: "資金決算法に基づく表示"
    },
    login_twitter: "Twitterでサインインする",
    email: "メールアドレス",
    password: "パスワード",
    login: "ログイン",
    email_error: "正しいメールアドレスをご入力ください",
    password_min: "パスワードは4文字以上でご入力ください",
    forgot_password: "パスワードをお忘れの方はコチラ",
    register: "会員登録はコチラ",
    email_required: "メールアドレスは必須です",
    password_required: "パスワードは必須です",
    login_error: "メールアドレス、またはパスワードが違います",
    reset_password: "パスワードの再設定",
    reissue: "再発行",
    please_login: "ログインして下さい",
    register_title: "下記内容をご入力いただき「登録」を押してください。",
    nickname: "ニックネーム",
    nickname_min: "ニックネームは25文字以下でご入力ください",
    nickname_required: "ニックネームは必須です",
    confirm_password: "パスワード再確認",
    password_not_match: "パスワードが一致しません",
    agree_term: "利用規約に同意する",
    please_agree_term: "利用規約に同意してください",
    premiun_layer: "Premium",
    normal_layer: "Normal",
    next: "次へ",
    event: "イベント",
    period_of_accept: "受付期間",
    fan_ranking: "ファンランキング",
    post_login: "ログインして、『KAWAII』する",
    event_rankings: "Event ranking",
    today: "今日",
    week: "週",
    month: "月",
    rankings: "ランキング",
    normal_layer_feed: "ノーマルレイヤーフィード",
    points: "ポイント",
    more: "More",
    past_ranking: "過去ランキング",
    profile: "プロフィール",
    posts: "投稿一覧",
    number_result: "Kawaii数推移",
    back_list: "一覧に戻る",
    change_user_info: "ユーザー情報変更",
    avatar: "画像",
    message: "メッセージ",
    message_max: "ニックネームは1000文字以下でご入力ください",
    cosplayer_list: "コスプレイヤー一覧",
    search_result: "投稿検索結果",
    register_thank: "ご登録いただき、ありがとうございます。",
    click_mypage: "マイページはコチラ",
    kwaii_player: "KAWAIIプレイヤー一覧",
    empty_kwaii_player: "KAWAIIしたプレイヤーはいません",
    cosplayer_block: "ブロックコスプレイヤー",
    cosplayer_block_empty: "ブロックしているプレイヤーはいません",
    change_password: "パスワード変更",
    password_reconfirm: "パスワード再確認",
    change: "変更",
    new_post: "新規投稿",
    event_join: "イベント参加",
    posting_guide: "KAWAII獲得実績",
    kawaii_achivements: "投稿者説明",
    phone: "電話番号",
    orther_url: "その他URL",
    bank_name: "銀行名",
    branch_name: "支店名",
    branch_number: "支店番号",
    account_type: "口座種別（普通または当座）",
    account_number: "口座番号",
    account_name: "口座名義（カナ）",
    image: "画像",
    required: "※必須",
    please_select: "選択して下さい",
    content: "内容",
    post_to_twitter: "Twitterに投稿",
    post_subtitle: "※Twitter連携する場合は140文字以降の投稿は省略されます",
    post: "登録",
    post_confirm: "投稿してもよろしいでしょうか？",
    delete: "削除",
    edit: "編集",
    confirm_delete_post: "削除してもよろしいでしょうか？",
    post_edit: "投稿編集",
    join_event: {
      title: '参加可能なイベント',
      subtitle: "※イベント参加後の取り消しはできません。",
      start: "エントリー開始",
      participate: "参加する",
      participating: "参加中"
    },
    reception_period: "受付期間",
    level: "レベル",
    next_lever: "次のレベルまであと",
    my_page: {
      position: "位",
      great_game: "ログインボーナス",
      continue_login: "連続ログイン",
      great_app: "通算ログイン",
      we_have_won: "獲得しました。",
      day_two: "日目"
    },
    post_detail: {
      title: "投稿の詳細",
      post_reflected: "この投稿のKAWWAIIが反映されるエントリー中のランキング",
      congratulations: "おめでとう！",
      level_is: "レベルが、",
      we_help_you: "に上がりました！",
      stamina: "スタミナが、",
      from: "から",
      kwaii_not: '『KAWAII』できません!',
      current_point: "現在のポイント",
      bottom_page: "ページ下部から",
      with: "を使って",
      kwaii_possible: '『KAWAII』が可能です',
      point_purchase: "ポイント購入画面はこちら",
      number_of_point: "ポイント数",
      retention_points: "保持ポイント",
      input_limit: "入力上限",
      violation: "！違反を通報する",
      please_enter_points: "ポイントを入力してください。",
      enter_points: "10の倍数でポイントを入力してください。",
      points_not_enough: "ポイントが足りません。",
      max_lever_up: "2レベルアップ以上のポイント数が入力されています。最大1レベルアップのポイント数を入力してください。"
    },
    purchase: {
      add_point: "ポイント追加",
      bonus_point: "購入するとボーナスポイントが付与されます。",
      enought_to_buy: "まとめて購入するほどお得となっています。",
      prossetion: "所持ポイント",
      buy_with_paypal: "PayPalで購入",
      view_advertisement: "サービス利用でポイントをゲット",
      after_click: "※上のボタンをクリック後、次の画面で",
      email_password_required: "COSPOのID(メールアドレス)とパスワードを入力して",
      please_login: "ログインして下さい。（初回のみ）"
    },
    logout_confirm: "本当にログアウトしてもよろしいでしょうか？",
    unsubscribe_confirm: "本当に退会してもよろしいでしょうか？",
    withdrawal_thank: "ご利用ありがとうございました。",
    notify: {
      title: '通知',
      cancel: "キャンセル",
      close: "閉じる",
      confirm: "確認"
    },
    about: {
      how_to_start: "はじめかた",
      post_title: "COSPOに写真を投稿して楽しむには、投稿者申請が必要です。",
      kawaii: "KAWAIIする",
      support_your_player: '“KAWAII”でお気に入りのレイヤーを応援しよう!',
      recover_in: "KAWAIIは10分で1KAWAII回復します。KAWAIIはポイントを使って回復することもできます。",
      put_together: "所持しているポイントを使ってまとめてKAWAIIをすることができます。",
      kawaii_to_point: "1KAWAII＝10ポイント換算",
      kwaii_lever_up: "まとめてKAWAIIはレベルアップする手前の数値までしか入力できません。",
      input_limit: "入力上限表示がレベルアップまでの残数を表示しています。",
      example_input_limit: "例：入力上限が100ptの場合",
      lever_up_kwaii: "あと10回のKAWAIIでレベルアップしKAWAIIが全回復するため、",
      can_only_put_together: "90ptを使用して9KAWAIIまでしかまとめてKAWAIIすることができません。",
      great_game: "ログインボーナス",
      first_login_change: "日付が変わって最初のログイン時は、ログインボーナス画面が表示されます。",
      login_day_total: "現在の連続ログイン日数/通算ログイン日数/獲得ポイントが表示されます。",
      lever_up: "レベルアップ",
      regularly_hosts: "COSPOでは定期的に、様々なイベントを開催しています。",
      many_types: "イベントの内容は雑誌掲載やイベント出演など多くの種類があり",
      determined_from_user: "誰が選ばれるかはユーザーのみなさまからのKAWAIIで決まります。",
      dream_layer_san: "あなたのKAWAIIが、レイヤーさんの夢へと繋がる一歩になるかもしれません！",
      event_page: "新しいイベントの開催時は、ニュースやイベントページ内でお知らせ致します。",
      in_addition: "また、COSPOが配信するメールマガジンでも随時お知らせ致します。",
      event_and_daily: "ランキングはイベントのものと、デイリー・ウィークリー・マンスリーのものが表示されます。",
      ranking_vary_according: "順位はKAWAII数に応じて変動します。",
      ranking_is_update: "ランキングは毎時0分に更新され、１時間ごとのポイントが反映されます。",
      daily: "デイリー",
      weekly: "ウィークリー",
      monthly: "マンスリー",
      in_aggregate: "で集計",
      weekly_day: "毎週日曜日　0:00〜土曜日23:59の期間で集計",
      monthly_day: " 毎月1日　0:00〜月末日　23:59の期間で集計",
      kwaii_article: "記事に対するKAWAIIは即時反映されます。",
      fan_ranking: "ファンランキングは、投稿にKAWAIIしたユーザーのランキングです。",
      in_addition_totals: "デイリー・ウィークリー・マンスリー・累計のほか、前日のランキングも見ることができます。",
      in_addition_events: "また、イベントなどのランキングに対してのファンランキングも見ることができます。",
      twitter_cooperation: "Twitter連携機能を使用する際は、140文字を超えてしまうと全文反映されませんのでお気をつけください。",
      url_post_comment: "投稿コメントにURLを記載した場合、自動でリンクされます。",
      post_title_1: "投稿後、紐付けができていると投稿された画面の下部に対応しているイベントが表示されます。",
      post_title_2: "１つの投稿につき１つのイベントを紐付けできます。",
      post_title_3: "同期間に複数のイベントに参加されている場合、それぞれに応じた投稿が必要になります。",
      post_title_4: "投稿時に設定していない場合、KAWAIIされてもイベントポイントとして反映されていませんのでご注意ください。",
      post_title_5: "あとからポイントを反映させることはできませんので、必ず紐付けできているかご確認ください。",
      post_ranking: "投稿者ランク",
      to_cospo: "COSPOには",
      premium_rank: "プレミアムランクとノーマルランクの２種類があります。",
      participate_rank: "ランクによって参加できるイベントに制限がございます。",
      about_ranking: "プレミアムへのランクアップについて",
      rank_normal: "ノーマルランクからプレミアムへはランクアップすることができます。",
      monthly_ranking: "マンスリーランキング５位以内にランクインした場合",
      after_submit_application: "投稿者申請後、運営側の審査を通過した場合",
      rank_up_only: "審査については通過した場合のみランクアップし、運営よりご連絡いたします。",
      please_note: "審査基準や結果についてはお答えできかねますので、ご了承ください。",
      point_bonus: "ポイント報酬",
      kwaii_sent_viewer: "COSPOでは、投稿者の方に対して閲覧ユーザーから送られるKAWAIIのうち、",
      kwaii_sent_points: "有償ポイントを使用して送られたKAWAII（有償KAWAII）の数に応じ",
      we_will_reward: "報酬をお渡し致します。",
      kwaii_free_points: "※無償ポイントでのKAWAIIは報酬換算されません。",
      cash_value: "換金額",
      kwaii_to_yen: "1有償KAWAII = 1円",
      point_bonus_1: "取得されたKAWAIIは、運営で毎月末締めにて集計し、翌々月末日にお振込となります。",
      point_bonus_2: "報酬支払い該当者にのみ、振込後運営からご連絡致しますが、口座が未登録の場合、",
      point_bonus_3: "お支払いが遅れる場合がございます。",
      point_bonus_4: "振込口座については、サイドバーのプロフィール欄から、振込先の銀行名・支店名・口座番号・口座名義をご登録ください。",
      point_bonus_5: "報酬は10,000有償KAWAII以上で支払い対象となります。",
      point_bonus_6: "取得数が10,000有償KAWAIIに満たない場合、翌月以降に集計を持ち越し、取得数が合計10,000有償KAWAIIを達成した月に支払い手続きが可能になります。",
      point_bonus_7: "今後、振込手数料をご負担頂ける場合のみ、10,000有償KAWAIIに満たない場合もお振込対応が可能になる予定です。",
      point_bonus_8: "また、今後は支払い申請を行うと振込がされるよう変更を予定しております。",
      point_bonus_9: "取得されたKAWAIIの累計は、サイドバー“KAWAII獲得実績”からご確認いただけます。",
      line: "投稿者専用LINE@",
      line_title_1: "COSPOでは、投稿者のみなさまへのアナウンスや回答をスムーズに行うために、",
      line_title_2: "投稿者専用のLINE@をご用意しております。",
      line_title_3: "メールでもお問い合わせを受け付けておりますが、イベントで選出された際やお問い合わせの際、",
      line_title_4: "主にこちらのLINE＠を利用してご連絡致しますので",
      line_title_5: "投稿者登録が完了しましたら、LINE@へのご登録もお願い致します。"
    },
    raking_info: "本日{{dailyRank}}位／ 今月{{monthlyRank}}位",
    update: "更新",
    pointCheertext: "{{point}}PTを使用して{{cheerCount}}kawaiiしました",
    contact: {
      title: "連絡先",
      contact_email: "問い合わせメール",
      description: "お問い合わせは下記メールへお問合わせください。"
    },
    reported: "通報しました",
    sent_reset_password: 'メールアドレスにパスワードを送りました。',
    kawaii_history: "Kawaiiした投稿一覧",
    favorite_player: "お気に入りレイヤー一覧",
    empty_favorite_player: "お気に入りレイヤーはいません",
    empty_kawaii_history: "KAWAIIした投稿はありません",
    aggregation_period: "集計期間",
    premium_player_feed: "プレミアムレイヤーフィード",
    number_of_kwaii: "Kawaii数推移",
    ranking_result: "ランキング実績",
    ranking_achievements: "ランキング実績",
    paid: "有償KAWAII",
    free: "Free",
    player_apply: {
      title: "投稿者登録（コスプレイヤー/VTuber）",
      name: "本名（非表示）",
      name_mandatory: "氏名は必須です",
      name_error: "氏名は25文字以下でご入力ください",
      name_kana: "本名カナ（非表示）",
      name_kana_mandatory: "氏名カナは必須です",
      name_kana_error: "氏名カナは25文字以下でご入力ください",
      gender: "性別(非表示)",
      women: "女性",
      men: "男性",
      blood_type: "お住まいの都道府県",
      address: "お住まいの都道府県",
      type: "{{value}}型",
      area: "活動地域",
      country: "国",
      birthday: "生年月日",
      birthday_mandatory: "生年月日",
      phone: "電話番号(非表示)",
      twitter_username: "Xユーザ名",
      twitter_username_required: "Xユーザ名は必須です(例：@cospo)",
      player_type: "活動* コスプレイヤー/VTuber選択",
      player_type_error: "活動* コスプレイヤー/VTuber選択は必須です",
      cosplayer: "コスプレイヤー",
      vtuber: "VTuber",
      instagram: "Instagramアカウント名",
      url_name: "その他ＵＲＬ.",
      cosplay_history: "活動歴",
      favorite: "好きなキャラクター名、タイトル",
      message: "メッセージ、プロフィール紹介など",
      message_mandatory: "プロフィール紹介は必須です",
      message_error: "氏名は1000文字以下でご入力ください",
      when_submitter: "投稿者申請を知った場所",
      social: "SNSやWEB等",
      cosplayer_archive: "コスプレイヤーズアーカイブ",
      invitation: "KAWAIIJAPANからのお誘い",
      acquaintance: "知人の紹介",
      other: "その他",
      name_introducer: "紹介者のお名前",
      application: "選択"
    },
    daily_ranking: {
      title: "デイリーランキング",
      one: "1位",
      two: "2位",
      three: "3位",
      place: "回"
    },
    monthly_ranking: {
      title: "월간 マンスリーランキング",
      one: "1位～3位",
      two: "4位～10位",
      three: "11位～50位",
      place: "回"
    },
    login_meta: {
      meta1: `アプリで既にユーザー会員登録済みの方はメールアドレスとパスワードでログインできます。『Twitterでサインインする』にて連携しておくとTwitterにてサインイン出来る様になります。`,
      meta2: "新規ユーザーの方は<br>『会員登録はコチラ』から新規登録をして頂いた後、メールアドレス、パスワード及びtwitterにてログインできます。",
      meta3: "新規会員登録について",
      meta4: "アプリでの会員登録者様への先行リリースをしておりますので新規会員登録の方はお手数ですが、『COSPO』アプリより会員登録をお願い致します。"
    },
    favorite: "☆お気に入り",
    noFavorite: "★お気に入り",
    block: "ブロックする",
    unblock: "ブロック解除",
    connect_twitter_success: "Twitterの成功を接続します。",
    connect_twitter: "Twitterアカウント連携を行う",
    register_sub: `※尚、WEB版からの新規会員登録はアカウント認証、不正防止の為、<span class="app-color">Twitterアカウント連携が必須</span>となっております。Twitterアカウントをお持ちでない方はあらかじめTwitterアカウント取得後、会員登録ください。`,
    confirm_connect_twitter: "登録を完了するには、Twitter連携が必須です。",
    please_connect_twitter: "引き続き、Twitterアカウント連携を行ってください。",
    play_guide: "プレイガイド",
    cosplayer_guide : "コスプレガイド"
  },
};
