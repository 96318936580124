<div id="body-inner">
  <div id="header" *ngIf="currentRouter!=='/coming-soon'">
    <div class="header_box">
      <div class="logo_box">
        <span class="header_text">{{'slogan'|translate}}<span class="app-color">♥</span>
        </span>
      </div>
      <div class="logo">
        <a routerLink="/">
          <img src="/assets/header/cospo_title.png" alt="cospoロゴ">
        </a>
        <div class="search_pc">
          <div class="cearchkakoi">
            <form class='search2' action method="get">
              <input type="text" name="search" placeholder="{{'menu.search_cosplayer'|translate}}"
                [(ngModel)]="queryPlayer" autocomplete="off">
              <button (click)="searchPlayer()"></button>
            </form>
          </div>
          <div class="cearchkakoi">
            <form class='search' action method="get">
              <input type="text" name="search" placeholder="{{'menu.search_post'|translate}}" [(ngModel)]="query"
                autocomplete="off">
              <button (click)="searchPost()"></button>
            </form>
          </div>
        </div>
      </div>

      <div class="navi_mobile">
        <div *ngIf="sessionService.session.login; then LogBlock else LogElseBlock "></div>
        <ng-template #LogBlock>
          <a (click)="logout()">
            <img src="/assets/header/key.png" alt="キー" style="width:1.3em;">
          </a>
        </ng-template>
        <ng-template #LogElseBlock>
          <a routerLink="/login" routerLinkActive="active-link">
            <img src="/assets/header/key.png" alt="キー" style="width:1.3em;">
          </a>
        </ng-template>
        <a class="language" style="margin-top: 5px;">
          <a [matMenuTriggerFor]="appMenu">{{lang.toUpperCase()}}</a>
        </a>
        <a *ngIf="!sessionService.session.login" routerLink="register" routerLinkActive="active-link">
          <img src="/assets/header/first.png" alt="登録" style="width:1.3em;">
        </a>
      </div>

      <div class="switchbox" [ngClass]="{'left-70': !sessionService.session.login}">
        <input type="checkbox" id="label1">
        <label for="label1">
          <img src="/assets/header/serch.png" alt="サーチ" style="width:1.3em;">
        </label>

        <div class="form_mobile">
          <form class='search2' (submit)="searchPlayer()">
            <input type="text" name="search" placeholder="{{'menu.search_cosplayer'|translate}}"
              [(ngModel)]="queryPlayer" autocomplete="off">
          </form>
          <form class='search' (submit)="searchPost()">
            <input type="text" name="search" placeholder="{{'menu.search_post'|translate}}" [(ngModel)]="query"
              autocomplete="off">
          </form>
        </div>
      </div>
    </div>
    <div class="navinew" id="nav-cospo" *ngIf="currentRouter!=='/coming-soon'">
      <div class="nav-wrap">
        <div class="scroll-nav">
          <ul>
            <li>
              <a routerLink="/about" routerLinkActive="active-link">
                About
              </a>
            </li>
            <li>
              <a routerLink="/gold-post" routerLinkActive="active-link">
                Feed
              </a>
            </li>
            <li>
              <a routerLink="/event" routerLinkActive="active-link">
                Event
              </a>
            </li>
            <li>
              <a routerLink="/ranking" routerLinkActive="active-link">
                <!-- <span class="menu_text" translate="menu.ranking"></span> -->
                Ranking
              </a>
            </li>
            <li>
              <a routerLink="/past-ranking" routerLinkActive="active-link">
                Ranking (past)
              </a>
            </li>
            <li>
              <a routerLink="/news" routerLinkActive="active-link">
                News
              </a>
            </li>
            <li>
              <a routerLink="/movie" routerLinkActive="active-link">
                Movie
              </a>
            </li>
            <li class="sumahoiran">
              <div *ngIf="sessionService.session.login; then LogBlock else LogElseBlock "></div>
              <ng-template #LogBlock>
                <a (click)="logout()">
                  <img src="/assets/header/key.png" alt="キー" style="width:1.3em;margin-bottom:-0.1em;">
                </a>
              </ng-template>
              <ng-template #LogElseBlock>
                <a routerLink="/login" routerLinkActive="active-link">
                  <img src="/assets/header/key.png" alt="キー" style="width:1.3em;margin-bottom:-0.1em;">
                </a>
              </ng-template>
            </li>
            <li *ngIf="!sessionService.session.login" class="sumahoiran">
              <a routerLink="register" routerLinkActive="active-link">
                <img src="/assets/header/first.png" alt="登録" style="width:1.3em;margin-bottom:-0.1em;">
              </a>
            </li>
            <li class="language">
              <a [matMenuTriggerFor]="appMenu">{{'language'|translate}}</a>
            </li>
          </ul>
        </div>
        <div class="next-btn">＞</div>
      </div>
    </div>
  </div>
  <mat-menu #appMenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="changeLanguage('ja','JAPANESE')">{{'languages.ja'|translate}}</button>
    <button mat-menu-item (click)="changeLanguage('en','ENGLISH')">{{'languages.en'|translate}}</button>
    <button mat-menu-item (click)="changeLanguage('fr','FRENCH')">{{'languages.fr'|translate}}</button>
    <button mat-menu-item (click)="changeLanguage('kor','KOREAN')">{{'languages.kor'|translate}}</button>
    <button mat-menu-item (click)="changeLanguage('zh','CHINESE')">{{'languages.china'|translate}}</button>
    <button mat-menu-item (click)="changeLanguage('zh-TW','CHINESE_TW')">{{'languages.china_tw'|translate}}</button>
  </mat-menu>
  <div *ngIf="sessionService.session.login; then userBlock else userElseBlock"></div>
  <ng-template #userBlock>
    <span class="openbtn" (click)="openNav()">
      <img src="/assets/header/menu_b.png" alt="ナビボタン" style="width:45px;">
    </span>
    <div id="mySidenav" class="sidenav" #nav>
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">
        &times;
      </a>
      <div class="user-info">
        <div class="image">
          <img class="user-image" alt="name" [src]="user?.thumbnail||user?.image">
        </div>
        <div class="name">{{ sessionService.session.name }}</div>
        <div>
          Lv
          <span class="level">{{ sessionService.session.level }}</span>
        </div>
        <div class="bar_box">
          <span class="app-color">♥</span>
          <span class="progress_bar">
            <div class="progress" [ngStyle]="{ 'width': sessionService.session.cheerBar + '%' }"></div>
          </span>
          {{ sessionService.session.cheerCount }}
        </div>
        <div>
          {{'next_lever'|translate}}
          <span class="next-level">{{ sessionService.session.nextLevel }}♥</span>
        </div>
      </div>
      <app-user-menu (click)="closeNav()" [session]="sessionService.session"></app-user-menu>
    </div>
    <div id="sidenav-close" (click)="closeNav()"></div>
  </ng-template>
  <ng-template #userElseBlock></ng-template>
  <div class="main_content">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <footer *ngIf="currentRouter!=='/coming-soon'">
    <div class="footer_pc">
      <div class="left">
        <span class="logo">
          <a routerLink="/">
            <img src="/assets/header/cospo_title.png" alt="cospoロゴ">
          </a>
        </span>
        <a href="https://www.facebook.com/kawaii.jp.cospo">
          <i class="fa fa-facebook" aria-hidden="true" style="font-size: 48px;color:#787878 ;"></i>
        </a>
        <a href="https://twitter.com/kawaii_jp_cospo">
          <i class="fa fa-twitter" aria-hidden="true" style="font-size: 48px;color:#787878 ;"></i>
        </a>
        <a href="https://www.instagram.com/kawaiijapan.cospo/">
          <i class="fa fa-instagram" aria-hidden="true" style="font-size: 48px;color:#787878 ;"></i>
        </a>
        <a href="https://apps.apple.com/jp/app/id1117580938" target="_blank">
          <img src="/assets/footer/apple.png" alt="appleapp" style="width:190px;margin:0 0 0 200px;">
        </a>
        <a href="https://play.google.com/store/apps/details?id=net.cospo&hl=ja" target="_blank">
          <img src="/assets/footer/googleplay.png" alt="appleapp" style="width:190px;">
        </a>
      </div>
      <div class="footernavi">
        <a routerLink="/policy" translate="footer.term"></a>
        &emsp;/&emsp;
        <a routerLink="/contact" translate="footer.contact_us"></a>
        &emsp;/&emsp;
        <a routerLink="/faq" translate="footer.faq"></a>
        &emsp;/&emsp;
        <a routerLink="/corp" translate="footer.company"></a>
        &emsp;/&emsp;
        <a routerLink="/privacy" translate="footer.privacy"></a>
        &emsp;/&emsp;
        <a routerLink="/commercial-code" translate="footer.commercial_code"></a>
        &emsp;/&emsp;
        <a routerLink="/shiketsuhou" translate="footer.shiketsuhou"></a>
        &emsp;/&emsp;
        <a routerLink="/company" translate="footer.company_info"></a>
        <br>
        © 2016-2019 COSPO
      </div>
    </div>

    <div class="footer_mobile">
      <div class="logo">
        <a routerLink="/">
          <img src="/assets/header/cospo_title.png" alt="cospoロゴ">
        </a>
      </div>
      <br>
      <div class="footericon">
        <a href="https://www.facebook.com/kawaii.jp.cospo">
          <i class="fa fa-facebook" aria-hidden="true" style="font-size: 48px;color:#787878 ;"></i>
        </a>
        <a href="https://twitter.com/kawaii_jp_cospo">
          <i class="fa fa-twitter" aria-hidden="true" style="font-size: 48px;color:#787878 ;"></i>
        </a>
        <a href="https://www.instagram.com/kawaiijapan.cospo/">
          <i class="fa fa-instagram" aria-hidden="true" style="font-size: 48px;color:#787878 ;"></i>
        </a>
      </div>
      <div class="footernavi">
        <a routerLink="/policy" translate="footer.term"></a>
        <a routerLink="/contact" translate="footer.contact_us"></a>
        <a routerLink="/faq" translate="footer.faq"></a>
        <a routerLink="/corp" translate="footer.company"></a>
      </div>
      <div class="footernavi2">
        <a routerLink="/privacy" translate="footer.privacy"></a>
        <a routerLink="/commercial-code" translate="footer.commercial_code"></a>
        <a routerLink="/shiketsuhou" translate="footer.shiketsuhou"></a>
        <a routerLink="/company" translate="footer.company_info"></a>
        <br>
      </div>

      <div class="applink">
        <a href="https://apps.apple.com/jp/app/id1117580938" target="_blank">
          <img src="/assets/footer/apple.png" alt="appleapp" style="width:43%;">
        </a>
        <a href="https://play.google.com/store/apps/details?id=net.cospo&hl=ja" target="_blank">
          <img src="/assets/footer/googleplay.png" alt="appleapp" style="width:43%;">
        </a>
      </div>
      <div>© 2016-2019 COSPO</div>

    </div>

  </footer>
</div>
