export const locale = {
  lang: 'kor',
  data: {
    code: "KOREAN",
    languages: {
      en: "영어",
      ja: "일본어",
      fr: "프랑스 국민",
      kor: "대한민국",
      china: "중국어 간체",
      china_tw: "중국어 번체"
    },
    language: "Korea",
    slogan: "좋아하는 코스프레 모델의 게시물을 발견하고 KAWAII (응원)하려고",
    menu: {
      about: "약",
      feed: "먹이다",
      event: "행사",
      ranking: "순위",
      ranking_past: "랭킹 (과거)",
      news: "뉴스",
      movie: "영화",
      search_cosplayer: "플레이어 검색",
      search_post: "게시물 검색",
      my_page: "나의 페이지",
      favorite: "인기 있는 말",
      kawaii_history: "가와이이 역사 history",
      kawaii_total: "카와이 토탈",
      cosplayer_apply: "코스 플레이어 적용",
      info_edit: "정보 편집",
      block_list: "차단리스트",
      login_setting: "로그인 설정",
      withdrawal: "철수"
    },
    footer: {
      term: "이용 약관",
      contact_us: "문의하기",
      faq: "자주하는 질문",
      company: "법인",
      privacy: "은둔",
      commercial_code: "특정 상법에 근거한 표시",
      company_info: "운영 회사에 대한 정보",
      shiketsuhou: "펀드 폐쇄 법에 따른 표시"
    },
    login_twitter: "Sign In With Twitter",
    email: "이메일",
    password: "암호",
    login: "로그인",
    email_error: "정확한 이메일 주소를 입력하십시오",
    password_min: "비밀번호는 4 자 이상이어야합니다",
    forgot_password: "비밀번호를 잊어 버렸습니까",
    register: "기재",
    email_required: "이메일 주소가 필요합니다",
    password_required: "비밀번호가 필요합니다",
    login_error: "이메일 주소 또는 비밀번호가 다릅니다",
    reset_password: "비밀번호를 재설정",
    reissue: "재발행",
    please_login: "로그인 해주세요",
    register_title: '다음 내용을 입력하고 "등록".',
    nickname: "별명",
    nickname_min: "닉네임을 25 자 이하로 입력하십시오",
    nickname_required: "닉네임이 필요합니다",
    confirm_password: "비밀번호 확인",
    password_not_match: "비밀번호가 일치하지 않습니다",
    agree_term: "이용 약관에 동의",
    please_agree_term: "이용 약관에 동의하십시오",
    premiun_layer: "프리미엄 레이어",
    normal_layer: "일반 레이어",
    next: "다음",
    event: "행사",
    period_of_accept: "수용 기간",
    fan_ranking: "팬 랭킹",
    post_login: '"KAWAII"에 로그인',
    event_rankings: "이벤트 랭킹",
    today: "오늘",
    week: "주",
    month: "달",
    rankings: "랭킹",
    normal_layer_feed: "일반 레이어 피드",
    points: "포인트들",
    more: "더",
    past_ranking: "지난 순위",
    profile: "프로필",
    posts: "게시물 목록",
    number_result: "수 동향 / 결과",
    back_list: "다시 목록으로",
    change_user_info: "사용자 정보 변경",
    avatar: "화신",
    message: "메시지",
    message_max: "닉네임을 1000 자 이하로 입력하십시오",
    cosplayer_list: "코스프레리스트",
    search_result: "검색 결과",
    register_thank: "등록 해 주셔서 감사합니다.",
    click_mypage: "내 페이지를 보려면 여기를 클릭하십시오",
    kwaii_player: "선수 목록",
    empty_kwaii_player: "KAWAII 플레이어가 없습니다",
    cosplayer_block: "코스 플레이어 차단",
    cosplayer_block_empty: "차단중인 플레이어가 없습니다.",
    change_password: "비밀번호 변경",
    password_reconfirm: "비밀번호 재확인",
    change: "변화",
    new_post: "새로운 게시물",
    event_join: "이벤트 참가",
    posting_guide: "게시 가이드",
    kawaii_achivements: "귀엽다 업적",
    phone: "전화",
    orther_url: "다른 URL",
    bank_name: "은행 이름",
    branch_name: "지점명",
    branch_number: "지점 번호",
    account_type: "계좌 유형 (보통 또는 초과 인출)",
    account_number: "계좌 번호",
    account_name: "계정 이름",
    image: "영상",
    required: "필수",
    content: "함유량",
    please_select: "선택 해주세요",
    post_to_twitter: "트위터에 게시",
    post_subtitle: "Twitter와 링크 할 때 140 자 이하의 게시물은 생략됩니다.",
    post: "우편",
    post_confirm: "게시 할 수 있습니까?",
    delete: "지우다",
    edit: "편집하다",
    confirm_delete_post: "삭제할 수 있습니까?",
    post_edit: "게시물 수정",
    join_event: {
      title: '참석할 수있는 이벤트',
      subtitle: "이벤트에 참여한 후 취소 할 수 없습니다.",
      start: "입장 시작 ",
      participate: "참가하다",
      participating: "참여"
    },
    reception_period: "접수 기간",
    level: "수평",
    next_lever: "다음 단계로",
    my_page: {
      position: "위치",
      great_game: "이것은 훌륭한 게임입니다.",
      continue_login: "지속적인 로그인",
      great_app: "훌륭한 앱입니다.",
      we_have_won: "우리는 이겼다.",
      day_two: "둘째 날"
    },
    post_detail: {
      title: "포스트 상세",
      post_reflected: "이 게시물의 KAWWAII는 항목의 순위에 반영됩니다",
      congratulations: "축하합니다",
      level_is: "레벨은",
      we_help_you: "우리는 당신을 돕기 위해 여기 있습니다.",
      stamina: "내구력",
      from: "에서",
      kwaii_not: 'KAWAII "할 수 없습니다!',
      current_point: "현재 포인트",
      bottom_page: "페이지 하단에서",
      with: "와",
      kwaii_possible: '「KAWAII」가 가능',
      point_purchase: "포인트 구입 화면은 이쪽",
      number_of_point: "포인트 수",
      retention_points: "보유 포인트",
      input_limit: "입력 한계",
      violation: "위반 신고",
      please_enter_points: "포인트를 입력하십시오.",
      enter_points: "10의 배수로 포인트를 입력하십시오.",
      points_not_enough: "요점은 충분하지 않습니다.",
      max_lever_up: "2 레벨 업 위의 포인트 수가 입력되었습니다. 최대 1 개의 레벨 업 포인트를 입력하십시오."
    },
    purchase: {
      add_point: "포인트 추가",
      bonus_point: "구매시 보너스 포인트가 부여됩니다.",
      enought_to_buy: "함께 구입할만큼 수익성이 높아졌습니다",
      prossetion: "보유 포인트",
      buy_with_paypal: "PayPal로 구매",
      view_advertisement: "View Advertisement",
      after_click: "다음 화면에서 위의 버튼을 클릭 한 후",
      email_password_required: "COSPO ID (이메일 주소) 및 비밀번호",
      please_login: "로그인하십시오 (처음으로 만)"
    },
    logout_confirm: "정말 로그 아웃 할 수 있습니까?",
    unsubscribe_confirm: "구독을 취소 할 수 있습니까?",
    withdrawal_thank: "좋은 경험이었습니다.",
    notify: {
      title: '공고',
      cancel: "취소",
      close: "닫기",
      confirm: "확인"
    },
    about: {
      how_to_start: "시작하는 방법",
      post_title: "사진을 게시하고 COSPO에서 즐거운 시간을 보내려면 컨트 리뷰 터를 신청해야합니다.",
      kawaii: "KAWAII",
      support_your_player: '"KAWAII"로 좋아하는 레이어를 지원하십시오!',
      recover_in: "KAWAII는 10 분 내에 1KAWAII를 복구합니다. KAWAII는 포인트를 사용하여 복구 할 수도 있습니다.",
      put_together: "가지고있는 포인트를 사용하여 KAWAII를 결합 할 수 있습니다.",
      kawaii_to_point: "1KAWAII = 10 점",
      kwaii_lever_up: "요약하면, KAWAII는 레벨 업 전에 숫자 만 입력 할 수 있습니다.",
      input_limit: "입력 제한 디스플레이는 레벨 업까지 남은 숫자를 보여줍니다.",
      example_input_limit: '예 : 입력 제한이 100pt 인 경우',
      lever_up_kwaii: "KAWAII 10 레벨을 더 높이고 KAWAII를 완전히 복구하려면",
      can_only_put_together: "9KAWAII를 사용하여 KAWAII를 최대 90pt까지만 구성 할 수 있습니다.",
      great_game: "이것은 훌륭한 게임입니다.",
      first_login_change: "날짜가 변경되고 첫 번째 로그인이되면 로그인 보너스 화면이 표시됩니다.",
      login_day_total: "현재 연속 로그인 일수 / 총 로그인 일수 / 적립 포인트를 표시합니다.",
      lever_up: "레벨 업",
      regularly_hosts: "COSPO는 정기적으로 다양한 행사를 주최합니다.",
      many_types: "잡지 출판 및 이벤트 출연과 같은 다양한 유형의 이벤트가 있습니다",
      determined_from_user: "선택 대상은 KAWAII에 의해 사용자로부터 결정됩니다.",
      dream_layer_san: "KAWAII는 레이어 샌의 꿈을 이끄는 단계 일 수 있습니다!",
      event_page: "새로운 이벤트를 개최하면 뉴스 및 이벤트 페이지에서 알려드립니다.",
      in_addition: "또한 COSPO가 배포하는 전자 메일 매거진에서 언제든지 알려드립니다.",
      event_and_daily: "순위는 이벤트 및 일일 주간 월간 보고서를 기반으로합니다.",
      ranking_vary_according: "KAWAII의 수에 따라 순위가 다릅니다.",
      ranking_is_update: "순위는 시간당 0 분으로 업데이트되며 1 시간 단위로 반영됩니다.",
      daily: "매일",
      weekly: "주간",
      monthly: "월간 간행물",
      in_aggregate: "종합적으로",
      weekly_day: "매주 일요일 0 : 00〜 총 토요일 23:59",
      monthly_day: "매월 1 일 0:00 〜 월말 23:59",
      kwaii_article: "기사에 KAWAII가 즉시 반영됩니다.",
      fan_ranking: "팬 순위는 게시물에서 KAWAII 사용자의 순위입니다.",
      in_addition_totals: "일일 주간 월간 총계 외에도 전날 순위를 확인할 수 있습니다.",
      in_addition_events: "또한 이벤트 등의 순위에 대한 팬 순위도 볼 수 있습니다.",
      twitter_cooperation: "Twitter 협력 기능을 사용하는 경우 140자를 초과하면 전체 텍스트에 반영되지 않으므로주의하십시오.",
      url_post_comment: "게시물 댓글에 URL이 표시되면 자동으로 연결됩니다.",
      post_title_1: "게시 후 연결이 가능한 경우 게시 된 화면 하단에 해당하는 이벤트가 표시됩니다.",
      post_title_2: "게시물 하나당 하나의 이벤트를 연결할 수 있습니다. 게시물 당 1 개의 이벤트.",
      post_title_3: "같은 기간 동안 여러 이벤트에 참여하는 경우 그에 따라 게시해야합니다.",
      post_title_4: "게시시 설정되지 않은 경우 KAWAII 인 경우에도 이벤트 포인트로 반영되지 않습니다.",
      post_title_5: "나중에 그 점을 반영 할 수 없으므로 페그가 가능한지 확인하십시오.",
      post_ranking: "순위 후",
      to_cospo: "코스 포에",
      premium_rank: "프리미엄 등급과 일반 등급에는 두 가지 유형이 있습니다.",
      participate_rank: "순위에 따라 행사에 참여할 수있는 제한 사항이 있습니다.",
      about_ranking: "보험료까지의 랭킹에 대해",
      rank_normal: "정상 등급에서 프리미엄까지 등급을 매길 수 있습니다.",
      monthly_ranking: '"５"의 위치 내에 순위를 매기면 월간 순위',
      after_submit_application: "신청서를 제출 한 후 행정부가 신청서를 검토했습니다.",
      rank_up_only: "심사를 통과 한 경우에만 순위가 정해지며 경영진으로부터 연락을 드릴 것입니다.",
      please_note: "선별 기준 및 결과에 답변 할 수 없습니다.",
      point_bonus: "포인트 보너스",
      kwaii_sent_viewer: "COSPO에서는 뷰어에서 게시 한 사람에게 보낸 KAWAII 중 하나입니다.",
      kwaii_sent_points: "유료 포인트를 사용하여 전송 된 KAWAII (유료 KAWAII) 수에 따라",
      we_will_reward: "우리는 당신에게 보상을 줄 것입니다.",
      kwaii_free_points: "* 무료 포인트의 KAWAII는 보상으로 전환되지 않습니다.",
      cash_value: "현금 가치",
      kwaii_to_yen: "유료 KAWAII = 1 엔",
      point_bonus_1: "취득한 KAWAII는 매월 말에 집계되어 다음 달 말에 이전됩니다.",
      point_bonus_2: "계정이 등록되지 않은 경우, 양도 후 작업에서 연락을 드리겠습니다.",
      point_bonus_3: "지불이 지연 될 수 있습니다.",
      point_bonus_4: "계좌 이체의 경우, 사이드 바의 프로파일 필드에서 계좌 이체의 은행 이름, 지점 이름, 계좌 번호, 계좌 이름을 등록하십시오.",
      point_bonus_5: "10,000 개 이상의 유료 KAWAII에 대해 보상이 지급됩니다.",
      point_bonus_6: "KAWAII의 수가 지불 된 KAWAII의 10,000 개 미만인 경우, 다음 달 후에 총계를 이월 할 수 있으며 지불 된 KAWAII의 총 수가 10,000에 도달 한 달에 지불 절차가 가능합니다.",
      point_bonus_7: "앞으로는 유료 KAWAII가 10,000 개 미만인 경우에도 양도 수수료를 낼 수있는 경우에만 양도를 처리 할 수 ​​있습니다.",
      point_bonus_8: "또한 향후 결제 신청이 완료 될 때까지 송금을 변경할 계획입니다.",
      point_bonus_9: `사이드 바 "KAWAII 획득 결과에서 획득 한 KAWAII의 누적 수를 확인할 수 있습니다.`,
      line: "LINE@",
      line_title_1: "작업을 최대한 활용하고 최대한 활용하는 좋은 방법입니다.",
      line_title_2: "전용 line @ contributor를 이용할 수 있습니다.",
      line_title_3: "우리는 이메일로 문의를 수락하지만, 행사에서 선출되거나 연락 할 때",
      line_title_4: "이 라인 @을 사용하여 연락 드리겠습니다.",
      line_title_5: "컨트 리뷰 터로 등록하면 LINE @에도 등록하십시오."
    },
    raking_info: "오늘 {{dailyRank}} 가격 / 월 {{monthlyRank}} 장소",
    update: "최신 정보",
    pointCheertext: "{{point}} PT를 사용하여 {{cheerCount}} kawaii를했습니다.",
    contact: {
      title: "접촉",
      contact_email: "이메일 연락처",
      description: "문의 사항이 있으시면 다음 이메일로 문의하십시오."
    },
    reported: "보고",
    sent_reset_password: '이메일 주소로 비밀번호를 보냈습니다.',
    kawaii_history: "Kawaii 님의 게시글 목록",
    favorite_player: "선호하는 레이어 목록",
    empty_favorite_player: "선호하는 레이어가 없습니다",
    empty_kawaii_history: "KAWAII의 게시물이 없습니다",
    aggregation_period: "집계 기간",
    premium_player_feed: "프리미엄 레이어 피드",
    number_of_kwaii: "가와이이 수",
    ranking_result: "순위 결과",
    ranking_achievements: "순위 성과",
    paid: "유료",
    free: "비어 있는",
    player_apply: {
      title: "코스 플레이어 등록",
      name: "이름 (표시되지 않음)",
      name_mandatory: "이름은 필수입니다",
      name_error: "이름은 25 자 이하로 입력하십시오",
      name_kana: "가나 이름 (표시되지 않음)",
      name_kana_mandatory: "가나의 이름은 필수입니다",
      name_kana_error: "25 자 이하로 성명 (가나)을 입력하십시오",
      gender: "성별 (숨김)",
      women: "여자들",
      men: "남자들",
      blood_type: "혈액형",
      address: "거주 중인 도도부현",
      type: "{{value}} 유형",
      area: "활동 분야",
      country: "국가",
      birthday: "생년월일",
      birthday_mandatory: "생년월일은 필수입니다",
      phone: "전화 번호 (숨김)",
      twitter_username: "트위터 아이디",
      twitter_username_required: "트위터 사용자 이름이 필요합니다 (예 : @ cospo)",
      player_type: "코스플레이어/버튜버",
      player_type_error: "코스플레이어/버튜버",
      cosplayer: "코스플레이어",
      vtuber: "버튜버",
      instagram: "Instagram 계정 이름",
      url_name: "URL 웹 사이트에 오신 것을 환영합니다.",
      cosplay_history: "코스프레의 역사",
      favorite: "좋아하는 캐릭터 이름, 제목",
      message: "메시지, 프로필 소개 등",
      message_mandatory: "프로필 소개는 필수입니다",
      message_error: "이름을 1000 자 미만으로 입력하십시오",
      when_submitter: "제출자 신청서에 대해 알게 된 곳",
      social: "소셜 미디어, 웹 등",
      cosplayer_archive: "코스프레 아카이브",
      invitation: "KAWAII JAPAN으로부터의 초대",
      acquaintance: "지인의 소개",
      other: "다른",
      name_introducer: "소개 자의 이름",
      application: "신청"
    },
    daily_ranking: {
      title: "월간 랭킹",
      one: "1位",
      two: "2位",
      three: "3位",
      place: "회"
    },
    monthly_ranking: {
      title: "월간 랭킹",
      one: "1 ~ 3",
      two: "4 ~ 10",
      three: "11 ~ 50",
      place: "회"
    },
    login_meta: {
      meta1: `응용 프로그램에서 이미 사용자 회원 가입하신 분은 이메일 주소와 비밀번호로 로그인 할 수 있습니다. "Twitter로 로그인」에서 연계 해두면 Twitter에서 로그인 할 수있게됩니다.`,
      meta2: "앱에서 로그인 정보를 등록 해 주시고있는 이용자는 등록 완료의 메일 주소, 비밀번호로 로그인 할 수 있습니다",
      meta3: "신규 회원 등록에 대해",
      meta4: `앱에서 회원 가입 자수의 선행 출시를하고 있으므로 신규 회원 등록 분은 번거로우 시겠지만, "COSPO"응용 프로그램에서 회원 가입을 부탁드립니다.`
    },
    favorite: "★ 인기 있는 말",
    noFavorite: "☆ 인기 있는 말",
    block: "블록",
    unblock: "차단 해제",
    connect_twitter_success: "트위터 성공 연결",
    connect_twitter: "트위터 연결",
    register_sub: `※ 단, WEB 판에서 신규 회원 등록은 계정 인증 부정 방지를 위해, <span class="app-color">Twitter 계정 연계가 필수가되고 있습니다</span>. Twitter 계정을 가지고 있지 않은 분은 미리 Twitter 어카운트 취득 후, 회원 등록하십시오.`,
    confirm_connect_twitter: "등록을 완료하려면 트위터에 연결하십시오",
    please_connect_twitter: "이어 Twitter 계정 연계 해주십시오.",
    play_guide: "플레이 가이드",
    cosplayer_guide : "코스플레이어 가이드"
  },
};
