<section>
	<h2 class="cospo-title">
		{{'contact.title'|translate}}
	</h2>
	<div class="contact">
		{{'contact.description'|translate}}<br>
		{{'contact.contact_email'|translate}}
		<a href="mailto:info@kawaiijapan.co.jp" class="app-color">info@kawaiijapan.co.jp</a>
	</div>
</section>
<!-- ?subject=【お問い合わせ】&body={{body}} -->