<div class="user_menu">
  <ul>
    <li>
      <a routerLink="/my-page" class="area-link"></a>
      <span class="icon">
        <img src="/assets/images/icon/mypage.png" alt="マイページ">
      </span>
      {{'menu.my_page'|translate}}
    </li>
    <li>
      <a routerLink="/favorite" class="area-link"></a>
      <span class="icon">
        <img src="/assets/images/icon/okini.png" alt="お気に入りレイヤー">
      </span>
      {{'menu.favorite'|translate}}
    </li>
    <li>
      <a routerLink="/kawaii-history" class="area-link"></a>
      <span class="icon">
        <img src="/assets/images/icon/kawaii.png" alt="KAWAIIした投稿">
      </span>
      {{'menu.kawaii_history'|translate}}
    </li>
    <li *ngIf="session.isLayer">
      <a routerLink="/kawaii-analysis" class="area-link"></a>
      <span class="icon">
        <img src="/assets/images/icon/ruikei.png" alt="累計KAWAII">
      </span>
      {{'menu.kawaii_total'|translate}}
    </li>
    <li>
      <a routerLink="/purchase" class="area-link"></a>
      <span class="icon">
        <img src="/assets/images/icon/kounyu.png" alt="ポイント購入">
      </span>
      {{'points'|translate}}
    </li>

    <div *ngIf="session.isLayer; then layerBlock else layerElseBlock"></div>
    <ng-template #layerBlock>
      <li><a routerLink="/my-posts" class="area-link"></a>{{'posts'|translate}}</li>
      <li><a routerLink="/event-join" class="area-link"></a>{{'event_join'|translate}}</li>
      <li><a routerLink="/kawaii-analysis" class="area-link"></a>{{'kawaii_achivements'|translate}}</li>
    </ng-template>
    
    <li>
      <a  class="area-link" href="https://kawaiijapan.co.jp/cospo/manual_user.pdf" target="_blank"></a>{{'play_guide'|translate}}
    </li>
    <li>
      <a class="area-link" href="https://kawaiijapan.co.jp/cospo/manual_cosplayer.pdf" target="_blank"></a>{{'cosplayer_guide'|translate}}
    </li>
    
    <ng-template #layerElseBlock>
      <li>
        <a routerLink="/player-apply" class="area-link"></a>
        <span class="icon">
          <img src="/assets/images/icon/sinsei.png" alt="投稿者申請">
        </span>
        {{'menu.cosplayer_apply'|translate}}
      </li>
    </ng-template>

    <li>
      <a routerLink="/user-edit" class="area-link"></a>
      <span class="icon">
        <img src="/assets/images/icon/settei.png" alt="ユーザー情報編集">
      </span>
      {{'menu.info_edit'|translate}}
    </li>
    <li>
      <a routerLink="/block-list" class="area-link"></a>
      <span class="icon">
        <img src="/assets/images/icon/block.png" alt="ブロックリスト">
      </span>
      {{'menu.block_list'|translate}}
    </li>
    <li>
      <a routerLink="/pass-edit" class="area-link"></a>
      <span class="icon">
        <img src="/assets/images/icon/settei.png" alt="パスワード変更">
      </span>
      {{'change_password'|translate}}
    </li>
    <li>
      <a routerLink="/withdrawal" class="area-link"></a>
      <span class="icon">
        <img src="/assets/images/icon/taikai.png" alt="退会">
      </span>
      {{'menu.withdrawal'|translate}}
    </li>
  </ul>
</div>
