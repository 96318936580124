import * as newsAction from '../actions/news.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';
export interface NewsReducer {
  news: Array<any>,
  hasNext: boolean,
  loading: boolean,
}
export const initialState: NewsReducer = {
  news: [],
  hasNext: false,
  loading: false
}
export function newsReducer(state = initialState, action: newsAction.NewsActions) {
  switch (action.type) {
    case newsAction.FETCH_NEWS:
      return {
        ...state,
        hasNext: false,
        loading: state.news.length === 0 ? true : false
      }
    case newsAction.FETCH_NEWS_SUCCESS:
      return {
        ...state,
        hasNext: action.hasNext,
        news: action.data,
        loading: false

      }
    default:
      return state;
  }
}
export const news = createFeatureSelector<NewsReducer>('news');
export const getNews = createSelector(news, (state: NewsReducer) => state);
