import { Injectable } from "@angular/core";
import { CacheCospoService } from "../services/cache.service";
import { Actions, Effect, ofType } from "@ngrx/effects";
import * as playerActions from "../actions/player.action";
import { mergeMap, map } from "rxjs/operators";

@Injectable()
export class PlayEffect {
  constructor(private api: CacheCospoService, private action$: Actions) { }

  @Effect()
  fetchPlayer$ = this.action$.pipe(
    ofType(playerActions.FETCH_PLAYER),
    mergeMap((payload: playerActions.FetchPlayers) => this.api.getPlayer(payload.page,payload.size,payload.query).pipe(
      map((res) => new playerActions.FetchPlayersSuccess(res.content, res.existNext))
    )
    )
  )
  @Effect()
  fetchPlayerMore$ = this.action$.pipe(
    ofType(playerActions.FETCH_PLAYER_MORE),
    mergeMap((payload: playerActions.FetchPlayers) => this.api.getPlayer(payload.page, payload.size, payload.query).pipe(
      map((res) => new playerActions.FetchPlayersMoreSuccess(res.content, res.existNext))
    )
    )
  )
  @Effect()
  fetchPlayerHistory$ = this.action$.pipe(
    ofType(playerActions.FETCH_PLAYER_HISTORY),
    mergeMap((payload: playerActions.FetchPlayerHistory) => this.api.getHistoryPlayer(payload.playerId).pipe(
      map((json) => new playerActions.FetchPlayerHistorySuccess(json))
    )
    )
  )
  @Effect()
  fetchPlayerFan$ = this.action$.pipe(
    ofType(playerActions.FETCH_PLAYER_FAN),
    mergeMap((payload: playerActions.FetchPlayerFan) => this.api.getPlayFan(payload.playerId).pipe(
      map((json) => new playerActions.FetchPlayerFanSuccess(json.playerRankings))
    )
    )
  )

  @Effect()
  $fetchPlayerDetail = this.action$.pipe(
    ofType(playerActions.FETCH_PLAYER_DETAIL),
    mergeMap((payload: playerActions.FetchPlayerDetail) => this.api.getPlayerDetail(payload.playerId).pipe(
      map(json => new playerActions.FetchPlayerDetailSuccess(json))
    ))
  )
}
