import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { TranslateService } from '@ngx-translate/core';
import { AuthActionTypes, LogIn, LogInSuccess, LogInFailure, LogInTwitter } from "../actions";
import { map, mergeMap, tap, catchError } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { SessionService } from "@app/@store/services/session.service";
import { User } from "../models/user";
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Injectable()
export class AuthEffects {

  constructor(
    private actions: Actions,
    private authService: AuthService,
    private sessionService: SessionService,
    private translateService: TranslateService
  ) { }

  @Effect()
  login$ = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN),
    mergeMap((data: LogIn) =>
      this.authService.logIn(data.payload).pipe(
        map((json: User) => json.success === 1 ? new LogInSuccess(json, data.prevUrl) : new LogInFailure(json)),
        catchError((error: HttpErrorResponse) => of(new LogInFailure(error.error)))
      ))
  )
  @Effect()
  loginTwitter = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_TWITTER),
    mergeMap((data: LogInTwitter) =>
      this.authService.loginTwitter(data.payload).pipe(
        map((json: User) => json.success === 1 ? new LogInSuccess(json, data.prevUrl) : new LogInFailure(json)),
        catchError((error: HttpErrorResponse) => of(new LogInFailure(error.error)))
      )
    )
  )
  @Effect({ dispatch: false })
  loginSuccess$ = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((data: LogInSuccess) => {
      this.sessionService.login(data.payload.userHash, data.payload.isLayer, data.prevUrl)
    })
  );
  @Effect({ dispatch: false })
  loginError$ = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE),
    tap((data: LogInFailure) => {
      if (data?.payload?.socialAccountConnected === false&&data?.payload?.userHash) {
        Swal.fire({
          title: this.translateService.instant('notify.title'),
          text: data.payload.cause,
          confirmButtonText: this.translateService.instant('connect_twitter'),
          preConfirm: () => {
           this.authService.redirectTwitterConnect(data.payload.userHash)
          }
        })
      }
    })
  );
}

