import { Action } from '@ngrx/store';

export const FETCH_MOVIES = '[MOVIES] GET LIST FETCH_MOVIES';
export const FETCH_MOVIES_SUCCESS = '[MOVIES] GET LIST FETCH_MOVIES_SUCCESS SUCCESS';
export class FetchMovies implements Action {
    readonly type = FETCH_MOVIES;
}
export class FetchMoviesSuccess implements Action {
    readonly type = FETCH_MOVIES_SUCCESS;
    constructor(public data: any) { }
}
export type MoviesActions = FetchMovies | FetchMoviesSuccess;