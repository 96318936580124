import { ActionReducerMap } from "@ngrx/store";
import { languageReducer } from "../actions";
import { moviesReducer } from "./movie.reducer";
import { playerPostReducer } from "./playerPost.reducer";
import { newsReducer } from "./news.reducer";
import { eventReducer } from "./event.reducer";
import { userReducer } from "./user.reduder";
import { rankingReducer } from "./ranking.reducer";
import { authReducer } from "./auth.reducers";
import { playerReducer } from "./player.reducer";
export const reducers: ActionReducerMap<any> = {
    language: languageReducer,
    playerPost: playerPostReducer,
    movies: moviesReducer,
    news: newsReducer,
    events: eventReducer,
    profile: userReducer,
    rankings: rankingReducer,
    auth: authReducer,
    player: playerReducer
}